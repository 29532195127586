import React, { useState, useEffect } from 'react';
import { PageLayout } from "../components/page-layout";
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import styles from "../styles/components/deleteAccountPage.module.css";

const DeleteAccountPage = () => {
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";

  useEffect(() => {
    const verifyTokens = () => {
      const hash = window.location.hash;
      const params = new URLSearchParams(hash.substring(1)); // Remove the leading '#'
      const accessToken = params.get('access_token');
      const expiresIn = params.get('expires_in');

      if (accessToken && expiresIn) {
        setIsPasswordVerified(true); // Set verified to true if tokens are present
        localStorage.setItem("isVerified", "true"); // Store verified status
        // Optionally, you can store the token in localStorage for future use
        localStorage.setItem("accessToken", accessToken);
      }
    };

    verifyTokens(); // Call the function to check tokens

    return () => {
      // Clean up if necessary
    };
  }, []);
  

  const clearVerifiedStatus = () => {
    localStorage.removeItem("isVerified");
  };
  

  const handleConfirmDelete = async () => {
    if (isPasswordVerified) {
      try {
        const token = await getAccessTokenSilently();
        await axios.post(`${REACT_APP_API_BASE_URL}/api/profile/deleteAccount`, {
          userId: user.sub,
          userEmail: user.email 
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        alert("Account deletion request submitted. Confirmation email sent.");
        logout();
      } catch (error) {
        console.error("Error deleting account:", error);
        alert("Failed to delete account. Please try again.");
      }
    } else {
      alert("You need to verify your password before deleting the account.");
    }
  };

  return (
    <PageLayout>
      <div className="content-layout">
        <div className="content__body">
          {!isPasswordVerified ? (
            <h2>Unauthorized</h2>
          ) : (
            <div>
              <h2>Delete Account</h2>
              <p></p>
              <p>This action is irreversible. All saved data will be permanently deleted.</p>
              <div className={styles.toggleButtons}>
                <button onClick={handleConfirmDelete}>
                  I understand and wish to proceed
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default DeleteAccountPage;
