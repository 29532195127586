import React from "react";
import styles from "../../styles/components/tabBar.module.css"; // Import your CSS module here
import { TabBarTicket } from "./tabBarTicket";

export const TabBar = ({ activeTab, setActiveTab, setActiveButton }) => {
  return (
    <div className={styles.tabContainer}>
      <TabBarTicket />
      <div className={styles.tabLabelsContainer}>
      <div className={styles.tabLabels}>
        <button
          className={activeTab === "ticket" ? styles.activeTab : styles.tab}
          onClick={() => {
            setActiveTab("ticket");
            setActiveButton("activelistings");
            // activebutton: activelistings, soldlistings, activeswaps, pastswaps
          }}
        >
          Listings
        </button>
        <button
          className={activeTab === "concert" ? styles.activeTab : styles.tab}
          onClick={() => {
            setActiveTab("concert");
            setActiveButton("lineup");
            // activebutton: lineup, concertcam
          }}
        >
          Concert
        </button>
        <button
          className={activeTab === "saved" ? styles.activeTab : styles.tab}
          onClick={() => {
            setActiveTab("saved");
            setActiveButton("savedlistings");
            // activebutton: savedlistings, savedswaps
          }}
        >
          Saved
        </button>
      </div>
      </div>
    </div>
  );
};
