import React from "react";
import { PageLayout } from "../components/page-layout";
import styles from "../styles/pages/terms.module.css"

const TermsOfUse = () => {
  return (
    <PageLayout>
      <div className={styles.termsContainer}>
        <h1>Terms of Use</h1>
        <p>
          <strong>Last updated:</strong> 9/23/2024
        </p>
        <h2>Welcome!</h2>
        <p>
          The following are the terms of use (“Terms”) that govern your use of
          CampusTicket or{" "}
          <a
            href="https://www.campus-ticket.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.campus-ticket.com
          </a>{" "}
          (“the Platform”) and all related services provided by CampusTicket. By
          using the Platform, you are entering into a direct user agreement with
          CampusTicket. Anything related to your account data and actions taken
          on the Platform are outlined in this agreement.
        </p>
        <p>
          We may make changes to the Terms at any time. Any changes we make will
          be effective immediately when we post a revised version of the Terms
          on the Platform. By visiting the Platform you are, you expressly agree
          to these Terms, as updated from time to time.
        </p>
        <p>
          You represent that you are over the age of 16. The Platform is not
          targeted at children under the age of 16, and they are not permitted
          to use the Platform.
        </p>
        <p>
          We attach great importance to the provision of a secure and reliable
          Platform. Our Privacy Policy outlines how user content and data are
          used on the Platform and how we secure and monitor the Platform.
        </p>

        <h2>Account Registration</h2>
        <p>
          To use the Platform, you must register using an email address ending
          in <b>.edu</b>. Once you create an account, you are considered a User
          and are liable for actions taken on the Platform pertaining to your
          account. You may only post ticket or group listings and send chat
          messages if you provide all required personal information.
        </p>
        <h2>Platform Services</h2>
        <p>
          The CampusTicket Platform offers a marketplace for users to create
          Listings to advertise selling tickets, organize carpools, and for
          various campus events. As such, our marketplace services are only
          designed to act as a marketplace to connect a specific User (“Lister”)
          with other Users who can choose to facilitate transactions
          off-Platform, including but not limited to financial exchanges
          conducted through Venmo, Instagram, or other third-party services.
          Additional services may be employed for communication between Users,
          profile customization, and data management.
        </p>
        <h2>Listings</h2>
        <p>
          CampusTicket provides users the ability to create two types of
          listings, Sale Listings and Group Listings. Sale Listings allow users
          to advertise a ticket or group of tickets that they are interested in
          selling or transferring to another User for a specified event. Listing
          prices are determined by individuals and we do not guarantee that the
          price seen on the Platform will be the same as the price settled upon
          by the Users. Listing tickets at higher than 300% of their presale
          cost is not allowed. Users are solely responsible for completing any
          transaction outside of the Platform. Group Listings can be used to
          announce plans for group ticket, ride hailing, or food orders. These
          orders will also be handled outside of the Platform by Users. Users
          should conduct their own research before meeting with a group in
          person. Any messages sent in Groups will be available to CampusTicket
          for monitoring. Additionally, misuse of another User’s social media
          data will not be tolerated.
        </p>
        <p>
          Do not post Sale Listings or Group Listings for tickets you do not
          personally possess, did not purchase, or cannot provide ownership or
          receipts for. Posting spam or misleading Group Listings is against the
          Terms of Use and will be removed.
        </p>
        <p>
          Unless otherwise expressly stated by CampusTicket, the display of any
          Listings on the Platforms does not in any way imply, suggest or
          constitute an endorsement by us of those Listing or that User, or any
          sponsorship of us by such User, or any other affiliation between such
          User and us.
        </p>
        <h2>Liability</h2>
        <p>
          CampusTicket is not responsible for any transaction that takes place
          outside of the Platform. Our role is one of a facilitator between You
          and the Lister, using the Platform. We are, therefore, a third party
          in Orders, which limits Our liabilities in any disputes between You
          and the Lister. CampusTicket makes no guarantee with respect (a) to
          the existence, quality, safety or legality of the Listing, (b) the
          truth or accuracy of a Users Listing or content, or (c) the ability of
          a User to fulfill any Listing.
        </p>
        <p>
          We do not condone the Listing of any Ticket that is not authorized for
          resale or transfer. Nor do we permit listing Tickets that you do not
          rightfully possess or have not been purchased under your name.
        </p>
        <h2>Events</h2>
        <p>
          Events available are not hosted or promoted by CampusTicket. Any event
          that is available for a Listing has been chosen by the CampusTicket
          team in order to provide better opportunities for Users to communicate
          and gain access to the event. If an event is canceled or moved
          CampusTicket will attempt to remove the event and all corresponding
          listings as soon as information is received about the cancellation.
        </p>
        <h2>Third Party Affiliations</h2>
        <p>
          Universities mentioned on the Platform are not affiliated with
          CampusTicket. Names and trademarks are used for informational purposes
          only. CampusTicket complies with the Digital Millennium Copyright Act
          (DMCA). To report a violation, email{" "}
          <a className={styles.email} href="mailto:admin@campus-ticket.com">admin@campus-ticket.com</a>{" "}
          with the following information:
        </p>
        <ul>
          <li>Your contact information.</li>
          <li>
            A description of the copyrighted work claimed to have been
            infringed.
          </li>
          <li>
            A description of where the material is located on our platform.
          </li>
          <li>
            A statement of good faith belief that the disputed use is not
            authorized.
          </li>
          <li>Your electronic or physical signature.</li>
        </ul>
        <h2>Personal Use</h2>
        <p>
          The Platform is intended for personal use only and is not to be
          utilized for commercial purposes. Commercial use and business
          partnerships are planned for the future and updates will be posted to
          the Platform accordingly.
        </p>
        <h2>Community Guidelines</h2>
        <p>
          The CampusTicket platform allows users to post and share user content
          (chats and concert cam images) with their campus community and
          associated users to help inspire new musical endevors. We require that
          users only post their own photos and video’s and always follow the
          law. We ask that you respect everyone on CampusTicket, don’t spam
          people or post nudity. Photos and public listings will not allow
          nudity or lewd and suggestive titles. Futhermore we reserve the right
          to remove any content that contains credible threats or hate speech,
          content that targets private individuals or degrade or shame them,
          personal information mean to blackmail or harass someone.
        </p>
        <p>
          Private messages that are reported and found to violate these
          guidelines may result in the user being suspended from The Platform.
        </p>
        <h2>Misuse</h2>
        <p>You may not use the Platform to:</p>
        <ul>
          <li>Do anything illegal or which is in breach of applicable law.</li>
          <li>
            Post any User Content that does not comply with these Terms of Use,
            Community Guidelines, or any of our additional Policies.{" "}
          </li>
          <li>
            Do anything that is fraudulent or deceptive or post any User Content
            that is false or misleading.
          </li>
          <li>
            Use or share another user’s User Content or Personal Information
            without their consent, either on CampusTicket or elsewhere.
          </li>
          <li>
            Modify, interfere with, intercept, disrupt or hack the Platform,
            mine data, scrape or crawl the Platform, including any internal
            software capabilities used to offer our Platform(e.g. an application
            programming interface), or use any bots or other third party
            software on the Platform.
          </li>
          <li>
            Misuse the Platform by knowingly introducing viruses, Trojans,
            worms, logic bombs or other material which would harm the Platform
            or any user of the Platform’s equipment.
          </li>
          <li>
            Use any photographs, images, text, music or video that belongs to
            someone else without their permission, post content that does not
            belong to you.
          </li>
          <li>
            Bully, threaten, defame, abuse, spam, harass, behave inappropriately
            towards or otherwise violate the rights or interests of others.
          </li>
          <li>Link to any third party websites, apps or platforms.</li>
          <li>Create more than one Account, unless we agree otherwise.</li>
          <li>
            Permit another person to use the Platform under your name or on your
            behalf.
          </li>
        </ul>
        <p>
          We reserve the right to investigate any violation of the Terms
          including unauthorized use of the Platform.
        </p>

        <p>
          Failure to comply with the Terms of Use or any of our Policies will be
          treated very seriously by us and may result in us:
        </p>
        <ul>
          <li>
            Temporarily or permanently removing any User Content (including the
            removal of any listings or photos on the Platform).
          </li>
          <li>
            Temporarily or permanently withdrawing your right to use the
            Platform and/or temporarily or permanently deleting your Account.
          </li>
          <li>Taking legal action against you.</li>
          <li>
            Disclosing information to law enforcement authorities if we
            reasonably feel it is necessary or we have a legal obligation to do
            so.
          </li>
        </ul>
        <h2>Contact</h2>
        <p>
          If you need to contact us about the Terms of Use or any other document
          mentioned in them please contact us at{" "}
          <a className={styles.email} href="mailto:admin@campus-ticket.com">admin@campus-ticket.com</a>.
        </p>
      </div>
    </PageLayout>
  );
};

export default TermsOfUse;
