import React, { useState, useEffect } from 'react';
import axios from 'axios'; 
import styles from "../../styles/components/sale-listing.module.css";
import { useAuth0 } from '@auth0/auth0-react';
import SaveIconButton from '../buttons/saveIconButton';
import { LoginButton } from "../buttons/login-button";

function formatDate(date) {
  const options = { month: 'short', day: 'numeric' };
  return new Date(date).toLocaleDateString('en-US', options);
}

export const SaleCard = ({ listing, handleConnect })  => {
  const { user } = useAuth0();
  const [isSaved, setIsSaved] = useState(false); 
  const type = 'listing';
  const pricePerTicket = (listing.price / listing.num_tickets);
  const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL || 'http://localhost:3000';
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';
  const dynamicRedirect = `/tickets/${listing._id}`;


  const handleSeeTixPg = () => {
    const ticketLink = `${REACT_APP_FRONTEND_BASE_URL}/tickets/${listing._id}`;
    window.open(ticketLink, '_blank'); 
  };

  useEffect(() => {
    const checkIfSaved = async () => {
      if (user) {
        try {
          const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/saved/user`, {
            params: { userId: user.sub },
          });
          const savedlist = response.data;
  
          if (savedlist.length > 0) {
            const userSavedlist = savedlist[0]; 
            const savedListings = userSavedlist.listings || []; 
            
            // Check if the current listing ID is in the saved listings
            const isListingSaved = savedListings.includes(listing._id);
            setIsSaved(isListingSaved);
            
          }
        } catch (error) {
          console.error('Error fetching saved listings:', error);
        }
      }
    };

    checkIfSaved();
  }, [user, listing._id, REACT_APP_API_BASE_URL]);

  const handleSaveNew = async (listingId) => {
    if (!user) {
      const currentPage = window.location.pathname + window.location.search;
      window.location.href = `${REACT_APP_FRONTEND_BASE_URL}/login?returnTo=${encodeURIComponent(currentPage)}`;
      return;
    }

    try {
      const payload = {
        userId: user.sub,
        userIg: user.instagram_handle || '', 
        ticketId: listingId, 
        type: type        // "listing" or "swap"
      };
  
      // Save the ticket
      const response = await axios.post(`${REACT_APP_API_BASE_URL}/api/saved/new`, payload);
  
      if (response.status === 201) {
        console.log('Saved ticket:', response.data);
        alert('Ticket saved successfully!');
        setIsSaved(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        alert(error.response.data.message); 
      } else {
        console.error('Error saving ticket:', error);
        alert('An error occurred while saving the ticket.');
      }
    }
  };

  const handleDeleteSaved = async (listingId) => {
    try {
      const payload = {
        userId: user.sub,
        type: type
      };

      await axios.delete(`${REACT_APP_API_BASE_URL}/api/saved/${listingId}`, {
        data: payload,
      });

      alert('Listing unsaved successfully!');
      setIsSaved(false);
    } catch (error) {
      console.error("Failed to unsave listing:", error);
      alert('An error occurred while unsaving the listing.');
    }
  };

  return (
    <div key={listing._id} className={styles.eventCard}>
      <div className={styles.event_info} onClick={handleSeeTixPg} style={{ cursor: 'pointer' }}>
          <h3 >{listing.event_name}</h3>
          <p>
            <span>{formatDate(listing.date)} |</span>
            <span> {listing.venue}</span>
          </p>
      </div>
      {listing.seller_id !== user?.sub && (
        <div className={styles.saveButton} onClick={(e) => { 
          e.stopPropagation(); 
          isSaved ? handleDeleteSaved(listing._id) : handleSaveNew(listing._id); 
        }}>
          <SaveIconButton fill={isSaved ? "url(#paint0_linear_1062_19)" : "url(#blackGradient)"} />
        </div>
      )}
      <div className={styles.ticket_info} onClick={handleSeeTixPg}  style={{ cursor: 'pointer' }}>
        <h4>{listing.num_tickets} Tickets | {listing.level}</h4>
        <div className={styles.price_info}>
          <h3 className={styles.price}>${pricePerTicket}</h3>
          <p className={styles.price_descriptor}>/ticket</p>
        </div>
      </div>
      {(user && listing.seller_id !== user.sub) && (
        <div className={styles.chatButton}>
          <button onClick={(e) => { e.stopPropagation(); handleConnect(listing); }}>Connect</button>
        </div>
      )}
      {(!user) && (
        <div className={styles.chatButton}>
          <LoginButton
          redirectTo={dynamicRedirect}
          buttonText="Connect"
        />
        </div>
      )}
    </div>
  );
};

