import React, { useState, useRef } from "react";
import styles from "../../../styles/components/editProfileModal.module.css";
import { useAuth0 } from "@auth0/auth0-react";
const axios = require('axios');

const EditProfileModal = ({ isOpen, closeModal }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const REACT_APP_API_FRONTEND_URL = process.env.REACT_APP_FRONTEND_BASE_URL || "http://localhost:3000";
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";
  const {REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID} = process.env;
  const [isDeleting, setIsDeleting] = useState(false);
  const [isChangingProfileImage, setIsChangingProfileImage] = useState(false);
  const fileInputRef = useRef(null);
  const [isImageConfirmed, setIsImageConfirmed] = useState(false);


  const handleRelogin = async () => {
    try {
      const authUrl = `https://${REACT_APP_AUTH0_DOMAIN}/authorize?response_type=token&client_id=${REACT_APP_AUTH0_CLIENT_ID}&redirect_uri=${encodeURIComponent(`${REACT_APP_API_FRONTEND_URL}/goodbye`)}&prompt=login&state=${user.sub}`;
      const newWindow = window.open(authUrl, '_blank');
  
      if (newWindow) {
        newWindow.onbeforeunload = async () => {
          // Check if tokens are present when the window is closed
          const hash = newWindow.location.hash; // Get the hash from the new window
          const params = new URLSearchParams(hash.substring(1)); // Remove the leading '#'
          const accessToken = params.get('access_token');
  
          if (accessToken) {
            localStorage.setItem("isVerified", "true"); // Set verified status
            window.location.reload(); // Refresh to sync the new state
          } else {
            alert("Verification failed. Please try again.");
          }
        };
      } else {
        alert("Please allow popups for this website to log in.");
      }
    } catch (error) {
      console.error("Error verifying password:", error);
      alert("Password verification failed. Please try again.");
    }
  };
  

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5 MB size limit
        alert("File size exceeds 5 MB limit.");
        return;
      }
      setIsImageConfirmed(false);
    }
  };
  

  const handleImageSubmit = async () => {
    if (fileInputRef.current.files.length > 0) {
      const file = fileInputRef.current.files[0];
      const formData = new FormData();
      formData.append("profileImage", file);
      const token = await getAccessTokenSilently();
  
      try {
        await axios.post(`${REACT_APP_API_BASE_URL}/api/profile/changeProfileImage/${user.sub}`, formData, {
          headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" }
        });
        alert("Profile image updated successfully!");
        setIsChangingProfileImage(false);
      } catch (error) {
        console.error("Error updating profile image:", error);
        alert("Failed to update profile image. Please try again.");
      }
    }
  };
  

  return (
    isOpen && (
      <div className={styles.modal}>
        <div className={styles.modalContent}>
        {(!isDeleting && !isChangingProfileImage) && (
            <>
          <h2>Edit Profile</h2>
          <div className={styles.toggleButtons} >
            <button onClick={() => setIsChangingProfileImage(true)}>Change Profile Image</button>
            <button onClick={() => handleRelogin()}>Delete Account</button>
            <button type="button" onClick={closeModal}>Cancel</button>
          </div>
          </>
        )}
        {/* @shiv this is glitching */}
        {isDeleting && (
            <div className={styles.confirmDelete}>
                <>
                <p>Please verify your account again.</p>
                <button onClick={() => { setIsDeleting(false) }}>
                Back
                </button>
                </>
          </div>
          )}
          {(!isDeleting && isChangingProfileImage) && (
            <form onSubmit={(e) => e.preventDefault()}>
              <div className={styles.formGroup}>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </div>
              <div className={styles.toggleButtons}>
                <button type="button" onClick={handleImageSubmit} disabled={isImageConfirmed}>Confirm Image</button>
                <button type="button" onClick={() => setIsChangingProfileImage(false)}>Cancel</button>
              </div>
            </form>
          )}
        </div>
      </div>
    )
  );
};

export default EditProfileModal;
