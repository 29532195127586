import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as TicketIcon } from "../../../styles/img/YellowTicketBordered.svg";
import { ReactComponent as SmallTicketIcon } from "../../../styles/img/Final-Ticket-Small.svg";


export const MobileNavBarBrand = ({ handleClick }) => {
  return (
    <div onClick={handleClick} className="mobile-nav-bar__brand">
      <NavLink to="/">
      <SmallTicketIcon 
          className="nav-bar__logo"
          xmlns="http://www.w3.org/2000/svg"
          aria-label="CampusTicket logo"
        />
      </NavLink>
    </div>
  );
};
