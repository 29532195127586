import React from "react";
import { PageLayout } from "../components/page-layout";
import StartPgTxt from "../components/about_txt/StartPgTxt";

export const StartPg = () => {
  return (
    <PageLayout>
      <div className="content-layout">
        <div className="content__body">
          <StartPgTxt />
        </div>
      </div>
    </PageLayout>
  );
};

