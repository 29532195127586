// components/ChatProfile.js
import React, { useState }from 'react';
import styles from '../styles/components/chatProfile.module.css';
import { useSocketContext } from '../context/socket-provider';
import { ChevronLeft } from 'react-feather';
import ReportModal from './modals/reportModal';

const ChatProfile = ({ chat, currUserIg, isMobile, onBackClick }) => {
    const { onlineUsers } = useSocketContext();
    const [showReportModal, setShowReportModal] = useState(false);

    const handleReportClick = (item) => {
        setShowReportModal(true);
      };

      const handleCloseModal = () => {
        setShowReportModal(false);
      };

    const handlePageRefresh = () => {
        window.location.reload();
    };

    return (
        <div className={styles.chatProfile}>
            {isMobile && (
                <button className={styles.backButton} onClick={onBackClick}>
                    <ChevronLeft size={24} />
                </button>
            )}
            <h2 className={styles.chatPageTitle}>{chat.chatName}</h2>
            <div className={styles.chatDetails}>
                {chat.accepted_members_ig
                // participant = users'Ig
                    .filter(participant => participant !== currUserIg)
                    .map((participant) => (
                        <div key={participant} className={styles.participant}>
                            <p>{participant}</p>
                            {onlineUsers.includes(participant) ? (
                                <span className={styles.onlineIndicator}>Online</span>
                            ) : (
                                <span className={styles.offlineIndicator}>Offline</span>
                            )}
                            <button className={styles.reportButton} onClick={handleReportClick}>
                                Report
                            </button>
                        </div>
                    ))
                }
            </div>
            {showReportModal && chat && (
            <ReportModal
                chat={chat}
                onClose={handleCloseModal}
                onBlockSuccess={handlePageRefresh}
            />
            )}
        </div>
    );
};

export default ChatProfile;
