// chat-display.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import styles from '../styles/components/chat-display.module.css';
import ChatProfile from './chatProfile';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';

const ChatDisplay = ({ socket, selectedChat, isMobile, handleBackButton }) => {
    const { user } = useAuth0();
    const [newMessage, setNewMessage] = useState("");
    const [messages, setMessages] = useState(selectedChat ? selectedChat.messages : []);

    useEffect(() => {
        if (selectedChat) {
            setMessages(selectedChat.messages || []);
            scrollToBottom(); // Scroll to bottom when chat is opened
        }
    }, [selectedChat]);

    const messageEndRef = useRef(null);

    const scrollToBottom = () => {
        messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        if (socket) {  // Only add listener if socket exists
            const handleIncomingMessage = (message) => {
                setMessages((prevMessages) => [...prevMessages, message]);
            };
        
            socket.on('message', handleIncomingMessage);
        
            return () => {
                socket.off('message', handleIncomingMessage); // Cleanup on unmount
            };
        }
    }, [socket]);  // Add socket to dependency array

    // Handle sending a new message
    const handleSendMessage = async () => {
        if (newMessage.trim()) {
            const messageData = {
                chatId: selectedChat._id,
                sender: user.sub,
                text: newMessage,
            };

            // Send message to server via Socket.io
            // socket.emit('message', messageData.text);
            socket.emit('message', messageData);

            // // Optimistically update local message state
            // setMessages((prevMessages) => [...prevMessages, messageData]);
            setNewMessage("");

            // Patch the message to MongoDB
            try {
                await axios.patch(`${REACT_APP_API_BASE_URL}/api/chats/${selectedChat._id}/update-history`, {
                    messages: [...messages, messageData],
                });
            } catch (error) {
                console.error("Error updating chat history:", error);
            }
        }
    };

    if (!selectedChat) {
        return (
            <div className={styles.chatDisplay}>
                <div className={styles.placeholderMessage}>
                    Select a chat to start messaging!
                </div>
            </div>
        );
    }

    return (
        <div className={styles.chatDisplay}>
            <ChatProfile 
                chat={selectedChat} 
                currUserIg={user.instagram_handle} 
                isMobile={isMobile} 
                onBackClick={handleBackButton} 
            />
            <div className={styles.messageList}>
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`${styles.message} ${message.sender === user.sub ? styles.myMessage : styles.otherMessage}`}
                    >
                        <p className={styles.message}>{message.text}</p>
                    <div ref={messageEndRef} />
                    </div>
                ))}
            </div>

            <div className={styles.messageInputContainer}>
                <input
                    type="text"
                    className={styles.messageInput}
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                    placeholder="Type a message..."
                />
                <button className={styles.sendButton} onClick={handleSendMessage}>
                    Send
                </button>
            </div>
        </div>
    );
};

export default ChatDisplay;
