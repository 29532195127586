// newMsgModal.js is for sending 1 msg upon requesting connect from listings pg
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import styles from '../../styles/components/newChatModal.module.css';
import { useProfanityFilter } from "../../context/ProfanityFilterContext";


const NewMsgModal = ({ item, onClose, isSaleListing }) => {
  // isSaleListing from listings pg code -> determines what to default chatName
  // can substitute w bool set by checking if item is listing or swap
  const [error, setError] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const { user } = useAuth0();
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';
  const [conversations, setConversations] = useState([]);
  const [pendingSentChats, setPendingSentChats] = useState(null);
  const [chatInvites, setChatInvites] = useState([]);
  const cleanText = useProfanityFilter();

    // Fetch accepted chats
    const fetchAcceptedChats = useCallback(() => {
      axios
        .get(
          `${REACT_APP_API_BASE_URL}/api/chats/accepted-chats/${user.instagram_handle}`
        )
        .then((res) => {
          const allChats = res.data;
  
          // Separate chats into accepted and pending based on the number of accepted members
          const acceptedChats = allChats.filter(
            (chat) => chat.accepted_members_ig.length > 1
          );
          const sentChats = allChats.filter(
            (chat) => chat.accepted_members_ig.length === 1 && chat.invited_members_ig.length === 1
          );
  
          // Set state for accepted conversations
          setConversations(acceptedChats);
  
          // Set state for pending chats
          setPendingSentChats(sentChats);
  
          console.log("Accepted chats:", acceptedChats);
          console.log("Pending invites:", sentChats);
        })
        .catch((error) => {
          console.error("Error fetching accepted chats:", error);
        });
    }, [user.instagram_handle, REACT_APP_API_BASE_URL]);
  
    const fetchChatInvites = useCallback(() => {
      axios
        .get(
          `${REACT_APP_API_BASE_URL}/api/chats/invited-chats/${user.instagram_handle}`
        )
        .then((res) => setChatInvites(res.data))
        .catch((error) => console.error("Error fetching chat invites:", error));
    }, [user.instagram_handle, REACT_APP_API_BASE_URL]);
  
    useEffect(() => {
      fetchAcceptedChats();
      fetchChatInvites();
  }, [fetchChatInvites, fetchAcceptedChats]);
  
  const handleChatLogic = async (existingChat, pendingChat, inviteChat) => {
    if (existingChat) {
      // tested 10/10 works fs
      const newChatName = isSaleListing ? `Re: ${item.event_name} Ticketswap` : `Re: ${item.event_name} Listing`;
      const updatedMessage = `${newChatName}- Msg: ${newMessage}`;

      const messageData = {
        sender: user.sub,
        text: updatedMessage,
        timestamp: Date.now(),
      };

      try {
        await axios.post(`${REACT_APP_API_BASE_URL}/api/chats/${existingChat._id}/messages`, messageData);
        alert('Message sent in the existing chat!');
        setNewMessage('');
        onClose();
      } catch (error) {
        console.error('Error sending message:', error);
        setError('Failed to send message.');
      }
      return;
    }

    if (pendingChat) {
      // tested 10/10 works fs
      const newChatName = isSaleListing ? `Re: ${item.event_name} Ticketswap` : `Re: ${item.event_name} Listing`;
      const chatId = pendingChat._id;

      const messageData = {
        sender: user.sub,
        text: newMessage,
        timestamp: Date.now(),
      };

      try {
        await axios.patch(`${REACT_APP_API_BASE_URL}/api/chats/${chatId}/update-pending`, {
          chatName: newChatName,
          connected_id: item._id,
          message: messageData,
        });
        alert('Updated existing pending chat with your message!');
        setNewMessage('');
        onClose();
      } catch (error) {
        console.error('Error updating pending chat:', error);
        setError('Failed to update chat.');
      }
      return;
    }

    if (inviteChat) {
      // tested 10/10 works fs
      const newChatName = isSaleListing ? `Re: ${item.event_name} Ticketswap` : `Re: ${item.event_name} Listing`;
      const updatedMessage = `${newChatName}: ${newMessage}`;

      const messageData = {
        userId: user.sub,
        userIG: user.instagram_handle,
        sender: user.sub,
        text: updatedMessage,
      };

      try {
        await axios.patch(`${REACT_APP_API_BASE_URL}/api/chats/${inviteChat._id}/accept-and-send-message`, messageData);
        alert('Chat accepted and message sent!');
        setNewMessage('');
        onClose();
      } catch (error) {
        console.error('Error accepting invite and sending message:', error);
        setError('Failed to accept the invite and send message.');
      }
      return;
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newMessage || newMessage.length > 50) {
      setError('Message is required and must be 50 characters or less.');
      return;
    }

    if (newMessage.includes("***")) { 
      setError("Please avoid using inappropriate language.");
      return;
    }

    // Existing chat, pending chat, and invite chat checks
    const existingChat = (conversations ?? []).find(chat => chat.accepted_members_ig.includes(item.instagram_handle));
    const pendingChat = (pendingSentChats ?? []).find(chat => chat.invited_members_ig.includes(item.instagram_handle));
    const inviteChat = (chatInvites ?? []).find(chat => chat.creatorIg === item.instagram_handle);
    
    // if checks not passed
    if (existingChat || pendingChat || inviteChat) {
      await handleChatLogic(existingChat, pendingChat, inviteChat);
      return;
    }

  // if clear cont:
    // Chat name depending on listing or swap
    const newChatName = isSaleListing ? `Re: ${item.event_name} Ticketswap` : `Re: ${item.event_name} Listing`;

    const chatData = {
      chatName: newChatName,
      connected_id: item._id,
      creatorId: user.sub,
      creatorIg: user.instagram_handle,
      creatorEmail: user.email,
      invited_members_ig: item.instagram_handle,
      historyBackup: Date.now(),
      messages: [{
        sender: user.sub,
        text: newMessage,
        timestamp: Date.now(),
      }],
    };

    try {
      const response = await axios.post(`${REACT_APP_API_BASE_URL}/api/chats`, chatData);
      if (response.status === 201) {
        alert('Chat request sent with your message!');
        setNewMessage('');
        onClose();
      } else {
        alert('Failed to create chat.');
      }
    } catch (error) {
      console.error('Error creating chat:', error);
      setError('Failed to create chat');
    }
  };

  const handleMessageChange = (text) => {
    const cleanedText = cleanText(text);
    setNewMessage(cleanedText);
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <h2>Connect</h2>
        <form onSubmit={handleSubmit}>
          <p>You can send the ticket owner one message (max 50 characters) before they accept your chat invite.</p>
          <textarea
            placeholder="Enter your message"
            value={newMessage}
            // onChange={(e) => setNewMessage(e.target.value)}
            onChange={(e) => handleMessageChange(e.target.value)}
            // handleMessageChange(value);
            maxLength={50}
            required
          />
          {error && <p className={styles.error}>{error}</p>}
          <div className={styles.modalButtons}>
          <button type="submit">Send Message</button>
          <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewMsgModal;
