import React from "react";
import styles from "../../styles/components/datedSoloCards.module.css";

export const SwapTicketCard = ({ swap, eventImgUrl }) => {

    // Helper function to format the date as "Month. Day"
  function getMonthDay(dateTimeString) {
    const date = new Date(dateTimeString);
    const options = { month: "short", day: "numeric" };
    const monthDay = date.toLocaleDateString("en-US", options);
    return `${monthDay}.`;
  }

  function getTime(dateTimeString) {
    const date = new Date(dateTimeString);
    let hours = date.getHours();
    let minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // Converts '0' to '12' for midnight times
    return `${hours}:${minutes}${ampm}`;
  }

  const monthDay = getMonthDay(swap.expiry);
  const formattedTime = getTime(swap.expiry);

  const preferredTickets = swap.preferred_tickets ? swap.preferred_tickets.join(", ") : "";


  return (
    <div className={styles.eventCard}>
      <img className={styles.eventCard} src={eventImgUrl} alt={swap.event_name}/>
      <div className={styles.eventDetails}>
        <div className={styles.eventDateTime}>
          <span className={styles.eventDate}>{monthDay}</span>
          <span className={styles.eventTime}>{formattedTime}</span>
        </div>
        <div className={styles.eventLocation}>
          <div className={styles.eventVenue}>{swap.venue}</div>
          <div className={styles.eventCity}>{swap.num_tickets}-{swap.level} tix</div>
          <div className={styles.eventCity}> Swap for: {preferredTickets}</div>
        </div>
      </div>
    </div>
  );
};
