import React from 'react';

const SaveIcon = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="28" viewBox="0 0 32 28" fill="none">
    <path 
      d="M6.68389 21.3737C6.79244 21.5617 6.77116 22.8466 6.71272 24.2349C6.64533 25.8357 8.36987 26.9107 9.75749 26.1095L29.5123 14.7041C30.8386 13.9384 30.8473 12.0271 29.5281 11.2492L27.125 9.83225C26.828 9.65709 26.5813 9.4081 26.4088 9.10945L25.0805 6.80867C24.8951 6.48752 24.8026 6.12115 24.8134 5.75048L24.8765 3.58185C24.922 2.01694 23.2332 1.00882 21.8773 1.79161L2.10624 13.2065C0.74981 13.9896 0.779206 15.9574 2.15842 16.6997L4.30869 17.8569C4.63569 18.0329 4.90724 18.2964 5.09292 18.618L6.68389 21.3737Z"
      fill={fill}  // fill prop
      stroke="black" 
      strokeWidth="1.5" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient id="paint0_linear_1062_19" x1="30.544" y1="12.9175" x2="-0.0184628" y2="14.005" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFB700"/>
        <stop offset="0%" stopColor="#FFC300"/>
        <stop offset="100%" stopColor="#FFDD00"/>
      </linearGradient>
      <linearGradient id="blackGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{ stopColor: 'transparent', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: 'transparent', stopOpacity: 1 }} />
      </linearGradient>
    </defs>
  </svg>
);

export default SaveIcon;

