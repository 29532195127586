import React, { useState } from "react";
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  ComboboxButton,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import styles from "../styles/components/event-search.module.css";

export const EventSearch = ({ events, onChange, reset }) => {
  const [query, setQuery] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Reset the component when the form is reset
  React.useEffect(() => {
    if (reset) {
      setQuery("");
      setDropdownOpen(false);
    }
  }, [reset]);

  // Sort events by dateTime
  const sortedEvents = events
    .map((event) => ({
      ...event,
      dateTime: new Date(event.dateTime),
    }))
    .sort((a, b) => a.dateTime - b.dateTime);

// Filter dropdown events based on the query
const filteredEvents =
  (query || "").trim() === "" 
    ? sortedEvents
    : sortedEvents.filter((event) => {
        const eventValue = event?.eventName; 
        return (
          eventValue &&
          typeof eventValue === "string" &&
          eventValue.toLowerCase().includes(query.toLowerCase())
        );
      });



  // Toggle dropdown visibility when the button is clicked
  const handleIconClick = () => {
    setQuery('');
    setDropdownOpen(true); 
  };

  return (
    <Combobox
      as="div"
      value={query}
      onChange={(value) => {
        setQuery(value);
        const selectedEvent = filteredEvents.find(
          (event) => event.eventName === value
        );
        onChange(selectedEvent); // Pass the entire event object back to the parent
        setDropdownOpen(false); 
      }}
    >
      <div className={styles.inputContainer}>
        <ComboboxInput
          className={styles.input}
          placeholder="Search for an event..."
          value={query}
          onChange={(event) => setQuery(event.target.value)} // Update query when typing
          onFocus={() => setDropdownOpen(true)} // Show dropdown when the input is focused
        />
        {/* Dropdown icon button */}
        <ComboboxButton as="button" className={styles.dropdownIndicator} onClick={handleIconClick}>
        </ComboboxButton>
      </div>

      {/* Dropdown options */}
      {dropdownOpen && (
        <ComboboxOptions className={styles.options}>
          {filteredEvents.length > 0 ? (
            filteredEvents.map((event) => (
              <ComboboxOption
                key={event._id}
                value={event.eventName}
                className={styles.option}
              >
                {event.eventName}
              </ComboboxOption>
            ))
          ) : (
            <div className={styles.noResults}>No results found</div>
          )}
        </ComboboxOptions>
      )}
    </Combobox>
  );
};
