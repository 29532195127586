import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { NavBarTab } from "./nav-bar-tab";

export const NavBarTabs = () => {
  const { isAuthenticated } = useAuth0();

  return (
      // will return in order btw
    <div className="nav-bar__tabs">
        <NavBarTab path="/events" label="Events" />
        <NavBarTab path="/listings" label="Listings" />
      {isAuthenticated && (
        <>
        <NavBarTab path="/sell" label="Sell" />
        <NavBarTab path="/chat" label="Chat" />
        <NavBarTab path="/profile" label="Profile" />
        </>
      )}
      <NavBarTab path="/start" label="Start" />
    </div>
  );
};
