// reportModal will now also be able to "block" users
import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import styles from '../../styles/components/reportModal.module.css';


const ReportModal = ({ post, onClose, chat, onBlockSuccess }) => {
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';
  const { user } = useAuth0();
  const [reportReason, setReportReason] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [file, setFile] = useState(null);
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes
  const allowedFileTypes = ['image/png', 'image/jpeg', 'image/heic', 'image/img'];
  const [isReporting, setIsReporting] = useState(false);
  // max character counts
  const max_reason_charcount = 50;
  const max_addinfo_charcount = 250;

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; 
    }
    if (selectedFile && selectedFile.size > MAX_FILE_SIZE) {
      alert('File size exceeds 5 MB limit.');
      setFile(null);
      return;
    }
    if (!allowedFileTypes.includes(selectedFile.type)) {
      alert('Invalid file type. Only .png, .jpg, .jpeg, .heic, .img are allowed.');
      setFile(null);
      return;
    }
    setFile(selectedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check character count limits
    if (reportReason.length > max_reason_charcount) {
      alert(`Report reason cannot exceed ${max_reason_charcount} characters.`);
      return;
    }
    if (additionalDetails.length > max_addinfo_charcount) {
      alert(`Additional details cannot exceed ${max_addinfo_charcount} characters.`);
      return;
    }

    // Ensure 'post._id' exists before proceeding
    const postId = post?._id || '';
    const chatId = chat?._id || '';
    const reporterEmail = user.email;
    const reporterId = user.sub;

    // Get reported user details from chat if post is not available
  let reportedUserIdFromChat = '';
  let reportedUserIgFromChat = '';

  if (!post && chat) {
    const { accepted_members_id, accepted_members_ig } = chat;
  
  const reportedUserIndex = accepted_members_id.findIndex(id => id !== reporterId);
  reportedUserIdFromChat = accepted_members_id[reportedUserIndex] || '';
  reportedUserIgFromChat = accepted_members_ig[reportedUserIndex] || '';
}

    const formData = new FormData();
    formData.append('reportedUserEmail', post?.email || reportedUserIgFromChat || ''); // will be Ig if chat bc no email
    formData.append('reportedUserId', post?.seller_id || post?.swapper_id || reportedUserIdFromChat || '');
    formData.append('reportReason', reportReason);
    formData.append('additionalDetails', additionalDetails);
    formData.append('reporterEmail', reporterEmail);
    formData.append('reporterId', reporterId);

    if (!chatId) {
      formData.append('listingId', postId);
      formData.append('listingObject', JSON.stringify(post));
    } else if (!postId) {
      formData.append('chatId', chatId);
    }

    if (file) {
        formData.append('file', file);
    }

    console.log('Submitting report data:', Object.fromEntries(formData.entries())); 

    try {
      const response = await axios.post(`${REACT_APP_API_BASE_URL}/api/reports/report`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
          },
      });

      if (response.status === 201) {
        alert('Your report has been sent to the team. Please allow 1-5 business days for us to get back to you via email.');
        onClose();
      } else {
        alert('Failed to submit report. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting report:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleBlockUser = async () => {
    try {
        const userIdToBlock = chat?.creatorId || post.seller_id ||  post.swapper_id ;
        console.log('userIdToBlock', userIdToBlock);
        const payload = {
            currentUserId: user.sub,
            blockUserId: userIdToBlock 
        };

        const response = await axios.post(`${REACT_APP_API_BASE_URL}/api/reports/blockUser`, payload);
        if (response.status === 200) {
          // Show a success message
          alert("User has been blocked.");
          if (onBlockSuccess) {
            onBlockSuccess();
          }
      } else {
          alert("Failed to block user. Please try again.");
      }
    } catch (error) {
        console.error('Error blocking user:', error);
        alert("Failed to block user. Please try again.");
    }
};

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
      {!isReporting ? (
          <>
            <h2>Select an Action</h2>
            <div className={styles.toggleButtons}>
              <button className={styles.toggleButtons} onClick={() => setIsReporting(true)}>
                Report
              </button>
              <button className={styles.toggleButtons} onClick={handleBlockUser}>
                Block User
              </button>
              <button className={styles.toggleButtons} onClick={onClose}>
                Cancel
              </button>
            </div>
          </>
        ) : (
          <>
        <h2>Report</h2>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="reportReason" className={styles.required}>Reason for Report</label>
            <input
              type="text"
              id="reportReason"
              value={reportReason}
              onChange={(e) => setReportReason(e.target.value)}
              placeholder='e.g. Seller profile, Chat, or Listing content'
              required
            />
          </div>
          <div className={styles.formGroup} >
            <label htmlFor="additionalDetails" className={styles.required}>Additional Details</label>
            <textarea
              id="additionalDetails"
              value={additionalDetails}
              onChange={(e) => setAdditionalDetails(e.target.value)}
              placeholder=''
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="file">Upload File</label>
            <input className={styles.fileLabel}
              type="file"
              id="file"
              name="file"
              onChange={handleFileChange}
            />
          </div>
          <div className={styles.modalButtons}>
          <button className={styles.inviteButton} type="submit">Submit Report</button>
          <button className={styles.inviteButton} type="button" onClick={onClose}>Cancel</button> 
          </div>
        </form>
        </>
        )}
      </div>
    </div>
  );
};

export default ReportModal;
