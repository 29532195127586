import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import styles from "../../../styles/components/addConcertModal.module.css";
import { ConcertEventSearch } from "../../concertlineup-event-search";

const AddConcertModal = ({ isOpen, closeModal }) => {
  const REACT_APP_API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";
  const { user } = useAuth0();
  const [concertName, setConcertName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [events, setEvents] = useState([]);
  let selectedEvent = "";

  // const handleChange = (e) => {
  //   setConcertName(e.target.value);
  // };

  useEffect(() => {
    const fetchEventNames = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/events`);
        const currentDateTime = new Date();
  
        // Not passed events
        const fetchedEvents = response.data
          .filter(event => new Date(event.dateTime) >= currentDateTime);
  
          setEvents(fetchedEvents);
      } catch (error) {
        console.error("Failed to fetch events data:", error);
      }
    };
  
    fetchEventNames();
  }, [REACT_APP_API_BASE_URL]);

  const handleEventSelect = (selectedEvent) => {
    console.log("selectedEvent:", selectedEvent);
    if (!selectedEvent) return;
  
  // Format the date to MM/DD
    const dateTime = new Date(selectedEvent.dateTime);
    const formattedDate = `${dateTime.getMonth() + 1}/${dateTime.getDate()}`; 
    const eventName = selectedEvent.eventName;
  
    // assemble concert lineup string
    const updatedConcertName = `${eventName} on ${formattedDate}`;
    setConcertName(updatedConcertName);
    console.log("Concert lineup formed:", updatedConcertName); // Log updated name
    return updatedConcertName; // Return the updated name if needed
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!concertName.trim()) {
      alert("Please enter a valid concert lineup.");
      return;
    }

    setSubmitting(true);

    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URL}/api/lineups/addtolineup`,
        {
          concertName,
          user_email: user.email,
          user_id: user.sub,
          userIg: user.instagram_handle,
        }
      );

      if (response.status === 201) {
        alert("Concert added successfully!");
        closeModal();
      } else {
        alert("Failed to add concert. Please try again.");
      }
    } catch (error) {
      console.error("Error adding concert:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    isOpen && (
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <h2>Add Concert</h2>
          <form onSubmit={handleSubmit}>
            {/* <div className={styles.formGroup}>
              <label htmlFor="concertName">Concert or Artist Tour Name</label>
              <input
                type="text"
                id="concertName"
                name="concertName"
                placeholder="e.g. W2E on 10/5 or Coachella Wknd 1"
                value={concertName}
                onChange={handleChange}
                maxLength="25"
                required
              />
            </div> */}
            <div className={styles.formGroup}>
            <ConcertEventSearch events={events} onChange={handleEventSelect} selectedEvent={selectedEvent}/>
            </div>
            {/* <div className={styles.spacer}></div> */}
            <div className={styles.modalButtons}>
              <button type="submit" disabled={submitting}>
                {submitting ? "Adding..." : "Add Concert"}
              </button>
              <button type="button" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default AddConcertModal;
