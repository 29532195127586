import React from 'react';
import { PageLayout } from "../components/page-layout";
import styles from "../styles/pages/terms.module.css"

const PrivacyPolicy = () => {
  return (
    <PageLayout>
    <div className={styles.termsContainer}>
      <h1>Privacy Policy</h1>
      <p><strong>Last updated:</strong> 9/23/2024</p>

      <h2>Commitment to Privacy</h2>
      <p>
      We are CampusTicket, as a Platform open to the public we want to provide tranparency on how we handle your personal information, outlined in our Privacy Policy. Our goal is to collect only what what is adequate, relevant, and reasonably necessary to deliver users with the best experience possible while ensuring proper stewardship and protection of personal details.
      </p>

      <h2>Information We Collect</h2>
      <p>
      We limit our data collection to the bare minimum. The personal information we require is voluntary and collected when you register for an account, participate in activities on the Platform (such as adding listings, responding to listings, or provide any other information asked for by CampusTicket), and when you contact us. We recognize the uniqueness of each user’s data and maintain it separately. 
      </p>
      <p>CampusTicket will maintain a digital history of all messages sent in the chat page. This data will be kept to ensure compliance with our privacy policy and terms but will not be used for targeted advertising nor distributed to third parties or affiliates.
      </p>
    <p>Users retain the ability to <i>request access, correction, deletion, or restriction</i> of their personal data at any time subject to assessment for the processing sensitive data. <i>They can do this through their user profile or by contacting our support email at <a className={styles.email} href="mailto:admin@campus-ticket.com">admin@campus-ticket.com</a></i>. 
    </p>
    <p>The personal information we collect can include the following:
    </p>
    <ul>
        <li><b>Personal Data:</b> Your first and last name, your email address, university name, phone number, or other similar contact information.
        </li>
        <li><b>Credentials:</b> Passwords and other security information used for authentication.
        </li>
        <li><b>Social Media Data:</b> Your instagram handle and any other social media account information provided by you.
        </li>
    </ul>

      <h2>Uses of Personal Information</h2>
      <p>CampusTicket uses personal information to:</p>
      <ul>
        <li>Facilitate account creation and login</li>
        <li>Manage correspondence about your account and services</li>
        <li>Respond to user inquiries and offer support</li>
        <li>Deliver targeted advertising</li>
        <li>Protect stakeholders and prevent fraud</li>
        <li>Fulfill legal requirements and respond to governmental requests</li>
        <li>Request feedback and contact you regarding your use of the platform</li>
      </ul>

      <h2>Sharing Your Information</h2>
      <p>
        CampusTicket may share your information with affiliates and service providers to perform tasks on our behalf, and with partners for joint services, but only with your consent. We comply with applicable law for security and public importance considerations.
      </p>

      <h2>Consent</h2>
      <p>
        We pledge to never collect or share your personal information with third parties for their marketing without your explicit consent.
      </p>
      <p><i>CampusTicket is not directed to individuals under the age of sixteen (16), and we do not knowingly collect personal information from individuals under 16.
      </i></p>

      <h2>Other Digital Information</h2>
      <p>
        CampusTicket avoids third-party "cookies" and similar tracking technologies. We may use your IP address or functional cookies to display relevant content based on your location.
      </p>
    <p>Other Practices:</p>
    <ul>
        <li>We might embed "click-through URLs" in email communications to direct you to relevant content on the CampusTicket site. This process involves a brief stop at a separate server, allowing us to gauge engagement. Not comfortable with tracking? Simply avoid clicking on links within our emails.
        </li>
    </ul>

      <h2>Notification Preferences</h2>
      <p>
        Users can opt out of push notifications at any time via their profile settings or on your mobile device settings for ios-app users.
      </p>

      <h2>Contact</h2>
      <p>
        If you have questions about the Privacy Policy or any other document mentioned in it, please contact us at <a className={styles.email} href="mailto:admin@campus-ticket.com">admin@campus-ticket.com</a>.
      </p>

    </div>
    </PageLayout>
  );
};

export default PrivacyPolicy;
