import React, { useState, useEffect } from "react";
import styles from "../../styles/components/start-txt.module.css";
import ss_1 from "../../styles/img/screenshots/sellform-ss.png";
import ss_2 from "../../styles/img/screenshots/eventspg-ss.png";
import ss_3 from "../../styles/img/screenshots/individualeventpg-ss.png";
import ss_305 from "../../styles/img/screenshots/listingspg-ss.png";
import ss_4 from "../../styles/img/screenshots/connectchat-ss.png";
import ss_5 from "../../styles/img/screenshots/profilepg-ss.png";
import { Accordion, AccordionItem } from "@nextui-org/react";
import { useAuth0 } from "@auth0/auth0-react";

export default function StartPg() {
  const email = "admin (at) campus-ticket.com";
  const { user } = useAuth0();
  const { loginWithRedirect } = useAuth0();
  const igURL = "https://www.instagram.com/campusticket/";
  const snapURL = "https://snapchat.com/t/T3EA70AX";
  const ttURL = "https://www.tiktok.com/@campusticket";
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);


  // State variables to manage visibility of FAQ and tips sections
  const [isImageEnlarged, setImageEnlarged] = useState({
    ss_1: false,
    ss_2: false,
    ss_3: false,
    ss_4: false,
    ss_5: false,
  });

  const toggleImageSize = (img) => {
    setImageEnlarged((prevState) => ({
      ...prevState,
      [img]: !prevState[img],
    }));
  };

  const handleSignUp = async () => {
    await loginWithRedirect({
      prompt: "login",
      screen_hint: "signup",
      appState: {
        returnTo: "/login",
      },
    });
  };

  return (
    <div>
      <h2 className={styles.title}>Let's Create a Listing</h2>
      <div className={styles.instructions_layout}>
        <div className={styles.instructions_row_left}>
          <h4
            className={styles.instructions_text}
            style={{ fontWeight: "500" }}
          >
            Choose between selling or swapping your ticket. Name your ticket
            price or preferred trades.
          </h4>
          <img
            className={`${styles.instructions_image_box} ${
              isImageEnlarged.ss_1 ? styles.enlarged : ""
            }`}
            alt="sell_img"
            src={ss_1}
            onClick={() => toggleImageSize("ss_1")}
          />
        </div>
        <div className={styles.instructions_row_right}>
          <img
            className={`${styles.instructions_image_box} ${
              isImageEnlarged.ss_2 ? styles.enlarged : ""
            }`}
            alt="events_img"
            src={ss_2}
            onClick={() => toggleImageSize("ss_2")}
          />
          <h4
            className={styles.instructions_text}
            style={{ fontWeight: "500" }}
          >
            Discover upcoming events.
          </h4>
        </div>
        <div className={styles.instructions_row_left}>
          <h4
            className={styles.instructions_text}
            style={{ fontWeight: "500" }}
          >
            See who's attending.
          </h4>
          <img
            className={`${styles.instructions_image_box} ${
              isImageEnlarged.ss_3 ? styles.enlarged : ""
            }`}
            alt="event_img"
            src={ss_3}
            onClick={() => toggleImageSize("ss_3")}
          />
        </div>
        <div className={styles.instructions_row_right}>
          <img
            className={`${styles.instructions_image_box} ${
              isImageEnlarged.ss_4 ? styles.enlarged : ""
            }`}
            alt="chat_img"
            src={ss_4}
            onClick={() => toggleImageSize("ss_4")}
          />
          <h4
            className={styles.instructions_text}
            style={{ fontWeight: "500" }}
          >
            Connect with other students to send offers.
          </h4>
        </div>
        <div className={styles.instructions_row_left}>
          <h4
            className={styles.instructions_text}
            style={{ fontWeight: "500" }}
          >
            Show off your concert lineup or listings by sharing your profile 🔥.
          </h4>
          <img
            className={`${styles.instructions_image_box} ${
              isImageEnlarged.ss_5 ? styles.enlarged : ""
            }`}
            alt="profile_img"
            src={ss_5}
            onClick={() => toggleImageSize("ss_5")}
          />
        </div>
      </div>
      {isMobileView && !user && (
        <div className={styles.signup_mobile}>
          <button
            id="create-account-button"
            className="button button--secondary"
            onClick={handleSignUp}
          >
            Create An Account!
          </button>
        </div>
      )}
      <div className={styles.spacer}></div>
      <h2 className={styles.title}>Welcome to CampusTicket</h2>
      <div className={styles.intro}>
        <p>
          At CampusTicket ⭐, we understand the growing needs of the
          concerts/festival community amongst other events. This is why
          we&apos;ve created a platform exclusively for students to enjoy the
          thrill and convenience of last-minute resale tickets with 0 FEES❗
          Whether you&apos;re looking to sell those extra tickets or snag a
          last-minute opportunity, our platform is designed to make the process
          social and enjoyable.
        </p>
        <p>
          Say goodbye to the disjointed ticket community and hello to
          student-friendly and student-priced fun with CampusTicket. 😉
        </p>
      </div>
      <div className={styles.spacer}></div>
      <h2 className={styles.title}>Learn More</h2>
      <div className={styles.qandA}>
        <Accordion>
          <AccordionItem
            key="1"
            aria-label="FAQ"
            title="FAQ"
            className={styles.accordionTitle}
          >
            <div className={styles.dropdownContent}>
              <h3>How do I start?</h3>
              <p>
                Be cautious of any offers that require you to pay a deposit.
                Genuine sellers won&apos;t ask for payment before confirming the
                transaction.
              </p>
              <h3>How can I stay updated on giveaways or news?</h3>
              <p>
                Click to follow @campusticket on
                <a
                  href={igURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.aLink}
                >
                  {" "}
                  Instagram
                </a>
                ,
                <a
                  href={ttURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.aLink}
                >
                  {" "}
                  TikTok
                </a>
                , and
                <a
                  href={snapURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.aLink}
                >
                  {" "}
                  Snapchat
                </a>{" "}
                .
              </p>
              <h3>Is CampusTicket only for sellers or prospective buyers?</h3>
              <p>
                We welcome all students to use CampusTicket for the community
                aspect, facilitated by our groupings feature. Instead of posting
                a ticket listing, users are able to post grouping requests so
                other users can be joined with those interested in ridesharing
                to events, grabbing a bite to eat after events, or swapping
                tickets to events instead of selling*, or beating the presale
                odds and group purchasing*.
              </p>
              <h3>
                How is CampusTicket secure for sellers and prospective buyers?
              </h3>
              <p>
                Currently, all users must use their .edu school email and must
                be verified to post on this platform. Each ticket and grouping
                listing has a report button that any user can use to report
                suspicious listings or users. In the case of misuse on our
                platform, we reserve the right to ban all parties involved.
                Users may privately request proof of purchase from users via
                Instagram. We suggest tips to safely use CampusTicket in the
                section below.
              </p>
              <h3>How are fraud and scams prevented?</h3>
              <p>
                *Please note that we do not currently offer any guarantee for
                resale ticket listings. As a result, we do not facilitate
                financial transactions on the platform. Any financial
                transactions occurring or initiated on our platform are not
                managed by us, and we do not assume responsibility for them. We
                are actively working on developing this feature with the safety
                of our users in mind, please stay tuned for updates.
              </p>
              <h3>
                What if I&apos;m interested in partnering with or sponsoring
                CampusTicket?
              </h3>
              <p>
                Reach out to {email} with "Partner/Sponsor Request" in the
                subject line.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            key="2"
            aria-label="Tips for Reselling"
            title="Tips for Reselling"
            className={styles.accordionTitle}
          >
            <div className={styles.dropdownContent}>
              <h3>Avoid Scams</h3>
              <p>
                Be cautious of any offers that require you to pay a deposit.
                Genuine sellers won&apos;t ask for payment before confirming the
                transaction.
              </p>
              <h3>Avoid Long Waitlists</h3>
              <p>
                If a ticket waitlist is more than a week long, consider
                exploring other options. Waiting too long can increase the risk
                of missing out on other opportunities.
              </p>
              <h3>Request Receipts</h3>
              <p>
                It&apos;s perfectly acceptable to request a receipt from any
                seller. This can help you track your purchase and verify its
                authenticity.
              </p>
              <h3>Ticket Guarantee</h3>
              <p>
                Please note that we do not currently offer guarantees on resale
                tickets. As a result, we do not facilitate financial
                transactions on the platform. Any financial transactions
                occurring or initiated on our platform are not managed by us,
                and we do not assume responsibility for them. We are actively
                working on developing this feature, so please stay tuned for
                updates.
              </p>
            </div>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}
