// ProfanityFilterContext.js
import React, { createContext, useContext } from 'react';
import { Filter } from 'bad-words';

const ProfanityFilterContext = createContext();

export const ProfanityFilterProvider = ({ children }) => {
  const filter = new Filter();

  // Func to clean text input
  const cleanText = (text) => filter.clean(text);

  return (
    <ProfanityFilterContext.Provider value={cleanText}>
      {children}
    </ProfanityFilterContext.Provider>
  );
};

// hook for components
export const useProfanityFilter = () => useContext(ProfanityFilterContext);
