import React from "react";
import { PageFooterHyperlink } from "./page-footer-hyperlink";
import { useAuth0 } from "@auth0/auth0-react";
//import { ReactComponent as TicketIcon } from "../styles/img/YellowTicketBordered.svg";
import { ReactComponent as SmallTicketIcon } from "../styles/img/Final-Ticket-Small-Footer.svg";

export const PageFooter = () => {
  const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL || 'http://localhost:3000';

  const { user } = useAuth0();
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = async () => {
    await loginWithRedirect({
      prompt: "login",
      screen_hint: "signup",
      appState: {
        returnTo: "/login",
      },
    });
  };

  return (
    <footer className="page-footer">
      <div className="page-footer-grid">
        <div className="page-footer-grid__info">
          <div className="page-footer-info__message">
            <SmallTicketIcon
              className="page-footer-brand__logo"
              alt="campusticket logo"
              width="60"
              height="auto"
            />
            <PageFooterHyperlink path={REACT_APP_FRONTEND_BASE_URL}>
              CampusTicket
            </PageFooterHyperlink>
          </div>
          <p className="page-footer-message__description">
            <span>
              <a
                href={`${REACT_APP_FRONTEND_BASE_URL}/terms`}
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                Terms & Conditions
              </a>
              {" | "}
              <a
                href={`${REACT_APP_FRONTEND_BASE_URL}/privacy`}
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                Privacy
              </a>
            </span>
          </p>
        </div>
        {!user && (
        <div className="page-footer-info__button">
          <button
            id="create-account-button"
            className="button button--secondary"
            onClick={handleSignUp}
          >
            Create An Account!
          </button>
        </div>
        )}
      </div>
    </footer>
  );
};
