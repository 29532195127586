import React, { useState, useEffect, useCallback } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import styles from "../styles/pages/public-profile-page.module.css";
import DropdownButton from "../components/buttons/dropdown-button";
import { TabBarPublic } from "../components/profile_tabs/tabBarPublic";
import { SaleCard } from "../components/sale-listings/sale-listing";
import { SwapCard } from "../components/swap-listings/swap-listing";
import { PublicLineupCard } from "../components/lineup/public-lineup";
import NewMsgModal from "../components/modals/newMsgModal";

export const PublicProfilePage = () => {
  const REACT_APP_API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";

  const { user } = useAuth0();
  const { instagramHandle } = useParams();
  const [sellerPic, setSellerPic] = useState('');
  const [activeTab, setActiveTab] = useState("sale");
  const [activeButton, setActiveButton] = useState("activelistings");
  const [activeListings, setActiveListings] = useState([]);
  const [soldListings, setSoldListings] = useState([]);
  const [activeSwaps, setActiveSwaps] = useState([]);
  const [concerts, setConcerts] = useState([]);
  const [error, setError] = useState(null);
  const [isNewMsgModalOpen, setIsNewMsgModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [profileImage, setProfileImage] = useState('');
  const [isABlockedUser, setIsABlockedUser] = useState(false); 
  const [noUserFound, setNoUserFound] = useState(false); 
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 540); // mobile view?


  useEffect(() => {
    const fetchBlockedUsersAndTicketDetails = async () => {
      try {
        if (user) {
          // Current user's blocked users
          const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/getUserMetadata/${user.sub}`);
          const blockedUsersData = response.data.metadata.blocked_users || [];
          console.log('Current blockedUsers:', blockedUsersData);
  
          try {
            const instagramResponse = await axios.get(`${REACT_APP_API_BASE_URL}/api/getUserIdAndBU/${instagramHandle}`);
            const { userId: instagramUserId, blockedUsers: instagramBlockedUsers } = instagramResponse.data;
  
            // Check if the current user is blocked by the Instagram user
            if (instagramBlockedUsers.includes(user.sub)) {
              setIsABlockedUser(true);
            }
  
            // Check if the current user has blocked the Instagram user
            if (blockedUsersData.includes(instagramUserId)) {
              setIsABlockedUser(true);
            }
  
            console.log('Instagram User:', instagramUserId, 'Blocked Users:', instagramBlockedUsers);
          } catch (error) {
            // If the user DNE (404 error), set noUserFound to true
            if (error.response && error.response.status === 404) {
              setNoUserFound(true);
            } else {
              console.error('Error fetching Instagram user data:', error);
              setError('Failed to fetch Instagram user data.');
            }
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch blocked users.');
      }
    };
  
    fetchBlockedUsersAndTicketDetails();
  }, [user, instagramHandle]);
  

  const loadProfileImage = async (profilepic) => {
    console.log('profilepic:', profilepic);
    // Check if the image is stored in cloud
    if (profilepic.startsWith("https://campus-ticket.s3.")) {
      const pictureKey = profilepic.split('.com/')[1];
      
      try {
        const response = await axios.post(`${REACT_APP_API_BASE_URL}/api/getSignedUrl`, {
          pictureKey: pictureKey,
        });

        const { signedUrl } = response.data;
        console.log('signedUrl:', signedUrl);
        // Use the signed URL to load the image
        setProfileImage(signedUrl);
      } catch (error) {
        console.error("Error fetching signed URL", error);
      }
    } else {
      // Use the local or alternative image URL
      setProfileImage(profilepic);
    }
  };

  const fetchListings = useCallback(async () => {
    if (!user) return;

    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/api/public-profile/listings/${instagramHandle}`
      );
      const publicListings = response.data;
      console.log(publicListings);

      // Ensure that listings and groupings exist
      const allListings = publicListings.listings || [];

      // Use the same filtering logic as fetchListings
      const active = allListings.filter(
        (listing) =>
          !listing.deleted && listing.state.toLowerCase() === "available"
      );
      const sold = allListings.filter(
        (listing) => listing.state.toLowerCase() === "sold" && !listing.deleted
      );

      setActiveListings(active);
      setSoldListings(sold);
    } catch (error) {
      setError(error);
    }
  }, [REACT_APP_API_BASE_URL, instagramHandle, user]);

  useEffect(() => {
    fetchListings();
  }, [fetchListings]);

  const fetchSwaps = useCallback(async () => {
    if (!user) return;

    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/api/public-profile/swaps/${instagramHandle}`
      );
      const publicSwaps = response.data;
      const allSwaps = publicSwaps.swaps || [];

      const today = new Date();
      const active = allSwaps.filter(
        (swap) =>
          new Date(swap.expiry) > today &&
          !swap.deleted &&
          swap.state.toLowerCase() === "available"
      );

      setActiveSwaps(active);
    } catch (error) {
      console.error("Oh no! Failed to fetch swaps:", error);
    }
  }, [user, REACT_APP_API_BASE_URL, instagramHandle]);

  const fetchLineup = useCallback(async () => {
    if (!instagramHandle) return;

    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/api/lineups/instagram/${instagramHandle}`);
      const lineup = response.data;

      setConcerts(lineup);
    } catch (error) {
      console.error("Oh no! Failed to fetch lineup:", error);
    }
  }, [REACT_APP_API_BASE_URL, instagramHandle]);

  const fetchSellerInfo = useCallback(async () => {
    if (!instagramHandle) return;

    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/api/public-profile/getUserInfofromIg/${instagramHandle}`);
        const sellersPic = response.data.userPicture;
        setSellerPic(sellersPic);
        loadProfileImage(sellersPic);

    } catch (error) {
      console.error("Oh no! Failed to fetch lineup:", error);
    }
  }, [REACT_APP_API_BASE_URL, instagramHandle]);

  useEffect(() => {
    fetchSellerInfo();
    fetchListings();
    fetchSwaps();
    fetchLineup();
  }, [user, fetchListings, fetchSwaps, fetchLineup, REACT_APP_API_BASE_URL]);


  const getActiveButtonLabel = () => {
    switch (activeButton) {
      case "lineup":
        return "Concert Lineup";
      case "activelistings":
        return "Active";
      case "soldlistings":
        return "Sold";
      case "activeswaps":
        return "Active";
      default:
        return "...";
    }
  };

  const mapListings =
    activeButton === "activelistings"
      ? activeListings
      : activeButton === "soldlistings"
      ? soldListings
      : [];

  const mapSwaps =
    activeButton === "activeswaps"
      ? activeSwaps
      : [];

  const handleConnectClick = (item) => {
    setCurrentItem(item);
    setIsNewMsgModalOpen(true);
  };    

  const handleCloseNewMsgModal = () => {
    setIsNewMsgModalOpen(false);
    setCurrentItem(null);
  };  

  return (
    <PageLayout>
      <div className={styles.content__layout}>
        <div className="content__body">
        {(isABlockedUser || noUserFound) && (
          <h2>
            User not found.
          </h2>
        )}
        {(!isABlockedUser && !noUserFound) && (
          <div className={styles["profile-grid"]}>
            <div className={styles.profile_header}>
              <div className={styles.profile_card}>
                <div className={styles.profile__headline}>
                  <img
                    src={profileImage}
                    alt="Profile"
                    className={styles["profile__avatar"]}
                  />
                  <div className={styles.user_info}>
                    <h2 className={styles["profile__title"]}>
                      @{instagramHandle}
                    </h2>
                    <span>
                      <a
                        href={`https://instagram.com/${instagramHandle}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles["instagram-link"]}
                      >
                        {instagramHandle}'s insta
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.listing_details}>
              {/* Tab bar */}
              <div>
                <TabBarPublic
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  setActiveButton={setActiveButton}
                />
              </div>
              {/* Ticket heading + dropdown labels */}
              <div>
                <div className={styles.listings_bar}>
                  {activeTab === "concert" && activeButton === "lineup" ? (
                    <h3>Concert Lineup</h3>
                  ) : null}
                  {activeTab === "sale" &&
                  (activeButton === "activelistings" ||
                    activeButton === "soldlistings") ? (
                    <h3>Sale Listings</h3>
                  ) : activeTab === "swap" && activeButton === "activeswaps" ? (
                    <h3>Ticketswap listings</h3>
                  ) : null}
                  
                  {/* Conditionally render DropdownButton only if activeTab is not "concert" */}
                  {activeTab !== "concert" && (
                    <DropdownButton label={getActiveButtonLabel()}>
                      {activeTab === "sale" && (
                        <>
                          <button
                            onClick={() => {
                              setActiveButton("activelistings");
                            }}
                          >
                            Active Listings
                          </button>
                          <button
                            onClick={() => {
                              setActiveButton("soldlistings");
                            }}
                          >
                            Sold Listings
                          </button>
                        </>
                      )}
                      {activeTab === "swap" && (
                        <>
                          <button
                            onClick={() => {
                              setActiveButton("activeswaps");
                            }}
                          >
                            Active Swaps
                          </button>
                        </>
                      )}
                    </DropdownButton>
                  )}
                </div>
              </div>
              
              {/* Ticket rendering */}
              <div className={styles["grid-container"]}>
                {activeTab === "sale" && (
                  <div className={styles["grid-container"]}>
                    {/* Active Listings or Sold Listings */}
                    {activeButton === "activelistings" ||
                    activeButton === "soldlistings" ? (
                      mapListings.length > 0 ? (
                        mapListings.map((listing) => (
                          <SaleCard
                            key={listing._id}
                            listing={listing}
                            handleConnect={handleConnectClick}
                          />
                        ))
                      ) : (
                        <p>
                          No current sale listings.{" "}
                          <a href="/listings">Discover listings here</a>.
                        </p>
                      )
                    ) : null}
                  </div>
                )}
                {/* Active Swaps */}
                { activeTab === "swap" && activeButton === "activeswaps" ? (
                 <div className={styles["grid-container"]}>
                  { mapSwaps.length > 0 ? (
                    mapSwaps.map((swap) => (
                      <SwapCard
                        key={swap._id}
                        swap={swap}
                        handleConnect={handleConnectClick}
                      />
                    ))
                  ) : (
                    <p>
                      No current ticketswaps.{" "}
                      <a href="/listings">Discover swaps here</a>.
                    </p>
                  )}
                  </div>
                ) : null}
                {/* Concert lineups */}
                {activeTab === "concert" && activeButton === "lineup" ? (
                  <div className={styles["concert-grid-container"]}>
                    {concerts.length > 0 ? (
                      concerts.map((concert) => (
                        <PublicLineupCard
                          key={concert._id}
                          concert={concert}
                        />
                      ))
                    ) : (
                      <p>
                        No lineup.
                      </p>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
      {isNewMsgModalOpen && currentItem && (
        <NewMsgModal
          item={currentItem}
          // @shiv use method for isListings?
          isSaleListing={activeButton === "activelistings"}
          onClose={handleCloseNewMsgModal}
        />
      )}
    </PageLayout>
  );
};
