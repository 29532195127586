import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as SmallTicketIcon } from "../../../styles/img/Final-Ticket-Small-Nav.svg";

export const NavBarBrand = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
      <SmallTicketIcon
          className="nav-bar__logo"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid meet"
          aria-label="campusticket logo"
        />
      </NavLink>
    </div>
  );
};
