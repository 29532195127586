import React, { useState } from "react";
import axios from "axios";
import styles from "../styles/components/prize-form.module.css";
import { useAuth0 } from "@auth0/auth0-react";
const PrizeForm = ({ step, setStep }) => {
  const REACT_APP_API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";

  const [codeInput, setCodeInput] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [prizeAmount, setPrizeAmount] = useState(null);
const { user } = useAuth0();
const usersIg = user.instagram_handle || '';
  const handleCodeSubmit = async (event) => {
    event.preventDefault();
    if (!user) {
        return;
    }

    try {
        console.log('userIg:', usersIg);
        if (!usersIg || usersIg.trim() === "") {
            setError("Instagram handle is missing. Please provide it.");
            return;
        }

        const response = await axios.post(`${REACT_APP_API_BASE_URL}/api/verifyPrizeCode/${user.sub}/${usersIg}`, {
        code: codeInput,
        });
        

      if (response.data.success) {
        setPrizeAmount(response.data.amount); // Set the winning amount from the backend response
        setStep(2); // Move to step 2 if verification succeeds
        setError("");
        } else {
            if (response.status === 400) {
            setError(response.data.message || "Invalid code. Please try again.");
            } else {
            setError("An unknown error occurred. Please try again.");
            }
        }
        } catch (error) {
        console.error("Error verifying code:", error);
    
        if (error.response && error.response.data && error.response.data.message) {
            setError(error.response.data.message); 
        } else {
            setError("There was an error verifying the code. Please try again later.");
        }
        }
    };

  return (
    <div className={styles.content_layout_waitlist}>
      {step === 0 ? (
        <div className={styles.email_verify_form}></div>
      ) : step === 2 ? (
        <div className={styles.content_body}>
          <h2 style={{fontSize: "28px"}}>Congratulations!</h2>
          <h2>
            You have won {" "}
            <span style={{ color: "var(--main-yellow)" }}>
             ${prizeAmount} total.
            </span>
         </h2>
          <h2>
            One last step! To claim your money: 
            <br />
            <br /> {'1)'} Click to follow{" "}
            <a style={{textDecoration: "underline"}}
              href="https://instagram.com/campusticket"
              target="_blank"
              rel="noopener noreferrer"
            >
              @campusticket on Instagram
            </a>{" "}
            <a style={{textDecoration: "underline"}}
              href="https://www.tiktok.com/@campusticket"
              target="_blank"
              rel="noopener noreferrer"
            >
              or tiktok, 
            </a>{" "}
            <br/> {'2)'} DM your CampusTicket and venmo handle 
            <br/> {'(optional)'} include a pic of you at your favorite concert
          </h2>
        </div>
      ) : step === 1 ? (
        // Step === 1
        <div className={styles.content_body}>
          <form onSubmit={handleCodeSubmit}>
            <label className={styles.code_label}>
              Enter 6-Digit Prize Code
            </label>
              <input
                type="text"
                value={codeInput}
                onChange={(e) => setCodeInput(e.target.value)}
                className={styles.form_input}
                required
              />
            <button type="submit" className={styles.special_submit_button}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                <path d="M1 7C0.447715 7 1.11851e-09 7.44772 0 8C-1.11851e-09 8.55228 0.447715 9 1 9L1 7ZM17.7071 8.70711C18.0976 8.31658 18.0976 7.68342 17.7071 7.29289L11.3431 0.928932C10.9526 0.538408 10.3195 0.538408 9.92893 0.928932C9.53841 1.31946 9.53841 1.95262 9.92893 2.34315L15.5858 8L9.92893 13.6569C9.53841 14.0474 9.53841 14.6805 9.92893 15.0711C10.3195 15.4616 10.9526 15.4616 11.3431 15.0711L17.7071 8.70711ZM1 9L17 9L17 7L1 7L1 9Z" fill="#0F0F0F"/>
              </svg>
            </button>
            {error && <p style={{ color: "red" }}>{error}</p>}
          </form>
          <h2
            style={{ cursor: "pointer"}}
            onClick={() => (window.location.href = "/profile")}
          >
            I don't have a ticket yet 
          </h2>
        </div>
      ) : 
      (
        <div className={styles.email_verify_form}></div>
      )
      }
    </div>
  );
};

export { PrizeForm };