import React, { useState, useEffect, useRef, cloneElement } from 'react';
import styles from '../../styles/components/dropdownButton.module.css';

const DropdownButton = ({ 
  label, 
  children, 
  className,
  dropdownContentClass,
  dropdownButtonClass
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Clone the children to add the onClick event to close the dropdown
  const clonedChildren = React.Children.map(children, (child) => 
    child ? cloneElement(child, {
      onClick: (event) => {
        // Close the dropdown when a child is clicked
        setIsOpen(false);
        
        // Call the child's own onClick handler, if it exists
        if (child.props.onClick) {
          child.props.onClick(event);
        }
      },
      className: `${child.props.className || ''} ${dropdownButtonClass || ''}`
    }) : null
  );

  // Check if there is more than one child
  const hasMultipleChildren = React.Children.count(children) > 1;

  return (
    <div className={`${styles.dropdown} ${className || ''}`} ref={dropdownRef}>
      <button className={`${styles.dropdownButton} ${className || ''}`} onClick={toggleDropdown}>
        {label}
        {hasMultipleChildren && <span className={styles.dropdownIndicator}></span>}
      </button>
      {isOpen && (
        <div className={`${styles.dropdownContent} ${dropdownContentClass || ''}`}>
          {clonedChildren}
        </div>
      )}
    </div>
  );
};

export default DropdownButton;
