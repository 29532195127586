import React from 'react';
import styles from '../../../styles/components/modals/genre-modal.module.css';

const genres = [
  { 
    name: 'EDM', 
    gradient: 'linear-gradient(252deg, #2FA4E5 -14.36%, #E32D92 103.45%)'
  },
  { 
    name: 'Pop', 
    gradient: 'linear-gradient(15deg, #FFB700 5.76%, #FFC300 28.57%, #FFD000 74.17%, #FFDD00 96.97%)'
  },
  {
    name: 'Country',
    gradient: 'linear-gradient(15deg, #0077B6 5.76%, #0096C7 36.77%, #00B4D8 74.17%, #48CAE4 96.97%)'
  },
  {
    name: 'Rock',
    gradient: 'linear-gradient(15deg, #590D22 5.76%, #800F2F 35.86%, #A4133C 65.96%, #C9184A 96.97%)'
  },
  {
    name: 'Latin',
    gradient: 'linear-gradient(15deg, #98DE5B 5.76%, #08E1AE 96.97%)'
  },
  {
    name: 'Rap',
    gradient: 'linear-gradient(59deg, #000 -6.36%, #5C5C5C 113.3%)'
  },
  {
    name: 'R&B',
    gradient: 'linear-gradient(215deg, #E5B2CA 8.95%, #AA6DD7 40.99%, #7028E4 91.95%)'
  },
  {
    name: 'K-Pop',
    gradient: 'linear-gradient(180deg, #EE658E -25.33%, #F093FB 143.67%)'
  },
  {
    name: 'Indie',
    gradient: 'linear-gradient(15deg, #3ECF8E 5.76%, #56AB91 28.34%, #469D89 51.37%, #358F80 74.17%, #248277 96.97%)'
  },
  {
    name: 'Christian',
    gradient: 'linear-gradient(15deg, #4400FF 5.76%, #2800A4 96.97%)'
  },
  {
    name: 'Hip Hop',
    gradient: 'linear-gradient(15deg, #E96443 5.76%, #904E95 96.97%)'
    },
  {
    name: 'World',
    gradient: 'linear-gradient(14deg, #19547B 3.49%, #FFD89B 106.74%)'
  },
  {
    name: 'Other',
    gradient: 'linear-gradient(0deg, #EACDA3 0%, #D6AE7B 100%)'
  }
];

const GenreModal = ({ onClose, onGenreSelect, clearGenre }) => {
  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          {/* Clear Genres Button */}
          <h2>Genres</h2>
          <button className={styles.closeButton} onClick={onClose}>×</button>
        </div>

        <div className={styles.genreGrid}>
          {genres.map((genre) => (
            <div
              key={genre.name}
              className={styles.genreCard}
              onClick={() => {
                onGenreSelect(genre.name);
                onClose();
              }}
              style={{ background: genre.gradient }}
            >
              <span className={styles.genreName}>{genre.name}</span>
            </div>
          ))}
        </div>
        <button 
          className={styles.clearButton} 
          onClick={() => {
            onGenreSelect(null);
            onClose(); 
          }}
        >
          Clear
        </button>
      </div>
    </div>
  );
};

export default GenreModal; 