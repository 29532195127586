import React, { useState } from 'react';
import styles from '../styles/components/search-filter-button.module.css';

const SearchFilterButton = ({ onFilter, activeButton }) => {
  // @shiv @scale we can add location/venue filter (let them select like eventName search)
  const maxPrice = 1000;
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [priceRange, setPriceRange] = useState([0, maxPrice]);
  const [tixType, setTixType] = useState('');
  const [availability, setAvailability] = useState('');

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    if (activeButton === 'listings') {
      onFilter({ startDate, endDate, priceRange, availability, tixType });
    } else if (activeButton === 'swaps') {
      onFilter({ startDate, endDate, availability, tixType });
    }
    closeModal(); // Close modal after applying filters
  };

  const handleResetFilters = () => {
    setStartDate('');
    setEndDate('');
    setPriceRange([0, maxPrice]);
    setTixType('');
    setAvailability('');

    onFilter({ startDate: '', endDate: '', priceRange: [0, maxPrice], tixType: '', availability: '' });

    closeModal(); // Close modal after resetting filters
  };

  return (
    <div className={styles.filterContainer}>
      <div className={styles.SearchFilterButton}>
      <button onClick={openModal}>
        Filter
      </button>
      </div>

      {showModal && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <button className={styles.closeButton} onClick={closeModal}>
              &times;
            </button>
            <h2>Apply select filters</h2>
            <form onSubmit={handleFilterSubmit} className={styles.filterForm}>
              <label>
                Start Date:
                <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              </label>
              <label>
                End Date:
                <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
              </label>
              <label>
                Ticket Type:
                <select value={tixType} onChange={(e) => setTixType(e.target.value)}>
                  <option value="">Select level</option>
                  <option value="VIP">VIP</option>
                  <option value="GA">GA</option>
                </select>
              </label>
              <label>
                Num of Tickets Available:
                <input
                  type="number"
                  min="1"
                  value={availability}
                  onChange={(e) => setAvailability(e.target.value)}
                />
              </label>
              {activeButton === 'listings' && (
                <>
                  <label>
                    Price Range:
                    <input
                      type="range"
                      min="0"
                      max="2000"
                      value={priceRange[1]}
                      onChange={(e) => setPriceRange([priceRange[0], Number(e.target.value)])}
                    />
                    {priceRange[1]}
                  </label>
                </>
              )}
              <div className={styles.modalButtons}>
              <button type="submit">Apply Filters</button>
              <button type="button" onClick={handleResetFilters}>Reset Filters</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchFilterButton;
