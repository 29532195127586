import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import AddConcertModal from "../components/modals/action-modals/addConcertModal";
import EditProfileModal from "../components/modals/action-modals/profileModal";
import EditListingModal from "../components/sale-listings/editListingModal";
import EditSwapModal from "../components/swap-listings/editSwapModal";
import styles from "../styles/pages/profile-page.module.css";
import DropdownButton from "../components/buttons/dropdown-button";
import { TabBar } from "../components/profile_tabs/tabBar";
import { PrivateSaleCard } from "../components/sale-listings/private-sale-listing";
import { PrivateSwapCard } from "../components/swap-listings/private-swap-listing";
import { PrivateLineupCard } from "../components/lineup/private-lineup";

export const ProfilePageTest = () => {
  const REACT_APP_API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";
  const REACT_APP_FRONTEND_BASE_URL =
    process.env.REACT_APP_FRONTEND_BASE_URL || "http://localhost:3000";

  const { user } = useAuth0();
  const navigate = useNavigate();
  const [editListingModalOpen, setEditListingModalOpen] = useState(false);
  const [editSwapModalOpen, setEditSwapModalOpen] = useState(false);
  const [addConcertModalOpen, setAddConcertModalOpen] = useState(false);
  const [editProfileModalOpen,   setEditProfileModalOpen] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);
  const [selectedSwap, setSelectedSwap] = useState(null);
  const [activeTab, setActiveTab] = useState("ticket");
  const [activeButton, setActiveButton] = useState("activelistings");
  const [activeListings, setActiveListings] = useState([]);
  const [soldListings, setSoldListings] = useState([]);
  const [activeSwaps, setActiveSwaps] = useState([]);
  const [pastSwaps, setPastSwaps] = useState([]);
  const [savedListings, setSavedListings] = useState([]);
  const [savedSwaps, setSavedSwaps] = useState([]);
  const [myConcerts, setMyConcerts] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [profileImage, setProfileImage] = useState('');


  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 540); // mobile view?
  const loadProfileImage = async (profilepic) => {
    console.log('profilepic:', profilepic);
    // Check if the image is stored in cloud
    if (profilepic.startsWith("https://campus-ticket.s3.")) {
      const pictureKey = profilepic.split('.com/')[1];
      
      try {
        const response = await axios.post(`${REACT_APP_API_BASE_URL}/api/getSignedUrl`, {
          pictureKey: pictureKey,
        });

        const { signedUrl } = response.data;
        console.log('signedUrl:', signedUrl);
        // Use the signed URL to load the image
        setProfileImage(signedUrl);
      } catch (error) {
        console.error("Error fetching signed URL", error);
      }
    } else {
      // Use the local or alternative image URL
      setProfileImage(profilepic);
    }
  };

  const fetchListings = useCallback(async () => {
    if (!user) return;

    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/api/listings/user`,
        {
          params: { email: user.email },
        }
      );
      const allListings = response.data;

      const active = allListings.filter(
        (listing) =>
          !listing.deleted && 
        listing.state.toLowerCase() === "available" && 
        new Date(listing.date) > Date.now() 
        );
      setActiveListings(active);

      const sold = allListings.filter(
        (listing) => listing.state.toLowerCase() === "sold" && !listing.deleted
      );
      setSoldListings(sold);

    const expiredListingIds = allListings
      .filter(listing => 
        new Date(listing.date) < Date.now() && 
        listing.state.toLowerCase() === "available" &&
        !listing.deleted
      )
      .map(listing => listing._id);
      
    // updateExpiredListings
    if (expiredListingIds.length > 0) {
      try {
        const response = await axios.patch(
          `${REACT_APP_API_BASE_URL}/api/listings/batch/updateExpired`,
          { expiredIds: expiredListingIds }
        );
        console.log("Expired listings updated:", response.data);
      } catch (error) {
        console.error("Failed to update expired listings:", error);
      }
    }

    } catch (error) {
      console.error("Oh no! Failed to fetch listings:", error);
    }
  }, [user, REACT_APP_API_BASE_URL]);

  
  const fetchSwaps = useCallback(async () => {
    if (!user) return;

    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/api/swaps/user`,
        {
          params: { email: user.email },
        }
      );
      const allSwaps = response.data;
      const today = new Date();
      const active = allSwaps.filter(
        (swap) =>
          !swap.deleted && 
        swap.state.toLowerCase() === "available" &&
        new Date(swap.expiry) > today 
      );
      setActiveSwaps(active);

      const pastSwaps = allSwaps.filter(
        (swap) => 
          new Date(swap.expiry) < today &&
          !swap.deleted && 
          swap.state.toLowerCase() !== "available"
      );
      
      setPastSwaps(pastSwaps);

    // Find expired swaps
    const expiredSwapIds = allSwaps
      .filter(
        (swap) =>
          new Date(swap.expiry) < today &&
          swap.state.toLowerCase() === "available" &&
          !swap.deleted
      )
      .map(swap => swap._id); 

    // updateExpiredListings
    if (expiredSwapIds.length > 0) {
      try {
        const response = await axios.patch(
          `${REACT_APP_API_BASE_URL}/api/swaps/batch/updateExpired`,
          { expiredIds: expiredSwapIds }
        );
        console.log("Expired swaps updated:", response.data);
      } catch (error) {
        console.error("Failed to update expired swaps:", error);
      }
    }

    } catch (error) {
      console.error("Oh no! Failed to fetch swaps:", error);
    }
  }, [user, REACT_APP_API_BASE_URL]);
  

  const fetchMyLineup = useCallback(async () => {
    if (!user || !user.email) return;

    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/api/lineups/user`,
        {
          params: { user_email: user.email },
        }
      );
      const myLineup = response.data.filter(
        (concert) => !concert.deleted && concert.user_email === user.email
      );

      setMyConcerts(myLineup);
    } catch (error) {
      console.error("Oh no! Failed to fetch lineup:", error);
    }
  }, [user, REACT_APP_API_BASE_URL]);


  // Fetch saved listings by IDs
const fetchListingsByIds = async (ids) => {
  if (ids.length === 0) return []; 

  try {
    const response = await axios.post(`${REACT_APP_API_BASE_URL}/api/tickets/batchListings`, { ids });
    return response.data; 
  } catch (error) {
    console.error("Error fetching listings by IDs:", error);
    return [];
  }
};

// Fetch saved swaps by IDs
const fetchSwapsByIds = async (ids) => {
  if (ids.length === 0) return [];

  try {
    const response = await axios.post(`${REACT_APP_API_BASE_URL}/api/tickets/batchSwaps`, { ids });
    return response.data; 
  } catch (error) {
    console.error("Error fetching swaps by IDs:", error);
    return []; 
  }
};

// Update fetchSavedList to fetch both listings and swaps
const fetchSavedList = useCallback(async () => {
  if (!user || !user.email) return;

  try {
    const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/saved/user`, {
      params: { userId: user.sub },
    });
    const savedlist = response.data;

    if (savedlist.length > 0) {
      const userSavedlist = savedlist[0]; 
      const savedlistSaleListings = userSavedlist.listings || []; 
      const savedlistTicketSwaps = userSavedlist.swaps || [];

      // Fetch the actual listing objects using the IDs
      const listings = await fetchListingsByIds(savedlistSaleListings);
      const swaps = await fetchSwapsByIds(savedlistTicketSwaps);
      
      setSavedListings(listings);
      setSavedSwaps(swaps);
    } else {
      setSavedListings([]);
      setSavedSwaps([]);
    }
  } catch (error) {
    console.error("Oh no! Failed to fetch saved listings:", error);
  }

}, [user, REACT_APP_API_BASE_URL]);
  
  useEffect(() => {
    loadProfileImage(user.picture);
    fetchListings();
    fetchSwaps();
    fetchMyLineup();
    fetchSavedList();
  }, [
    user,
    fetchListings,
    fetchSwaps,
    fetchMyLineup,
    fetchSavedList,
    REACT_APP_API_BASE_URL,
  ]);

  // handling listings
  const handleUpdateListing = async (id, updatedFields) => {
    try {
      await axios.patch(
        `${REACT_APP_API_BASE_URL}/api/listings/${id}`,
        updatedFields
      );
      fetchListings();
      setEditListingModalOpen(false); // Close modal after update
    } catch (error) {
      console.error("Failed to update listing:", error);
    }
  };

  const handleDeleteListing = async (id) => {
    try {
      await axios.delete(`${REACT_APP_API_BASE_URL}/api/listings/${id}`);
      fetchListings();
    } catch (error) {
      console.error("Failed to delete listing:", error);
    }
  };

  const handleEditListing = (listing) => {
    setSelectedListing(listing);
    setEditListingModalOpen(true);
  };

  const closeEditListingModal = () => {
    setEditListingModalOpen(false);
    setSelectedListing(null);
    fetchListings();
  };

  // handling swaps
  const handleUpdateSwap = async (id, updatedFields) => {
    try {
      await axios.patch(
        `${REACT_APP_API_BASE_URL}/api/swaps/${id}`,
        updatedFields
      );
      fetchSwaps();
      setEditSwapModalOpen(false); // Close modal after update
    } catch (error) {
      console.error("Failed to update swap:", error);
    }
  };

  // @shiv test the buttons !
  const handleDeleteSwap = async (id) => {
    try {
      await axios.delete(`${REACT_APP_API_BASE_URL}/api/swaps/${id}`);
      fetchSwaps();
    } catch (error) {
      console.error("Failed to delete swap:", error);
    }
  };

  const handleDeleteConcert = async (id) => {
    try {
      await axios.delete(`${REACT_APP_API_BASE_URL}/api/lineups/${id}`);
      fetchMyLineup();
    } catch (error) {
      console.error("Failed to delete concert:", error);
    }
  };

  const handleMarkAsSold = async (id) => {
    try {
      await axios.patch(`${REACT_APP_API_BASE_URL}/api/listings/mark-sold/${id}`, {
        state: "Sold",
      });
      fetchListings();
    } catch (error) {
      console.error("Failed to mark listing as sold:", error);
    }
  };

  const handleEditSwap = (swap) => {
    setSelectedSwap(swap);
    setEditSwapModalOpen(true);
  };

  const closeEditSwapModal = () => {
    setEditSwapModalOpen(false);
    setSelectedSwap(null);
    fetchSwaps();
  };

  const handleShareProfile = () => {
    const profileLink = `${REACT_APP_FRONTEND_BASE_URL}/profile/${user.instagram_handle}`;
    navigator.clipboard
      .writeText(profileLink)
      .then(() => alert("Profile link copied to clipboard!"))
      .catch((error) => console.error("Failed to copy profile link:", error));
  };

  const handleAddNewListingOrSwap = () => {
    window.location.href = `${REACT_APP_FRONTEND_BASE_URL}/sell`;
  };

  const handleVistChatPg = () => {
    navigate('/chat');
  };

  const getActiveButtonLabel = () => {
    switch (activeButton) {
      case "lineup":
        return "Concert Lineup";
      case "activelistings":
        return "Active";
      case "soldlistings":
        return "Sold";
      case "activeswaps":
        return "Active";
      case "pastswaps":
        return "Past";
      case "savedlistings":
        return "Sale";
      case "savedswaps":
        return "Swap";
      default:
        return "...";
    }
  };

  const handleAddConcertOpen = () => {
    setAddConcertModalOpen(true);
  };

  const closeAddConcertModal = () => {
    setAddConcertModalOpen(false);
    fetchMyLineup();
    setActiveTab("concert");
    setActiveButton("lineup");
  };


  const handleEditProfileOpen = () => {
    setEditProfileModalOpen(true);
  };

  const closeEditProfileModal = () => {
    setEditProfileModalOpen(false);
  };
  
  const mapListings = activeButton === "activelistings" ? activeListings 
  : activeButton === "soldlistings" ? soldListings 
  : [];

const mapSwaps = activeButton === "activeswaps" ? activeSwaps 
: activeButton === "pastswaps" ? pastSwaps 
: [];

  // Toggle the expanded state
  const toggleDisclaimer = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <PageLayout>
      <div className={styles.content__layout}>
        <div className="content__body">
          <div className={styles["profile-grid"]}>
            <div className={styles.profile_header}>
              <div className={styles.profile_card}>
              <div className={styles.profile__headline}>
                {!isMobileView ? (
                  <>
                  <img
                  src={profileImage}
                  alt="Profile"
                  className={styles["profile__avatar"]}
                  />
                  <div className={styles.user_info}>
                    <h2 className={styles["profile__title"]}>
                      @{user.instagram_handle}
                    </h2>
                    <span className={styles["profile__description"]}>
                      {user.email}
                    </span>
                  </div>
                </>
              ) : (
                <div className={styles.user_info}>
                  <h2 className={styles["profile__title"]}>
                    @{user.instagram_handle}
                  </h2>
                  <span className={styles["profile__description"]}>
                    {user.email}
                  </span>
                </div>
              )}
              </div>
            </div>

              
                <div className={styles.other_actions}>
                  <h2 className={styles.action_title}>
                    {isMobileView ? "Actions" : "Actions"}
                  </h2>
                  <h3 onClick={handleAddConcertOpen}>
                    {isMobileView ? "Concert Lineup" : "Concert Lineup"}
                  </h3>
                  <h3 onClick={handleShareProfile}>
                    {isMobileView ? "Share Profile" : "Share Profile"}
                  </h3>
                  <h3 onClick={handleEditProfileOpen}>
                    {/* edit profile will change later */}
                    {isMobileView ? "✏️ Profile" : "✏️ Profile"}
                  </h3>
                  {/* <h3 onClick={handleVistChatPg}>
                    {isMobileView ? "My Chats" : "My Chats"}
                  </h3> */}
                </div>
            </div>
            <div className={styles.listing_details}>
            <p style={{textWrap: "pretty"}} className={styles.disclaimer}>
            <span>
              Disclaimer{' '}
              {isExpanded && (
                <span>
                  {' '}
                  - Everything posted can be accessed by all users via your&nbsp;
                  <a
                    href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/profile/${user.instagram_handle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.aLink}
                  >
                    public profile page. {' '}
                  </a>
                </span>
              )}
            </span>
            <button onClick={toggleDisclaimer} className={styles.toggleDisclaimerButton}>
              {/* @clark thoughts on replacing >> and << w emojis? */}
              {isExpanded ? ' ⏪ ' : ' ⏩ '}
            </button>
          </p>
            {/* Tab bar */}
              <div>
              <TabBar activeTab={activeTab} setActiveTab={setActiveTab} setActiveButton={setActiveButton} />
              </div>
            {/* Ticket tab */}
              <div>
                {activeTab === 'ticket' && <div className={styles.listings_bar}>
                {(activeButton === 'activelistings' || activeButton === 'soldlistings') ? (
                    <h3>My Sale Listings</h3>
                  ) : (activeButton === 'activeswaps' || activeButton === 'pastswaps') ? (
                    <h3>My Ticketswap Listings</h3>
                  ) : null}
                <DropdownButton label={getActiveButtonLabel()}>
                  <button
                    onClick={() => {
                      setActiveButton("activelistings");
                    }}
                  >
                    Active
                  </button>
                  <button
                    onClick={() => {
                      setActiveButton("soldlistings");
                    }}
                  >
                    Sold
                  </button>
                  <button onClick={() => {
                      setActiveButton("activeswaps");
                    }}>Active Swaps</button>
                  <button onClick={() => {
                      setActiveButton("pastswaps");
                    }}>Past Swaps</button>
                </DropdownButton>
                </div>}
              {/* Concert tab */}  
              {activeTab === 'concert' && <div className={styles.listings_bar}>
                <h3>My Concerts</h3> 
                {/*<DropdownButton label={getActiveButtonLabel()} onClick={() => {
                      setActiveButton("lineup");
                    }}>
                </DropdownButton>*/ }
                </div>}
               {/* Saved tab */} 
                {activeTab === 'saved' && <div className={styles.listings_bar}>
                  <h3>My Saved Listings</h3>
                <DropdownButton label={getActiveButtonLabel()}>
                <button onClick={() => {
                      setActiveButton("savedlistings");
                    }}>Sale</button>
                <button onClick={() => {
                      setActiveButton("savedswaps");
                    }}>Swaps</button>
                </DropdownButton>
                </div>}
              </div>

            <div className={styles.listings_grid}>
                {activeTab === "ticket" && (
                  <div className={styles["grid-container"]}>
                    {/* Active Listings or Sold Listings */}
                    {(activeButton === "activelistings" || activeButton === "soldlistings") ? (
                      mapListings.length > 0 ? (
                        mapListings.map((listing) => (
                          <PrivateSaleCard
                            key={listing._id}
                            listing={listing}
                            handleEdit={handleEditListing}
                            handleMarkAsSold={handleMarkAsSold}
                            handleDelete={handleDeleteListing}
                          />
                        ))
                      ) : (
                        <p>No prior sale listings. Click to <a href="/sell">create a listing here</a> or <a href="/listings">discover listings</a>.</p>
                      )
                    ) : null}
                    {/* Active Swaps or Past Swaps */}
                    {(activeButton === "activeswaps" || activeButton === "pastswaps") ? (
                      mapSwaps.length > 0 ? (
                        mapSwaps.map((swap) => (
                          <PrivateSwapCard
                            key={swap._id}
                            swap={swap}
                            handleEdit={handleEditSwap}
                            handleDelete={handleDeleteSwap}
                          />
                        ))
                      ) : (
                        <p>No prior ticketswaps. Click to <a href="/sell">create a swap</a> or <a href="/listings">discover swaps</a>.</p>
                      )
                    ) : null}
                  </div>
                )}
                {/* Concert lineups */}
                {(activeTab === "concert" && activeButton === "lineup") ? (
                  <div className={styles["concert-grid-container"]}>
                    {myConcerts.length > 0 ? (
                      myConcerts.map((concert) => (
                        <PrivateLineupCard
                          key={concert._id}
                          concert={concert}
                          handleDelete={handleDeleteConcert}
                        />
                      ))
                    ) : (
                      <p className={styles.gridContainer}>Attending concerts? Click 'Concert Lineup' above to select your upcoming event and let others know you're going to "W2E on 2/3".</p>
                    )}
                  </div>
                ) : null}
                </div>
                {/* Saved listings */}
                {/* @shiv uses the privatesalecard for saved listings + swaps */}
                {activeTab === "saved" && (
                  <div className={styles["grid-container"]}>
                  {activeButton === "savedlistings" ? (
                    savedListings.length > 0 ? (
                      savedListings.map((listing) => (
                        <PrivateSaleCard
                          key={listing._id}
                          listing={listing}
                          refetchSavedList={fetchSavedList}
                        />
                      ))
                    ) : (
                      <p>Nothing saved. <a href="/listings">Discover more here</a>.</p>
                    )
                  ) : activeButton === "savedswaps" ? (
                    savedSwaps.length > 0 ? (
                      savedSwaps.map((swap) => (
                        <PrivateSwapCard
                          key={swap._id}
                          swap={swap}
                          refetchSavedList={fetchSavedList}
                        />
                      ))
                    ) : (
                      <p>Nothing saved. <a href="/listings">Discover more here</a>.</p>
                    )
                  ) : null}
                </div>
                )}
            </div>
            </div>
          </div>
        </div>
      {/* Edit Listing Modal */}
      {editListingModalOpen && (
        <EditListingModal
          isOpen={editListingModalOpen}
          closeModal={() => {
            closeEditListingModal();
          }}
          listing={selectedListing}
          updateListing={handleUpdateListing}
        />
      )}
      {/* Edit Swap Modal */}
      {editSwapModalOpen && (
        <EditSwapModal
          isOpen={editSwapModalOpen}
          closeModal={() => {
            closeEditSwapModal();
          }}
          swap={selectedSwap}
          updateSwap={handleUpdateSwap}
        />
      )}
      {/* New Concert Lineup Modal */}
      {addConcertModalOpen && (
        <AddConcertModal
          isOpen={addConcertModalOpen}
          closeModal={() => {
            closeAddConcertModal();
          }}
        />
      )}
      {/* Edit Profile Modal */}
      {editProfileModalOpen && (
        <EditProfileModal
          isOpen={editProfileModalOpen}
          closeModal={() => {
            closeEditProfileModal();
          }}
        />
      )}
    </PageLayout>
  );
};
