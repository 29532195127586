import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LoginButton = ({ redirectTo = "/profile", buttonText = "Log In", className = "button__login"  }) => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      prompt: "login",
      appState: {
        returnTo: redirectTo,
      },
    });
  };

  return (
    <button className={className}  onClick={handleLogin}>
     {buttonText}
    </button>
  );
};