import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app.js";
import { Auth0ProviderWithNavigate } from "./context/auth0-provider-with-navigate.js";
import { SocketContextProvider } from "./context/socket-provider.js";
import { NextUIProvider } from "@nextui-org/react";
import { ProfanityFilterProvider } from './context/ProfanityFilterContext.js';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from '@vercel/speed-insights/react';
import "./styles/styles.css";
import "./styles/tailwind.css";

const container = document.getElementById("root");
const root = createRoot(container);

if (process.env.NODE_ENV === "production") {
  console.log = () => {}; // Disabling all console.log in production
}

root.render(
  <>
    <React.StrictMode>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <SocketContextProvider>
            <NextUIProvider>
            <ProfanityFilterProvider> 
              <App />
              </ProfanityFilterProvider> 
              <Analytics />
              <SpeedInsights />
            </NextUIProvider>
          </SocketContextProvider>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </React.StrictMode>
  </>
);

// @import "theme.css";
// @import "general.css";
// @import "components/index.css";
