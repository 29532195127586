import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { MobileNavBarTab } from "./mobile-nav-bar-tab";

export const MobileNavBarTabs = ({ handleClick }) => {
  const { isAuthenticated } = useAuth0();

  // will return in order btw
  return (
    <div className="mobile-nav-bar__tabs">
            <MobileNavBarTab
        path="/events"
        label="Events"
        handleClick={handleClick}
      />
      <MobileNavBarTab
        path="/listings"
        label="Listings"
        handleClick={handleClick}
      />
    {isAuthenticated && (
        <>
      <MobileNavBarTab 
        path="/sell" 
        label="Sell" 
        handleClick={handleClick} 
      />
      <MobileNavBarTab
        path="/chat"
        label="Chat"
        handleClick={handleClick}
      />
      <MobileNavBarTab
        path="/profile"
        label="Profile"
        handleClick={handleClick}
      />
      </>
      )}
      <MobileNavBarTab
        path="/start"
        label="Start"
        handleClick={handleClick}
      />
    </div>
  );
};
