import React from 'react';
import styles from '../../styles/components/listing-confirmation-modal.module.css';



const ListingConfirmationModal = ({ eventUrl, closeModal }) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
      <button className={styles.closeButton} onClick={closeModal}>
              &times;
        </button>
        <h2>Listing posted successfully!</h2>
        <br/>
        <p>
          You can check out your listing for your event{" "}
          <a href={eventUrl} target="_blank" rel="noopener noreferrer">
            here
          </a>.
        </p>
      </div>
    </div>
  );
};

export default ListingConfirmationModal;
