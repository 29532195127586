import React from "react";
import { PageLayout } from "../components/page-layout";
import SupportPgTxt from "../components/about_txt/SupportPgTxt";

export const SupportPg = () => {
  return (
    <PageLayout>
      <div className="content-layout">
        <div className="content__body">
          <SupportPgTxt />
        </div>
      </div>
    </PageLayout>
  );
};

