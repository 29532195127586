// socket-provider.js
import { useAuth0 } from '@auth0/auth0-react';
const {createContext, useState, useEffect, useContext} = require('react');
const io = require('socket.io-client');
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';

export const SocketContext = createContext();

export const useSocketContext = () => {
    return useContext(SocketContext);
}

export const SocketContextProvider = ({children}) => {
    const { user } = useAuth0();
    const [socket, setSocket] = useState(null);
    const [onlineUsers, setOnlineUsers] = useState([]);

    useEffect(() => {
        if (user) {
            const socket = io(REACT_APP_API_BASE_URL, {
                query: {
                    userIg: user.instagram_handle,
                },
            });
            setSocket(socket);
    
            socket.on('online-users', (users) => {
                setOnlineUsers(users);
            });
    
            return () => {
                socket.close(); // This properly closes the socket connection
            };
        } else {
            if (socket) {
                socket.close();
                setSocket(null);
            }
        }
    }, [user]);
    

    return <SocketContext.Provider value={{socket, onlineUsers}}>{children} </SocketContext.Provider>;
};