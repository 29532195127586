import React, { useState } from "react";
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  ComboboxButton,
} from "@headlessui/react";
import styles from "../styles/components/event-search.module.css";

export const EventSelectSearch = ({ events, onChange, reset }) => {
  const [query, setQuery] = useState("");
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false); // Manage dropdown visibility

  // Reset the component when form is reset
  React.useEffect(() => {
      if (reset) {
        setQuery(""); 
        setSelectedArtists([]); 
        setDropdownOpen(false); // Close dropdown on reset
      }
    }, [reset]);

  // Sort events by dateTime
  const sortedEvents = events
    .map(event => ({
      ...event,
      dateTime: new Date(event.dateTime) // Convert to Date object
    }))
    .sort((a, b) => a.dateTime - b.dateTime);

  // Filter dropdown events based on the query
  const filteredEvents = (query || "").trim() === ""
    ? sortedEvents
    : sortedEvents.filter((event) => {
        const eventValue = event.eventName; // Get event value directly
        return eventValue && typeof eventValue === "string" && eventValue.toLowerCase().includes(query.toLowerCase());
      });

  // Toggle dropdown visibility when the button is clicked
  const handleIconClick = () => {
    setQuery('');
    setDropdownOpen(true); 
  };

  // Validate the selected value
  const isValidValue = (value) => {
    return value && value.trim() !== "" && !/^[\W_]+$/.test(value);
  };

  const handleSelectArtist = (value) => {
    if (isValidValue(value) && selectedArtists.length < 5 && !selectedArtists.includes(value)) {
      const updatedArtists = [...selectedArtists, value]; // Create a new array with the updated artists
      setSelectedArtists(updatedArtists);
      setQuery(""); // Clear query after selecting
      onChange(updatedArtists); // Pass the updated artists to the parent component
      setDropdownOpen(false); // Close dropdown after selection
    }
  };

   const handleRemoveArtist = (artist) => {
    const updatedArtists = selectedArtists.filter((a) => a !== artist); // Remove the artist
    setSelectedArtists(updatedArtists);
    onChange(updatedArtists); // Pass the updated artists to the parent component
  };

  return (
    <div className={styles.eventSearchContainer}>
      <Combobox
        as="div"
        value={query}
        onChange={handleSelectArtist}
      >
        <div className={styles.inputContainer}>
          <ComboboxInput
            className={styles.input}
            placeholder="Enter up to 5 preferred events for trade"
            onChange={(event) => setQuery(event.target.value)} // Update query when typing
            value={query}
            disabled={selectedArtists.length >= 5} // Disable input if 5 artists are selected
            onFocus={() => setDropdownOpen(true)} // Show dropdown when the input is focused
          />
          {/* Dropdown icon button */}
          <ComboboxButton as="button" className={styles.dropdownIndicator} onClick={handleIconClick}>
          </ComboboxButton>
        </div>

        {/* Dropdown options */}
        {dropdownOpen && (
          <ComboboxOptions className={styles.options}>
            {filteredEvents.length > 0 ? (
              filteredEvents.map((event) => (
                <ComboboxOption
                  key={event._id}
                  value={event.eventName}
                  className={styles.option}
                >
                  {event.eventName}
                </ComboboxOption>
              ))
            ) : (
              <div className={styles.noResults}>No results found</div>
            )}
          </ComboboxOptions>
        )}
      </Combobox>

      {/* Display selected artists as buttons */}
      <div className={styles.selectedArtistsContainer}>
        {selectedArtists.map((artist, index) => (
          <div key={index} className={styles.selectedArtist}>
            <span>{artist}</span>
            <button
              className={styles.removeButton}
              onClick={() => handleRemoveArtist(artist)}
            >
              ✕
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
