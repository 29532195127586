import React from "react";
import { TypeAnimation } from "react-type-animation";
import styles from "../styles/pages/landing-page.module.css";
import { ReactComponent as TicketIcon } from "../styles/img/Final-Ticket-Small.svg";
import { ReactComponent as TicketIconDuplicate } from "../styles/img/Final-Ticket-Small-2.svg";


const LandingPage = () => {
  return (
    <div className={styles.container}>
      <TicketIconDuplicate
        className={styles.mobileTicketLogo}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
        aria-label="campusticket logo"
      />
      <div className={styles.topGroup}>
        <div className={styles.titleGroup}>
          <div className={styles.titleWrapper}>
            <h1 className={styles.title}>Welcome to CampusTicket</h1>
            <TicketIcon
              className={styles.desktopTicketLogo}
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid meet"
              aria-label="campusticket logo"
            />
          </div>
        </div>
        <div className={styles.messageContainer}>
          <div className={styles.schoolMessage}>
            <p className={styles.message}>
              Helping students at{" "}
              <span className={styles.animationWrapper}>
              <TypeAnimation
                sequence={[
                  "Berkeley🐻",
                  1500,
                  "Stanford🌲",
                  1500,
                  "UCLA🧸",
                  1500,
                  "USC✌️",
                  1500,
                  "SMU🐎",
                  1500,
                ]}
                wrapper="span"
                speed={50}
                repeat={Infinity}
                style={{ display: "flex" }}
              />
            </span>
            <br></br>
            buy and sell tickets with{" "}
            <span style={{ color: "var(--main-yellow)" }}>no fees</span>
          </p>
          </div>
        </div>
      </div>

      <div className={styles.bottomGroup}>
        <div className={styles.buySellButtonsContainer}>
          <a href="/events" className={styles.buySellButtons}>
            Buy tickets
          </a>
          <a href="/sell" className={styles.buySellButtons}>
            Sell tickets
          </a>
        </div>
        <a href="/" className={styles.finalMessage}>
          Neither, just browsing today
        </a>
      </div>
    </div>
  );
};

export default LandingPage;
