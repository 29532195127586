// chat-page.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import styles from "../styles/pages/chat-page.module.css";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayoutNoFooter } from "../components/page-layout-no-footer";
import NewChatModal from "../components/modals/newChatModal";
import ChatDisplay from "../components/chatDisplay";
import { useSocketContext } from "../context/socket-provider";
import NewChatIcon from "../styles/img/NewChat.svg";

const REACT_APP_API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";

export const ChatPage = () => {
  const { user } = useAuth0();
  // convos = accepted chats
  const [conversations, setConversations] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  // pending chats are chats you send out and waiting for users to accept invite
  const [pendingSentChats, setPendingSentChats] = useState(null);
  // depending on selected chat, RHS will show, can set to most recent convo
  // order convo and req lsts by historyBackup
  const [chatInvites, setChatInvites] = useState([]);
  const [showNewChatModal, setShowNewChatModal] = useState(false);
  const [newChatName, setNewChatName] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [invitedUserIG, setInvitedUserIG] = useState("");
  // Toggle chat invites
  const [showRequests, setShowRequests] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const timerRef = useRef(null);
  const [selectedUserIg, setSelectedUserIg] = useState("");
  const [userId, setUserId] = useState("");
  const [userIg, setUserIg] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const { socket } = useSocketContext();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 540);
  const [showChatDisplay, setShowChatDisplay] = useState(false);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [isFromBlockedUser, setIsFromBlockedUser] = useState(false); 

  useEffect(() => {
    const fetchBlockedUsers = async () => {
      try {
        if (user) {
          // Fetch blocked users
          const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/getUserMetadata/${user.sub}`);
          const blockedUsersData = response.data.metadata.blocked_users;
          setBlockedUsers(blockedUsersData);
          console.log('blockedUsers:', blockedUsersData);
  
          // Fetch ticket details after blocked users are set
          fetchAcceptedChats(blockedUsers);
        }
      } catch (error) {
        console.error('Error fetching blocked users:', error);
        setError('Failed to fetch blocked users.');
      }
    };
    
    fetchBlockedUsers();
  }, [user, REACT_APP_API_BASE_URL]);  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 540);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch accepted chats
  const fetchAcceptedChats = useCallback(() => {
    axios
      .get(
        `${REACT_APP_API_BASE_URL}/api/chats/accepted-chats/${user.instagram_handle}`
      )
      .then((res) => {
        const allChats = res.data;

        // Separate chats into accepted and pending based on the number of accepted members
        // const acceptedChats = allChats.filter(
        //   (chat) => chat.accepted_members_ig.length > 1
        // );
        // const sentChats = allChats.filter(
        //   (chat) => chat.accepted_members_ig.length === 1 && chat.invited_members_ig.length === 1
        // );
        const acceptedChats = allChats.filter((chat) => chat.accepted_members_ig.length > 1 && 
          !chat.accepted_members_id.some((id) => blockedUsers.includes(id))
        );
        const sentChats = allChats.filter((chat) => (chat.accepted_members_ig.length === 1 && chat.invited_members_ig.length === 1) && 
          !chat.invited_members_id.some((id) => blockedUsers.includes(id))
        );

        // Set state for accepted conversations
        setConversations(acceptedChats);

        // Set state for pending chats
        setPendingSentChats(sentChats);

        console.log("Accepted chats:", acceptedChats);
        console.log("Pending invites:", sentChats);
      })
      .catch((error) => {
        console.error("Error fetching accepted chats:", error);
      });
  }, [user.instagram_handle]);

  // Use effect to fetch chats based on showRequests
  // useEffect(() => {
  //   if (!showRequests) {
  //     fetchAcceptedChats();
  //   }
  // }, [showRequests, fetchAcceptedChats]);

  const fetchChatInvites = useCallback(() => {
    axios
      .get(
        `${REACT_APP_API_BASE_URL}/api/chats/invited-chats/${user.instagram_handle}`
      )
      .then((res) => setChatInvites(res.data))
      .catch((error) => console.error("Error fetching chat invites:", error));
  }, [user.instagram_handle]);

  useEffect(() => {
    if (showRequests) {
      fetchChatInvites(blockedUsers);
      fetchAcceptedChats(blockedUsers);
      setConversations([]);
    }
  }, [showRequests, fetchChatInvites]);

  // Helper function to merge messages without duplication
  const mergeMessages = (oldMessages, newMessages) => {
    const combined = [
      ...oldMessages,
      ...newMessages.filter(
        (newMsg) => !oldMessages.find((oldMsg) => oldMsg._id === newMsg._id)
      ),
    ];
    return combined.sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
  };

  useEffect(() => {
    if (user && selectedChat) {
      // Join the selected chat room
      socket.emit("joinChat", selectedChat._id);

      const handleMessage = (message) => {
        setMessages((prevMessages) => mergeMessages(prevMessages, [message]));
      };
      socket.on("message", handleMessage);

      // Cleanup on component unmount or chat change
      return () => {
        socket.emit("leaveChat", selectedChat._id);
        socket.off("message", handleMessage); // Ensure to clean up the listener properly
      };
    }
    // return () => { socket.emit('disconnect', { userIg: user.instagram_handle }); };
  }, [user, selectedChat]);

  // Handle chat selection and fetching chat history
  const handleSelectChat = async (chat) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/api/chats/history/${chat._id}`
      );
      const chatHistory = response.data;
      setSelectedChat({
        ...chat,
        messages: chatHistory,
      });

      socket.emit("joinChat", chat._id);
      console.log("opening socket chat from client");

      if (isMobile) {
        setShowChatDisplay(true);
      }
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };

  // Toggle showing chat requests
  const handleToggleRequests = () => {
    setShowRequests(!showRequests);
  };

  const handleCreateChat = async () => {
    if (!newChatName || !selectedUserIg) {
      setError("Chat name and user to invite are required");
      return;
    }

    const chatData = {
      chatName: newChatName,
      creatorId: userId,
      creatorIg: userIg,
      creatorEmail: userEmail,
      invited_members_ig: [selectedUserIg],
      historyBackup: Date.now(),
    };

    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URL}/api/chats`,
        chatData
      );
      const newChat = response.data;
      // Update UI for the new chat
      setChats([...chats, newChat]);
      setNewChatName("");
      setSelectedUserIg("");
      setError(null);

      // Fetch new chat history
      const chatHistoryResponse = await axios.get(
        `${REACT_APP_API_BASE_URL}/api/chats/history/${newChat._id}`
      );
      const chatHistory = chatHistoryResponse.data;
      setChatHistory(chatHistory);
    } catch (error) {
      console.error("Error creating chat:", error);
      setError("Failed to create chat");
    }
  };

  // Accept Chat Invites
  const handleAcceptInvite = async (chatId) => {
    // Emit socket event for real-time update
    socket.emit("acceptInvite", {
      chatId,
      userId: user.sub,
      userIG: user.instagram_handle,
    });

    try {
      const response = await axios.patch(
        `${REACT_APP_API_BASE_URL}/api/chats/${chatId}/accept`,
        {
          userId: user.sub,
          userIG: user.instagram_handle, // Ensure this matches the backend field
        }
      );

      if (response.status === 200) {
        showConversations(); // Refresh the chat list or perform any other update
      } else {
        console.error("Failed to accept invite. Status:", response.status);
      }
    } catch (error) {
      console.error(
        "Error accepting invite:",
        error.response ? error.response.data : error.message
      );
    }
  };

  // Handle rejecting a chat invite (Socket.io)
  const handleRejectInvite = async (chatId) => {
    socket.emit("rejectInvite", { chatId, userIG: user.instagram_handle });

    try {
      const response = await axios.patch(
        `${REACT_APP_API_BASE_URL}/api/chats/${chatId}/reject`,
        {
          userIG: user.instagram_handle,
        }
      );

      if (response.status === 200) {
        fetchChatInvites(); // refresh chat invites
      } else {
        console.error("Failed to reject invite. Status:", response.status);
      }
    } catch (error) {
      console.error(
        "Error rejecting invite:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleCloseModal = () => {
    setShowNewChatModal(false);
    fetchAcceptedChats();
  };

  const handleOpenModal = () => {
    setShowNewChatModal(true);
    handleCreateChat();
  };

  const showConversations = () => {
    fetchAcceptedChats();
    setShowRequests(false);
  };

  const handleBackButton = () => {
    setShowChatDisplay(false);
    setSelectedChat(null);
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: '2-digit', // 2-digit year
      month: 'numeric', // numeric month
      day: 'numeric', // numeric day
      hour: 'numeric', // numeric hour
      minute: 'numeric', // numeric minute
      hour12: true, // 12-hour clock
    };
    return date.toLocaleString('en-US', options).replace(',', ' @'); // Replace the comma with '@'
  };

  return (
    <PageLayoutNoFooter>
      <div className={styles.chatPageTitleDiv}>
        <h3 className={styles.chatPageTitle}>
          {showRequests ? "Chat Requests" : "Conversations"}
        </h3>
      </div>
      <div className={styles.chatPage}>
      {(!isMobile || (isMobile && !selectedChat)) && (
        <div className={styles.conversationsList}>
          {/* LHS */}

          <div className={styles.chatControls}>
            <button
              onClick={handleToggleRequests}
              className={styles.requestsButton}
            >
              {showRequests ? "Back to Chats" : "View Requests"}
            </button>
            <img
              src={NewChatIcon}
              alt="New Chat"
              onClick={() => setShowNewChatModal(true)}
              className={styles.newChatIcon}
            />
          </div>

          {showRequests && (
          <>
            <div className={styles.gridContainer}>
              {chatInvites?.length > 0 ? (
                <>
                  <h2 className={styles.reqHeaders}>My invite requests:</h2>
                  {chatInvites?.map((invite) => {
                  const formattedLocalDate = formatDate(invite.historyBackup);
                  return (
                    <div key={invite._id} className={styles.eventCard}>
                      <p>Invite from: @{invite.creatorIg}</p>
                      <p>{invite.chatName}</p>
                      <p>{formattedLocalDate}</p>
                      <p>
                        Msg: {invite.messages.length > 0 
                          ? invite.messages[invite.messages.length - 1].text 
                          : ''}
                      </p>
                      <div className={styles.reqActionButtonWrapper}>
                        <button onClick={() => handleAcceptInvite(invite._id)}
                          className={styles.reqActionButton}
                          >
                          Accept
                        </button>
                        <button onClick={() => handleRejectInvite(invite._id)}
                          className={styles.reqActionButton}>
                          Reject
                        </button>
                      </div>
                      {/* Display success or error messages */}
                      {success && <p style={{ color: "green" }}>{success}</p>}
                      {error && <p style={{ color: "red" }}>{error}</p>}
                    </div>
                  );
                  })}
                </>
              ) : (
                <h2 className={styles.reqHeaders}>No invite requests.</h2> // When there are no invites
              )}
            </div>
              <div className={styles.gridContainer}>
              {pendingSentChats?.length > 0 && (
                  <h2 className={styles.reqHeaders}>My pending sent requests:</h2>
                )}
                {pendingSentChats?.map((invite) => {
                  const invitedPpl = invite.invited_members_ig;
                  const formattedLocalDate = formatDate(invite.historyBackup); // Format the date

                  return (
                    <div key={invite._id} className={styles.eventCard}>
                      <p>Invited: @{invitedPpl.join(", ")}</p>
                      <p>{invite.chatName}</p>
                      <p>{formattedLocalDate}</p>
                    </div>
                  );
                })}
                </div>
            </>
          )}

          {!showRequests && (
            <>
          <div className={styles.gridContainer}>
            {conversations.map((chat) => {
              // get last message
              const lastMessageObj =
                chat.messages.length > 0
                  ? chat.messages[chat.messages.length - 1]
                  : null;
              // get sender's IG handle
              const lastMessageSender = lastMessageObj
                ? chat.accepted_members_ig[
                    chat.accepted_members_id.indexOf(lastMessageObj.sender)
                  ]
                : "User";
              // get last message
              const lastMessage = lastMessageObj
                ? `${lastMessageSender}: ${lastMessageObj.text}`
                : "No messages yet";

              return (
                <div
                  key={chat._id}
                  className={styles.chatPreview}
                  onClick={() => handleSelectChat(chat)}
                >
                  <div className={styles.chatPreviewHeader}>
                    <span className={styles.userName}>
                      {chat.chatName || "Chat"}
                    </span>
                    <span className={styles.timeStamp}>
                      {chat.historyBackup
                        ? new Date(chat.historyBackup).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        : ""}
                    </span>
                  </div>
                  <div className={styles.chatPreviewContent}>
                    <p>@{chat.creatorIg || "User"}</p>
                    {/* Display the formatted last message */}
                    <p>{lastMessage}</p>
                  </div>
                </div>
              );
            })}
            {/* New div with motivational text */}
            <div className={styles.motivationalText}>
              <p>Keep Selling Those Tickets!</p>
            </div>
          </div>
          </>
          )}
        </div>
        )}
        
        {/* Chat modal */}
        {showNewChatModal && (
          <NewChatModal
            newChatName={newChatName}
            setNewChatName={setNewChatName}
            invitedUserIG={selectedUserIg}
            setInvitedUserIG={setInvitedUserIG}
            onCreateChat={handleCreateChat}
            acceptedChats={conversations} 
            pendingSentChats={pendingSentChats}
            chatInvites={chatInvites}
            onClose={() => setShowNewChatModal(false)}
          />
        )}
        {/* RHS */}
        {(!isMobile || (isMobile && selectedChat)) && (
          <div className={styles.chatDisplayContainer}>
            <ChatDisplay socket={socket} selectedChat={selectedChat} isMobile={isMobile} handleBackButton={handleBackButton} />
          </div>
        )}
      </div>
    </PageLayoutNoFooter>
  );
};
