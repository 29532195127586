import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const SignupButton = ({ redirectTo = "/login", buttonText = "Sign Up", className = "button__sign-up" }) => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = async () => {
    await loginWithRedirect({
      prompt: "login",
      screen_hint: "signup",
      appState: {
        returnTo: redirectTo,
      },
    });
  };

  return (
    <button className={className} onClick={handleSignUp}>
     {buttonText}
    </button>
  );
};