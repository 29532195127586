import React, { useState, useEffect } from "react";
import axios from "axios";
import {Tab,TabGroup,TabList,TabPanel,TabPanels,Field,Fieldset,Input,Label,Select,Button,Description,Legend,} from "@headlessui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import { EventSearch } from "../components/event-search";
import { EventSelectSearch } from "../components/event-select-search";
import { DatePicker } from "@nextui-org/react";
import {parseAbsoluteToLocal} from "@internationalized/date";
import styles from "../styles/pages/sale-form-test.module.css";
import ListingConfirmationModal from "../components/modals/listingConfirmationModal";
import { useProfanityFilter } from "../context/ProfanityFilterContext";
import schoolNames from "../config/schoolNames";

const MAX_SECTION_CHAR_LENGTH = 50;
const MAX_TICKET_PRICE = 1000;
const MAX_TICKET_NUM = 10;


const ListingForm = () => {
  const REACT_APP_API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";
  const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL || 'http://localhost:3000';


  const { user } = useAuth0();
  const [isSellForm, setIsSellForm] = useState(true);
  const [formData, setFormData] = useState({
    event_name: "",
    num_tickets: "",
    price: "",
    date: "",
    venue: "",
    level: "GA",
    terms: false,
    section: "",
  });
  
  const [swapData, setSwapData] = useState({
    event_name: "",
    num_tickets: "",
    preferred_tickets: [],
    venue: "",
    level: "GA",
    terms: false,
    expiry: "", 
  });
  const [events, setEvents] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [resetSearch, setResetSearch] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [eventUrl, setEventUrl] = useState(""); 
  const cleanText = useProfanityFilter();
  const [region, setRegion] = useState("bay_area"); // Default region

  const getRegionFromEmail = (email) => {
    if (!email) return "bay_area";
    const schoolDomain = email.split('@')[1]?.split('.')[0];
    const schoolInfo = schoolNames[schoolDomain];
    return schoolInfo?.region || "bay_area";
  };

  useEffect(() => {
    const initialRegion = getRegionFromEmail(user?.email);
    setRegion(initialRegion);
  }, []); 

useEffect(() => {
  const fetchEventNames = async () => {
    try {
      const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/events`, {
        params: { region }
      });      
      const currentDateTime = new Date();

      // Not passed events
      const fetchedEvents = response.data
        .filter(event => new Date(event.dateTime) >= currentDateTime);

        setEvents(fetchedEvents);
    } catch (error) {
      console.error("Failed to fetch events data:", error);
    }
  };

  fetchEventNames();
}, [REACT_APP_API_BASE_URL]);


  const handleChange = (event) => {
    if (!event || !event.target) {
      return;
    }
  
    const { name, value, type, checked } = event.target;
    console.log(`Field changed: ${name}, Value: ${value}`); // Debugging line
  
    setError("");

    if (type === "checkbox") {
      setFormData(prevData => ({ ...prevData, terms: checked }));
    } else if (name === "section") {
      if (value.length <= MAX_SECTION_CHAR_LENGTH) {
        handleMessageChange(value); // filter and update message state
      }
    } else if (name === "price") {
      // @shiv max price rn is techically 999
      if (value.length <= 3) {
        setFormData({ ...formData, [name]: value });
      }
    } else if (name === "num_tickets") {
      if (value.length <= 2) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData(prevData => ({ ...prevData, [name]: value }));
    }
  };
  
  const handleSwapChange = (event) => {
    if (!event || !event.target) {
      return;
    }
  
    const { name, value, type, checked } = event.target;
    console.log(`Field changed: ${name}, Value: ${value}`); // Debugging line

    setError("");

  // Consolidate state updates
  setSwapData((prevData) => {
    const updatedData = { ...prevData };
    if (type === "checkbox") {
      updatedData.terms = checked;
    } else if ( name === "level" || name === "expiry") {
      updatedData[name] = value;
    }  if (name === "num_tickets") {
      if (value.length <= 2) {
        updatedData.num_tickets = value;
      }
    }else if (name === "preferred_tickets") {
      updatedData.preferred_tickets = prevData.preferred_tickets.includes(value)
        ? prevData.preferred_tickets.filter(ticket => ticket !== value)
        : [...prevData.preferred_tickets, value];
    }

    return updatedData;
  });
};
  
const handleMessageChange = (text) => {
  const cleanedText = cleanText(text);
  setMessage(cleanedText);
  setFormData((prevData) => ({ ...prevData, section: cleanedText })); // Update section in formData
};

useEffect(() => {
  // Reset errors when switching tabs
  setError("");
  resetForm();
  // should i include  handleChange, handleSwapChange in the dependency []
}, [isSellForm]);


  const handleEventSelect = (selectedEvent) => {
    console.log("selectedEvent:", selectedEvent);
    if (!selectedEvent) return;

  // WORKS !!
  const dateTime = new Date(selectedEvent.dateTime);
  const formattedDate = dateTime.toISOString(); // Converts to format needed by datepicker

  // Set event URL to indp event pg 
  const url = `${REACT_APP_FRONTEND_BASE_URL}/events/${encodeURIComponent(selectedEvent.eventName)}`;
  setEventUrl(url); 

    if (isSellForm) {
      setFormData((prevData) => {
        const updatedData = {
          ...prevData,
          event_name: selectedEvent.eventName,
          date: formattedDate,
          venue: selectedEvent.venue,
          region: selectedEvent.region,
        };
        console.log("Sell Form Data updated:", updatedData);
        return updatedData; // Return the updated state
      });
    } else {
      setSwapData((prevData) => {
        const updatedData = {
          ...prevData,
          event_name: selectedEvent.eventName,
          expiry: formattedDate,
          venue: selectedEvent.venue,
          region: selectedEvent.region,
        };
        console.log("Swap Form Data updated:", updatedData);
        return updatedData; // Return the updated state
      });
    }
  };  
  
  const handlePreferredTicketsSelect = (selectedTickets) => {
    console.log("selectedTickets:", selectedTickets); // Debugging
    if (!isSellForm) {
      setSwapData((prevData) => ({
        ...prevData,
        preferred_tickets: selectedTickets || [], // Update preferred tickets
      }));
      console.log("Updated preferred_tickets:", selectedTickets); // Verify
    }
  };
  
  const handleDateChange = (newDate) => {
    if (!newDate) return;
    console.log("newDate", newDate);

    // Assuming newDate is an instance of the custom date class
    const { year, month, day, hour, minute, second, millisecond } = newDate;

    // Create a new JavaScript Date object in local time
    const localDate = new Date(year, month - 1, day, hour, minute, second, millisecond);

    // Convert to ISO format
    const isoDate = localDate.toISOString(); // Directly convert to ISO format

    setSwapData(prevData => ({
        ...prevData,
        expiry: isoDate,
      }));
  };

const convertToUTC = (customDate) => {
  // Check if the date is already in UTC format
  if (typeof customDate === 'string' && customDate.endsWith('Z')) {
    // Try parsing the string to ensure it's a valid date
    const parsedDate = new Date(customDate);
    if (!isNaN(parsedDate.getTime())) {
      // Return the date as is if it's a valid UTC date
      return customDate;
    } else {
      console.error("Invalid date provided:", customDate);
      return null; // Return null if the date is invalid
    }
  }

    const { year, month, day, hour, minute, second, millisecond } = customDate;
    // Create a new JavaScript Date object in local time
    const localDate = new Date(year, month - 1, day, hour, minute, second, millisecond);
    // Convert local time to UTC
    const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
    // Check for validity
    if (isNaN(utcDate.getTime())) {
        console.error("Invalid date provided:", customDate);
        return null; 
    }

    return utcDate.toISOString(); 
};

const resetForm = () => {
  if (isSellForm) {
      setFormData({
         // @shiv event name ISNT CLEARING after submit either... 
          event_name: "",
          num_tickets: "",
          price: "",
          date: "",
          venue: "",
          level: "GA",
          terms: false,
          section: "",
      });
  } else {
      setSwapData({
        // @shiv event name + selected artists in preferred tix aren't CLEARING AFTER SUBMIT??? 
          event_name: "",
          num_tickets: "",
          preferred_tickets: [],
          venue: "",
          level: "GA",
          terms: false,
          expiry: "",
      });
  }

    setResetSearch(true);
    setTimeout(() => setResetSearch(false), 100); // Reset to false w delay
};
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("isSellForm", isSellForm, "Form Data:", isSellForm ? formData : swapData);

      // @shiv add check for max num of tix?
  if (isSellForm) {
    // Check that the ticket type is not empty or N/A
    if (!formData.event_name || !formData.num_tickets || !formData.price || !formData.level || !formData.terms) {
      setError('Please fill out all required fields, select a ticket type, and accept the terms.');
      return;
    }
    if (formData?.price <1 || formData?.price>=MAX_TICKET_PRICE) {
      setError("Price must be between $1 and $1,000.");
      return;
    }

    if (formData?.num_tickets <1 || formData?.num_tickets>=MAX_TICKET_NUM) {
      setError("Cannot list more than 10 tix for the same event.");
      return;
    }
  
    if (formData.section.length > MAX_SECTION_CHAR_LENGTH) {
      setError(`Section info exceeds limit.`);
      return;
    }

    if (message.includes("***")) {
      setError("Please avoid using inappropriate language.");
      return;
    }
  }

    if (!isSellForm) {
      if (!swapData.event_name || !swapData.num_tickets || !swapData.level || !swapData.terms) {
        setError('Please fill out all required fields, select a ticket type, and accept the terms.');
        return;
      }

      if (swapData?.num_tickets <1 || swapData?.num_tickets>=MAX_TICKET_NUM) {
        setError("Cannot list more than 10 tix for the same event.");
        return;
      }

      if (swapData.preferred_tickets.length === 0) {
        setError('Please select preferred concert tickets for trade.');
        return;
      }
    }

    // Convert the swap expiry date to UTC if not in sell form
    let expiryDate;
    if (!isSellForm && swapData.expiry) {
        expiryDate = convertToUTC(swapData.expiry);
        if (!expiryDate) {
            setError('Invalid expiry date.');
            return;
        }
    }

    const formJson = isSellForm
      ? {
          ...formData,
          email: user?.email || "",
          instagram_handle: user?.instagram_handle || "",
          seller_id: user.sub || "",
        }
      : {
        ...swapData,
        expiry: expiryDate, // Add the UTC expiry date here
        swapper_id: user?.sub || "",
        email: user?.email || "",
        instagram_handle: user?.instagram_handle || "",
    };

    const endpoint = isSellForm
      ? `${REACT_APP_API_BASE_URL}/api/listings/new`
      : `${REACT_APP_API_BASE_URL}/api/swaps/new`;

    try {
      const response = await axios.post(endpoint, formJson);
      if (response.status === 201) {
        setMessage("Submission successful");
        setIsModalOpen(true); 
        // visually shown when data clears tho
        console.log("submitted successfully to db");
        resetForm();
      } else {
        setMessage("Submission failed");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage(`Error: ${error.response?.data?.error || error.message}`);
    }
  };

  const regions = [
    { value: "bay_area", label: "Bay Area" },
    { value: "los_angeles", label: "Los Angeles" },
    { value: "dfw", label: "Dallas/Fort Worth" }
  ];

  const currentRegionLabel = regions.find((reg) => reg.value === region)?.label || "Select Region";

  const formatPrettyDate = (dateString) => {
    const date = new Date(dateString);
  
    if (isNaN(date)) return null;
  
    const options = {
      month: 'short',
      day: 'numeric', 
      year: 'numeric', 
      hour: 'numeric',
      minute: 'numeric', 
      hour12: true,
    };
      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  
    const formattedParts = formattedDate.split(' ');
    return `${formattedParts[0]}. ${formattedParts[1]} ${formattedParts[2]} @ ${formattedParts[3].toLowerCase()}${formattedParts[4]}`;
  };
  
  return (
    <PageLayout>
      <div className={styles.formbox}>
        {/* Render the modal conditionally */}
        {isModalOpen && (
          <ListingConfirmationModal 
            eventUrl={eventUrl}
            closeModal={() => setIsModalOpen(false)}
          />
        )}
        <TabGroup>
          <TabList className={styles.tabList}>
          <Tab
              className={({ selected }) => selected ? styles.selectedTab : styles.tab}
              onClick={() => setIsSellForm(true)}
            >
              Sell
            </Tab>
            <Tab
              className={({ selected }) => selected ? styles.selectedTab : styles.tab}
              onClick={() => setIsSellForm(false)}
            >
              Swap
            </Tab>
          </TabList>
          <TabPanels className={styles.tabPanels}>
            {/*SALES FORM*/}
            <TabPanel>
            <form className={styles.fieldset} onSubmit={handleSubmit}>
              <Fieldset>
              <Legend className={styles.legend}>Sell Your Tickets</Legend>
                <Field className={styles.field}>
                  <Label className={styles.required}>Choose A {currentRegionLabel} Event</Label>
                  <EventSearch events={events} onChange={handleEventSelect} reset={resetSearch} />
                  <p>
                  {formData.event_name && formData.venue && formData.date && (
                    <span>
                      <strong>Venue:</strong> {formData.venue} <br />
                      <strong>Date:</strong> {formatPrettyDate(formData.date)} 
                    </span>
                  )}
                  </p>
                </Field>
                <Field className={styles.field}>
                  <Label className={styles.required}>Number of Tickets</Label>
                  <Input
                    type="number"
                    name="num_tickets"
                    value={formData.num_tickets}
                    onChange={handleChange}
                    required
                    className={styles.input}
                    min={1}
                    max={MAX_TICKET_NUM}
                  />
                </Field>
                <Field className={styles.field}>
                  <Label className={styles.required}>Total Price</Label>
                  <Input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                    required
                    className={styles.input}
                    min={1}
                    max={MAX_TICKET_PRICE}
                  />
                </Field>
                {formData.price && formData.num_tickets && (
                  <p className={styles.ppt}>
                    Price per tix: ${formData.price/formData.num_tickets}
                  </p>
                )}
                <Field className={styles.field}>
                  <Label className={styles.required}>Ticket Type</Label>
                  <div className={styles.inputContainer}>
                  <Select
                    name="level" 
                    className={styles.select}
                    value={formData.level}
                    onChange={handleChange} 
                  >
                    <option value="GA">GA</option>
                    <option value="VIP">VIP</option>
                  </Select>

                  {/* Dropdown indicator */}
                  <div className={styles.dropdownIndicator} />
                </div>
                </Field>
                <Field className={styles.field}>
                  <Label className={styles.label}>Section</Label>
                  <Input
                    type="text"
                    name="section"
                    placeholder="Details About Section if Applicable"
                    value={formData.section}
                    onChange={handleChange}
                    className={styles.input}
                  />
                </Field>
                  <label>
                  <div className={styles.checkboxWrapper}>
                    <Input
                      className={styles.checkbox}
                      type="checkbox"
                      name="terms"
                      checked={formData.terms}
                      onChange={handleChange}
                      required
                    />
                    <p className={styles.termsLabel}>
                      I agree to the Terms & Conditions
                    </p>
                  </div>
                  </label>
                {error && <p className={styles.errorMessage}>{error}</p>}
                <Button type="submit" className={styles.submitButton}>
                  Submit
                </Button>
                </Fieldset>
              </form>
              
            </TabPanel>

            {/*SWAP FORM*/}
            <TabPanel>
            <form className={styles.fieldset} onSubmit={handleSubmit}>
              <Fieldset>
                <Legend className={styles.legend}>Swap Your Ticket</Legend>
                <Field className={styles.field}>
                <Label className={styles.required}>Choose A {currentRegionLabel} Event</Label>
                  <EventSearch events={events} onChange={handleEventSelect} reset={resetSearch}/>
                  <p>
                  {formData.event_name && formData.venue && formData.date && (
                    <span>
                      <strong>Venue:</strong> {formData.venue} <br />
                      <strong>Date:</strong> {formatPrettyDate(formData.date)} 
                    </span>
                  )}
                  </p>
                </Field>
                <Field className={styles.field}>
                  <Label className={styles.required}>Number of Tickets</Label>
                  <Input
                    type="number"
                    name="num_tickets"
                    value={swapData.num_tickets}
                    onChange={handleSwapChange}
                    required
                    className={styles.input}
                    min={1}
                    max={MAX_TICKET_NUM}
                  />
                </Field>
                <Field className={styles.field}>
                  <Label className={styles.required}>Preferences</Label>
                  {/* <Description className={styles.description}>
                    Enter up to 5 Preferred Events for Trade
                  </Description> */}
                  <EventSelectSearch events={events} onChange={handlePreferredTicketsSelect} reset={resetSearch}/>
                </Field>
                <Field className={styles.field}>
                  <Label className={styles.required}>Ticket Type</Label>
                  <Select
                    name="level" 
                    className={styles.select}
                    value={swapData.level}
                    onChange={handleSwapChange} 
                  >
                    <option value="GA">GA</option>
                    <option value="VIP">VIP</option>
                  </Select>
                </Field>
                <Field className={styles.field}>
                  <Label className={styles.required}>Listing Expiration</Label>
                  <div>
                  <DatePicker
                      aria-label="expiry date"
                      granularity="minute"
                      color="default"
                      radius="sm"
                      value={swapData.expiry ? parseAbsoluteToLocal(swapData.expiry) : null}  // Parse date correctly
                      onChange={handleDateChange}
                      className={styles.customDatepicker}
                      // min={new Date().toISOString()}
                    />
                  </div>
                </Field>
                  <label>
                    <div className={styles.checkboxWrapper}>
                    <Input
                      className={styles.checkbox}
                      type="checkbox"
                      name="terms"
                      checked={swapData.terms}
                      onChange={handleSwapChange}
                      required
                    />
                      <p className={styles.termsLabel}>
                        I agree to the Terms & Conditions
                      </p>
                    </div>
                  </label>
                {error && <p className={styles.errorMessage}>{error}</p>}
                <Button type="submit" className={styles.submitButton}>
                  Submit
                </Button>
                </Fieldset>
              </form>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </PageLayout>
  );
};

export { ListingForm };