import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import styles from '../../styles/components/editListingModal.module.css';
import { DatePicker } from "@nextui-org/react";
import {parseAbsoluteToLocal} from "@internationalized/date";
import { EventSearch } from "../event-search";
import { EventSelectSearch } from "../event-select-search";


const EditSwapModal = ({ isOpen, closeModal, swap, updateSwap }) => {
  const { user } = useAuth0();
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';


  const [formData, setFormData] = useState({
    event_name: "",
    num_tickets: 0,
    preferred_tickets: [],
    expiry: '',
    venue: '',
    level: 'GA', 
  });

  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEventNames = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/events`);
        const currentDateTime = new Date();
  
        // Not passed events
        const fetchedEvents = response.data
          .filter(event => new Date(event.dateTime) >= currentDateTime);
  
          setEvents(fetchedEvents);
      } catch (error) {
        console.error("Failed to fetch events data:", error);
      }
    };
  
    fetchEventNames();
  }, [REACT_APP_API_BASE_URL]);

  useEffect(() => {
    if (swap) {
      setFormData({
        event_name: swap.event_name || '',
        num_tickets: swap.num_tickets || 0,
        preferred_tickets: swap.preferred_tickets || [],
        expiry: swap.expiry || '',
        venue: swap.venue || '',
        level: swap.level || 'GA',
      });
    }
  }, [swap]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEventSelect = (selectedEvent) => {
    console.log("selectedEvent:", selectedEvent);
    if (!selectedEvent) return;

  // WORKS !!
  const dateTime = new Date(selectedEvent.dateTime);
  const formattedDate = dateTime.toISOString(); // Converts to format needed by datepicker

    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        event_name: selectedEvent.eventName,
        expiry: formattedDate,
        venue: selectedEvent.venue,
      };
      console.log("Swap Form Data updated:", updatedData);
      return updatedData; // Return the updated state
    });
  };

  const handlePreferredTicketsSelect = (selectedTickets) => {
    console.log("selectedTickets:", selectedTickets); 
      setFormData((prevData) => ({
        ...prevData,
        preferred_tickets: selectedTickets || [],
      }));
      console.log("Updated preferred_tickets:", selectedTickets);
  };

  const handleDateChange = (newDate) => {
    if (!newDate) return;
    console.log("newDate", newDate);

    // Assuming newDate is an instance of the custom date class
    const { year, month, day, hour, minute, second, millisecond } = newDate;

    // Create a new JavaScript Date object in local time
    const localDate = new Date(year, month - 1, day, hour, minute, second, millisecond);

    // Convert to ISO format
    const isoDate = localDate.toISOString(); // Directly convert to ISO format

    setFormData(prevData => ({
        ...prevData,
        expiry: isoDate,
      }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedSwap = {
      ...formData,
      // Keep from original swap
      swapper_id: swap.swapper_id,
      email: swap.email, 
      instagram_handle: swap.instagram_handle,
    };

    try {
      const response = await axios.patch(`${REACT_APP_API_BASE_URL}/api/swaps/${swap._id}`, updatedSwap);
      console.log("updatedSwap:", updatedSwap);

      if (response.status === 200) {
        updateSwap(swap._id, updatedSwap);
        alert('Swap updated successfully!');
        closeModal();
      } else {
        alert('Failed to update swap. Please try again.');
      }
    } catch (error) {
      console.error('Error updating swap:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    isOpen && (
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <h2>Edit Swap</h2>
          <form onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="event_name">Event Name</label>
              {/* <input
                type="text"
                id="event_name"
                name="event_name"
                value={formData.event_name}
                onChange={handleChange}
                list="event_name_options"
                required
              />
              <datalist id="event_name_options">
                {eventNames.map((event, index) => (
                  <option key={index} value={event.label} />
                ))}
              </datalist> */}
              <EventSearch events={events} onChange={handleEventSelect} />
              <p className={styles.selectedEvent}>Selected: {formData.event_name}</p>
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="num_tickets">Number of Tickets</label>
              <input
                type="number"
                id="num_tickets"
                name="num_tickets"
                value={formData.num_tickets}
                onChange={handleChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="preferred_tickets">Preferences</label>
              {/* <p className={styles.description}>
                  Enter up to 5 Preferred Events for Trade
              </p> */}
              <EventSelectSearch events={events} onChange={handlePreferredTicketsSelect}/>
              <p className={styles.selectedEvent}>Selected: {formData.preferred_tickets.join(', ')}</p>
            </div>
            {/* <div className={styles.formGroup}>
              <label htmlFor="instagram_handle">Instagram Handle</label>
              <input
                type="text"
                id="instagram_handle"
                name="instagram_handle"
                value={formData.instagram_handle}
                onChange={handleChange}
                list="instagram_handle_options"
              />
              <datalist id="instagram_handle_options">
                <option value={user?.instagram_handle}>{user?.instagram_handle}</option>
              </datalist>
            </div> */}
            <div className={styles.formGroup}>
              <label htmlFor="date">Date</label>
              {/* <input
                type="date"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                className={styles.input}
                required
              /> */}
                <DatePicker
                aria-label="expiry date"
                granularity="minute"
                color="default"
                radius="sm"
                value={formData.expiry ? parseAbsoluteToLocal(formData.expiry) : null}  // Parse date correctly
                onChange={handleDateChange}
                className={styles.customDatepicker}
                // min={new Date().toISOString()}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="venue">Venue</label>
              <input
                type="text"
                id="venue"
                name="venue"
                value={formData.venue}
                onChange={handleChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="state">Status</label>
              <select
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                className={styles.select}
                required
              >
                <option value="Available">Available</option>
                <option value="Expired">Expired</option>
              </select>
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="level">Level</label>
              <select
                id="level"
                name="level"
                value={formData.level}
                onChange={handleChange}
                className={styles.select}
                required
              >
                <option value="GA">GA</option>
                <option value="VIP">VIP</option>
              </select>
            </div>
            {/* Hidden fields */}
            <input type="hidden" name="swapper_id" value={swap.swapper_id} />
            <input type="hidden" name="email" value={swap.email} />
            <input type="hidden" name="instagram_handle" value={swap.instagram_handle} />
            <div className={styles.modalButtons}> 
              <button type="submit">Update Swap</button>
              <button type="button" onClick={closeModal}>Cancel</button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default EditSwapModal;
