import React, { useState, useEffect }  from 'react';
import axios from 'axios'; 
import styles from "../../styles/components/swap-listing.module.css";
import { ReactComponent as SwapIcon} from '../../styles/img/swapicon.svg';
import { useAuth0 } from '@auth0/auth0-react';
import SaveIconButton from '../buttons/saveIconButton';
import { LoginButton } from "../buttons/login-button";

// formatDate function defined directly within the same file for simplicity
function formatDate(date) {
  const options = { month: 'short', day: 'numeric' };
  return new Date(date).toLocaleDateString('en-US', options);
}

export const SwapCard = ({ swap, handleConnect })  => {
  const { user } = useAuth0();
  const [isSaved, setIsSaved] = useState(false); 
  const type = 'swap';
  const preferredTickets = swap.preferred_tickets ? swap.preferred_tickets.join(", ") : "";
  const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL || 'http://localhost:3000';
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';
  const dynamicRedirect = `/tickets/${swap._id}`;

  const handleSeeTixPg = () => {
    const ticketLink = `${REACT_APP_FRONTEND_BASE_URL}/tickets/${swap._id}`;
    window.open(ticketLink, '_blank'); 
  };

  useEffect(() => {
    const checkIfSaved = async () => {
      if (user) {
        try {
        const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/saved/user`, {
          params: { userId: user.sub },
        });
        
        const savedlist = response.data;

        if (savedlist.length > 0) {
          const userSavedlist = savedlist[0]; 
          const savedSwaps = userSavedlist.swaps || []; 
          
          const isSwapSaved = savedSwaps.includes(swap._id);
          setIsSaved(isSwapSaved);

        }
      } catch (error) {
          console.error('Error fetching saved listings:', error);
        }
      }
    };

    checkIfSaved();
  }, [user, swap._id, REACT_APP_API_BASE_URL]);

  const handleSaveNew = async (swapId) => {
    if (!user) {
      const currentPage = window.location.pathname + window.location.search;
      window.location.href = `${REACT_APP_FRONTEND_BASE_URL}/login?returnTo=${encodeURIComponent(currentPage)}`;
      return;
    }

    try {
      const payload = {
        userId: user.sub,
        userIg: user.instagram_handle || '', 
        ticketId: swapId,
        type: type       // "listing" or "swap"
      };
  
      const response = await axios.post(`${REACT_APP_API_BASE_URL}/api/saved/new`, payload);
  
      if (response.status === 201) {
        console.log('Saved ticket:', response.data);
        alert('Ticket saved successfully!');
        setIsSaved(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        alert(error.response.data.message); 
      } else {
        console.error('Error saving ticket:', error);
        alert('An error occurred while saving the ticket.');
      }
    }
  };

  const handleDeleteSaved = async (swapId) => {
    try {
      const payload = {
        userId: user.sub,
        type: type
      };

      await axios.delete(`${REACT_APP_API_BASE_URL}/api/saved/${swapId}`, {
        data: payload,
      });

      setIsSaved(false);
    } catch (error) {
      console.error("Failed to unsave swap:", error);
      alert('An error occurred while unsaving the swap.');
    }
  };

  return (
    <div key={swap._id} onClick={handleSeeTixPg} className={styles.eventCard} style={{ cursor: 'pointer' }}>
      <div className={styles.event_info} onClick={handleSeeTixPg}  style={{ cursor: 'pointer' }}>
        <h3>{swap.event_name}</h3>
        <p>
          <span>{formatDate(swap.expiry)} |</span>
          <span> {swap.venue}</span>
        </p>
      </div>
      {swap.swapper_id !== user?.sub && (
        <div className={styles.saveButton} onClick={(e) => { 
          e.stopPropagation(); 
          isSaved ? handleDeleteSaved(swap._id) : handleSaveNew(swap._id); 
        }} style={{ cursor: 'pointer' }}>
          <SaveIconButton fill={isSaved ? "url(#paint0_linear_1062_19)" : "url(#blackGradient)"} />
        </div>
      )}
      <div className={styles.ticket_info} onClick={handleSeeTixPg}  style={{ cursor: 'pointer' }}>
        <h4>{swap.num_tickets} {swap.level} {swap.num_tickets === 1 ? "Ticket" : "Tickets"}</h4>
          <div className={styles.swapIcon}>
          <SwapIcon/>
          </div>
        </div>
      {(user && swap.swapper_id !== user.sub) && (
      <div className={styles.chatButton}>
        <button onClick={(e) => e.stopPropagation() || handleConnect(swap)}>Connect</button>
      </div>
      )}
      {(!user) && (
        <div className={styles.chatButton}>
          <LoginButton
          redirectTo={dynamicRedirect}
          buttonText="Connect"
        />
      </div>
      )}
    </div>
  );
};

