// EventCountdown.js
import React, { useState, useEffect } from "react";
import styles from "../../styles/pages/artist-search-page.module.css"; // Update with correct path

// Helper function to calculate the time difference in hours and days
const calculateTimeDifferenceInHours = (eventDateTime) => {
  const now = Date.now(); // Get the current time in milliseconds
  const eventDate = Date.parse(eventDateTime); // Parse the dateTime string to get milliseconds

  // Handle invalid date parsing
  if (isNaN(eventDate)) {
    console.error("Invalid date format:", eventDateTime);
    return { diffInHours: NaN, diffInDays: NaN };
  }

  const diffInMilliseconds = eventDate - now;
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInMinutes / (60 * 24));

  return { diffInHours, diffInDays };
};

// Function to determine the label based on the time difference
const getTimeLabel = (timeDifference) => {
  const { diffInHours, diffInDays } = timeDifference;

  if (isNaN(diffInHours)) {
    return <div>Error: Invalid date</div>; // Handle invalid date
  }

  if (diffInHours < 0) {
    return <div>Event has passed</div>; // Handle event passed
  }

  if (diffInHours < 6) {
    return <div className={styles.timeLabelPurple}>{diffInHours} hrs left</div>;
  } else if (diffInHours < 12) {
    return <div className={styles.timeLabelRed}>{diffInHours} hrs left</div>;
  } else if (diffInHours < 24) {
    return <div className={styles.timeLabelYellow}>{"<"} 24 hrs left</div>;
  } else if (diffInDays <= 1) {
    return <div className={styles.timeLabelBlue}>{"<"} 1 day left</div>;
  // } else if (diffInDays <= 3) {
  //   return <div className={styles.timeLabelBlue}>{"<"} 3 days left</div>;
  } else if (diffInDays <= 7) {
    return <div className={styles.timeLabelGreen}>{"<"} 1 week left</div>;
  } else if (diffInDays <= 14) { // Up to 2 weeks away
    // need diff time label color? dark grey?
    return <div className={styles.timeLabelGreen}>{"<"} 2 weeks left</div>;
  } else if (diffInDays <= 30) { // Up to 1 month away
    return <div className={styles.timeLabelGrey}>{"<"} 1 month left</div>;
  } else {
    return <div>More than 1 month away</div>; // More than 1 month away
  }
};

export const EventCountdown = ({ dateTime }) => {
  const [timeDifference, setTimeDifference] = useState(null);

  useEffect(() => {
    const timeDiff = calculateTimeDifferenceInHours(dateTime);
    setTimeDifference(timeDiff);
  }, [dateTime]);

  return timeDifference ? getTimeLabel(timeDifference) : <p>Loading...</p>;
};
