const schoolNames = {
  berkeley: { name: "Berkeley 🐻", region: "bay_area" },
  stanford: { name: "Stanford 🌲", region: "bay_area" },
  usfca: { name: "USF 🌉", region: "bay_area" },
  sfsu: { name: "SFSU 💜💛", region: "bay_area" },
  sjsu: { name: "SJSU 💛💙", region: "bay_area" },
  ucla: { name: "UCLA 🧸", region: "los_angeles" },
  usc: { name: "USC ✌️", region: "los_angeles" },
  lmu: { name: "LMU 🦁", region: "los_angeles" },
  pepperdine: { name: "Pepperdine 🌊", region: "los_angeles" },
  calstatela: { name: "Cal State LA 🖤💛", region: "los_angeles" },
  csulb: { name: "CSULB 🦈", region: "los_angeles" },
  smu: { name: "SMU 🐎", region: "dfw" },
  uta: { name: "UT Arlington 💙🧡", region: "dfw" },
  utdallas: { name: "UTD ☄️", region: "dfw" },
  tcu: { name: "TCU 💜🤍", region: "dfw" },
  dbu: { name: "DBU 💙❤️", region: "dfw" },
  baylor: { name: "Baylor 💛💚", region: "dfw" },
  unt: { name: "UNT 🦅", region: "dfw" },
  campusticket: {
    name: "Campus Ticket 🎟️",
    region: "bay_area, los_angeles, dfw",
  },
};

export default schoolNames;
