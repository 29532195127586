import React from 'react';
import styles from '../../styles/components/private-lineup.module.css'; // Adjust the path

export const PublicLineupCard = ({ 
    concert,
  }) => {

    return (
        <div key={concert._id} className={styles.eventCard}>
        <h3>{concert?.concertName}</h3>
      </div>
  )};
