import React from "react";
import { PageLayout } from "../components/page-layout";
import styles from "../styles/pages/email-verify-page.module.css";

export const EmailVerify = () => {
  return (
    <PageLayout>
    <div className={styles.verify_page}>
      <h1>Welcome to CampusTicket!!</h1>
      <br/>
      <p>A verification email has been sent to your inbox, please verify your email to continue!</p>
    </div>
    </PageLayout>
  );
};
