import React from "react";
import styles from "../../styles/components/start-txt.module.css";

export default function SupportPg() {
  const email = "admin@campus-ticket.com";

  return (
    <div>
      <h2 className={styles.lefttitle}>Customer Service</h2>
      <div className={styles.supportintro}>
        <p>
        At CampusTicket, we’re here to enhance your event experience and support you at every stage. 
        For any questions, concerns, or support with our platform, 
        <a href="/start" className={styles.aLink}> visit our start page and FAQ section </a> 
        for quick answers or reach out to us anytime.
        </p>
        <p>
        For assistance inquiries, contact our team at:
        </p>
        <p>
        Email: <a href={`mailto:${email}`} className={styles.aLink}>{email}</a>
        </p>
        <p>
        Our customer support team is dedicated to responding within 48 hours to assist you as promptly as possible.
        </p>
      </div>
      <div className={styles.spacer}></div>
    </div>
  );
}
