import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";

const AutoSignup = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const returnTo = urlParams.get('returnTo'); 

    const redirectTo = returnTo || '/login';


    loginWithRedirect({
      prompt: "signup",
      appState: {
        returnTo: redirectTo,
      },
    });
  }, [loginWithRedirect]);

  return null;
};

export default AutoSignup;
