import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import ReportModal from "../components/modals/reportModal";
import styles from "../styles/pages/listing-page.module.css";
import { PageLayout } from "../components/page-layout";
import DropdownButton from "../components/buttons/dropdown-button";
import SearchFilterButton from "../components/search-filter-button";
import { SwapCard } from "../components/swap-listings/swap-listing";
import { SaleCard } from "../components/sale-listings/sale-listing";
import schoolNames from "../config/schoolNames";
import NewMsgModal from "../components/modals/newMsgModal";

const ListingsPageTest = () => {
  const REACT_APP_API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";

  const { user } = useAuth0();
  const [listings, setListings] = useState([]);
  const [swaps, setSwaps] = useState([]);
  const [showReportModal, setShowReportModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [showListings, setShowListings] = useState(true);
  const [activeButton, setActiveButton] = useState("listings");
  const maxPrice = 2000;
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
    priceRange: [0, maxPrice], // Default value
    tixType: "",
    availability: "",
  });
  const [sortedListings, setSortedListings] = useState([]);
  const [sortedSwaps, setSortedSwaps] = useState([]);
  const [order, setOrder] = useState("");
  const [isNewMsgModalOpen, setIsNewMsgModalOpen] = useState(false);
  const [formattedSchoolName, setFormattedSchoolName] = useState("");
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [error, setError] = useState(null);
  const [schoolDomain, setSchoolDomain] = useState("");
  const [schoolNameKey, setSchoolNameKey] = useState("");
    // const [schoolListings, setSchoolListings] = useState([]);
  // const [outsideSchoolListings, setOutsideSchoolListings] = useState([]);
  // const [schoolSwaps, setSchoolSwaps] = useState([]);
  // const [outsideSchoolSwaps, setOutsideSchoolSwaps] = useState([]);

  const fetchUserInfo = async () => {
    if (!user) return;
    
    try {
      // School domain filtering
      setSchoolDomain(user.email.split('@')[1]);
      setSchoolNameKey(schoolDomain.split('.')[0]);
      const schoolInfo = schoolNames[schoolNameKey] || { name: "Your Campus" };
      setFormattedSchoolName(schoolInfo.name);
      // Blocked users 
      const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/getUserMetadata/${user.sub}`);
      setBlockedUsers(response.data.metadata.blocked_users || []);
      console.log('blockedUsers:', response.data.metadata.blocked_users);
    } catch (error) {
      console.error('Error fetching blocked users:', error);
      setError('Failed to fetch blocked users.');
    }
  };

  const fetchListings = useCallback(async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/api/listings`
      );
      const fetchedListings = response.data;
      const expiredListingIds = fetchedListings
      .filter(
        (listing) =>
          new Date(listing.date) < Date.now() &&
          listing.state.toLowerCase() === "available" &&
          !listing.deleted
      )
      .map((listing) => listing._id);

    // Update expired listings
    if (expiredListingIds.length > 0) {
      try {
        const response = await axios.patch(
          `${REACT_APP_API_BASE_URL}/api/listings/batch/updateExpired`,
          { expiredIds: expiredListingIds }
        );
        console.log("Expired listings updated:", response.data);
      } catch (error) {
        console.error("Failed to update expired listings:", error);
      }
    }

      if (user) {
      // Filter out expired and from blocked but keep user's listings
      const validListings = fetchedListings
    .filter(listing => new Date(listing.date) > Date.now()); 

      setListings(validListings);

      // // Separate listings by school
      // const schoolFiltered = sortedListings.filter((listing) =>
      //   listing.email?.endsWith(`@${schoolDomain}`)
      // );
      // const outsideFiltered = sortedListings.filter(
      //   (listing) => !listing.email?.endsWith(`@${schoolDomain}`)
      // );

      // setSchoolListings(schoolFiltered);
      // setOutsideSchoolListings(outsideFiltered);
    }
    // If no user is logged in, ret all fetched listings
    if (!user) {
      setListings(fetchedListings);
    }
    } catch (error) {
      console.error("Error fetching listings:", error);
    }
  }, [REACT_APP_API_BASE_URL, user]);

  const fetchSwaps = useCallback(async () => {
    try {
      const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/swaps`);
      const fetchedSwaps = response.data;
      // Find expired swaps
      const expiredSwapIds = fetchedSwaps
        .filter(
          (swap) =>
            new Date(swap.expiry) < Date.now() &&
            swap.state.toLowerCase() === "available" &&
            !swap.deleted
        )
        .map((swap) => swap._id);

      // Update expired swaps
      if (expiredSwapIds.length > 0) {
        try {
          const response = await axios.patch(
            `${REACT_APP_API_BASE_URL}/api/swaps/batch/updateExpired`,
            { expiredIds: expiredSwapIds }
          );
          console.log("Expired swaps updated:", response.data);
        } catch (error) {
          console.error("Failed to update expired swaps:", error);
        }
      }

  if (user) {
    // Filter out expired and from blocked but keep user's listings
    const validSwaps = fetchedSwaps
    .filter(swap => new Date(swap.expiry) > Date.now() && !blockedUsers.includes(swap.swapper_id) );
  
    setSwaps(validSwaps);

    // // Separate listings by school
    // const schoolFilteredSwaps = sortedSwaps.filter((swap) =>
    //   swap.email?.endsWith(`@${schoolDomain}`)
    // );
    // const outsideFilteredSwaps = sortedSwaps.filter(
    //   (swap) => !swap.email?.endsWith(`@${schoolDomain}`)
    // );

    // setSchoolSwaps(schoolFilteredSwaps);
    // setOutsideSchoolSwaps(outsideFilteredSwaps);
  }

    // If no user is logged in, get all fetched swaps
    if (!user) {
      setSwaps(fetchedSwaps);
    }
      // console.log("Fetched swaps:", fetchedSwaps);
    } catch (error) {
      console.error("Error fetching swaps:", error);
    }
  }, [REACT_APP_API_BASE_URL, user]);


  const fetchUserData = async () => {
    await fetchUserInfo();
    fetchListings();
    fetchSwaps();
  };
  
  useEffect(() => {
    fetchUserData();
  }, []);

  const applyFiltersAndOrder = () => {
    let filteredListings = listings.filter(
      (listing) =>
        (filter.startDate
          ? new Date(listing.date) >= new Date(filter.startDate)
          : true) &&
        (filter.endDate
          ? new Date(listing.date) <= new Date(filter.endDate)
          : true) &&
        // filters the total price (@shiv make this price per tix not total price...)
        listing.price >= filter.priceRange[0] &&
        listing.price <= filter.priceRange[1] &&
        (filter.tixType
          ? listing.level.toLowerCase().includes(filter.tixType.toLowerCase())
          : true) &&
        (filter.availability
          ? listing.num_tickets >= Number(filter.availability)
          : true)
    );

    let filteredSwaps = swaps.filter(
      (swap) =>
        !swap.deleted &&
        swap.state.toLowerCase() === "available" &&
        (filter.startDate
          ? new Date(swap.expiry) >= new Date(filter.startDate)
          : true) &&
        (filter.endDate
          ? new Date(swap.expiry) <= new Date(filter.endDate)
          : true) &&
        (filter.tixType
          ? swap.level.toLowerCase().includes(filter.tixType.toLowerCase())
          : true) &&
        (filter.availability
          ? swap.num_spots >= Number(filter.availability)
          : true)
    );

    if (order) {
      console.log("Sorting by:", order);
      if (activeButton === "swaps") {
        if (order === "date") {
          filteredSwaps.sort((a, b) => new Date(a.expiry) - new Date(b.expiry));
        } else if (order === "dateDesc") {
          filteredSwaps.sort((a, b) => new Date(b.date) - new Date(a.date));
        }
      }
      if (activeButton === "listings") {
        if (order === "date") {
          filteredListings.sort((a, b) => new Date(a.date) - new Date(b.date));
        } else if (order === "dateDesc") {
          filteredListings.sort((a, b) => new Date(b.date) - new Date(a.date));
          // filters the total price (@shiv make this price per tix not total price...
        } else if (order === "priceAsc") {
          filteredListings.sort((a, b) => a.price - b.price);
        } else if (order === "priceDesc") {
          filteredListings.sort((a, b) => b.price - a.price);
        }
      }
    }

    if (activeButton === "listings") {
      setSortedListings(filteredListings);
    } else if (activeButton === "swaps") {
      setSortedSwaps(filteredSwaps);
    }
  };

  const handleFilter = (filterParams) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...filterParams,
    }));
  };

  useEffect(() => {
    applyFiltersAndOrder();
  }, [filter, order, listings, swaps]);

  const handleCloseModal = () => {
    setShowReportModal(false);
    setCurrentItem(null);
  };

  const handleCloseNewMsgModal = () => {
    setIsNewMsgModalOpen(false);
    setCurrentItem(null);
  };

  // const schoolDomain = user.email.split("@")[1];
  // const schoolNameKey = schoolDomain.split(".")[0];
  // const schoolInfo = schoolNames[schoolNameKey] || { name: "Your Campus" };
  // const formattedSchoolName = schoolInfo.name;

  const schoolListings = sortedListings.filter((listing) =>
    listing.email?.endsWith(`@${schoolDomain}`) && !blockedUsers.includes(listing.seller_id)
  );
  const outsideSchoolListings = sortedListings.filter(
    (listing) => !listing.email?.endsWith(`@${schoolDomain}`) && !blockedUsers.includes(listing.seller_id)
  );

  const schoolSwaps = sortedSwaps.filter((swap) =>
    swap.email?.endsWith(`@${schoolDomain}`)  && !blockedUsers.includes(swap.swapper_id)
  );
  const outsideSchoolSwaps = sortedSwaps.filter(
    (swap) => !swap.email?.endsWith(`@${schoolDomain}`) && !blockedUsers.includes(swap.swapper_id)
  );
  

  const resetFilters = () => {
    setFilter({
      startDate: "",
      endDate: "",
      priceRange: [0, maxPrice],
      tixType: "",
      availability: "",
    });
    setOrder("");
  };

  const handleConnectClick = (item) => {
    setCurrentItem(item);
    setIsNewMsgModalOpen(true);
  };

  function getSortByName(order) {
  const sortNames = {
    date: "Date (Upcoming)",
    dateDesc: "Date (Farthest)",
    priceAsc: "Price (Low to High)",
    priceDesc: "Price (High to Low)",
  };

  return sortNames[order] || "";
}

  const handlePageRefresh = () => {
      window.location.reload();
  };

  return (
    <PageLayout>
      <div className={`${styles.content__body} content-layout`}>
        <div className={styles.toggleButtons}>
          <button
            className={showListings ? styles["button-active"] : ""}
            onClick={() => {
              setShowListings(true);
              setActiveButton("listings");
              resetFilters();
            }}
          >
            Buy
          </button>
          <button
            className={!showListings ? styles["button-active"] : ""}
            onClick={() => {
              setShowListings(false);
              setActiveButton("swaps");
              resetFilters();
            }}
          >
            Swap
          </button>
          <SearchFilterButton
            className={styles.SearchFilterButton}
            onFilter={handleFilter}
            activeButton={activeButton}
          />
          <div className={styles.sortbyButton}>
            <DropdownButton label={`Sort ${order}`}>
              <div className={styles.dropdownContent}>
                {activeButton === "listings"
                  ? [
                      { label: "Event date (Earliest)", value: "date" },
                      { label: "Event date (Farthest)", value: "dateDesc" },
                      { label: "Price (Inc)", value: "priceAsc" },
                      { label: "Price (Dec)", value: "priceDesc" },
                    ].map((option) => (
                      <div
                        key={option.label}
                        onClick={() => setOrder(option.value)}
                      >
                        {option.label}
                      </div>
                    ))
                  : [
                      { label: "Expires (Soon)", value: "date" },
                      { label: "Expires (Farthest)", value: "dateDesc" },
                    ].map((option) => (
                      <div
                        key={option.label}
                        onClick={() => setOrder(option.value)}
                      >
                        {option.label}
                      </div>
                    ))}
              </div>
            </DropdownButton>
          </div>
        </div>
        {user ? (
          <>
        {showListings ? (
          <>
            {/* NO LISTINGS */}
            {(schoolListings.length === 0 && outsideSchoolListings.length === 0) && (
              <div className={styles.allListings}>
                {/* @clark feel free to modify this verbiage */}
                <h2>No listings available at this time 😔</h2>
              </div>
            )}
            {/* SCHOOL LISTINGS */}
            {schoolListings.length > 0 && (
              <div className={styles.schoolListings}>
                <h2>From {formattedSchoolName}:</h2>
                <div className={styles.gridContainer}>
                  {schoolListings.map((listing) => (
                    <SaleCard
                      key={listing._id}
                      listing={listing}
                      handleConnect={handleConnectClick}
                    />
                  ))}
                </div>
              </div>
            )}
            {/* OUTSIDE LISTINGS */}
            {outsideSchoolListings.length > 0 && (
              <div className={styles.allListings}>
                <h2>
                  {schoolListings.length === 0
                    ? `From outside your campus`
                    : "More Listings:"}
                </h2>
                <div className={styles.gridContainer}>
                  {outsideSchoolListings.map((listing) => (
                    <SaleCard
                      key={listing._id}
                      listing={listing}
                      handleConnect={handleConnectClick}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
          {/* NO SWAPS */}
          {(schoolSwaps.length === 0 && outsideSchoolSwaps.length === 0 ) && (
              <div className={styles.groupingRequests}>
                <h2>No swaps available at this time 😔</h2>
              </div>
            )}
            {/* SCHOOL SWAPS */}
            {schoolSwaps.length > 0 && (
              <div className={styles.groupingRequests}>
                <h2>From {formattedSchoolName}:</h2>
                <div className={styles.gridContainer}>
                  {schoolSwaps.map((swap) => (
                    <SwapCard
                      key={swap._id}
                      swap={swap}
                      handleConnect={handleConnectClick}
                    />
                  ))}
                </div>
              </div>
            )}
            {/* OUTSIDE SWAPS */}
            {outsideSchoolSwaps.length > 0 && (
              <div className={styles.groupingRequests}>
                <h2>
                  {schoolSwaps.length === 0
                    ? `From outside your campus`
                    : "More Ticketswaps:"}
                </h2>
                <div className={styles.gridContainer}>
                  {outsideSchoolSwaps.map((swap) => (
                    <SwapCard
                      key={swap._id}
                      swap={swap}
                      handleConnect={handleConnectClick}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
        </>
      ) : (
        // NO USER LOGGED IN
        <div>
        {showListings ? (
          <>
            {/* NO LISTINGS */}
            {(listings.length === 0) && (
              <div className={styles.allListings}>
                <h2>No listings available at this time 😔</h2>
              </div>
            )}
            {/* ALL LISTINGS */}
            {listings.length > 0 && (
              <div className={styles.schoolListings}>
                <h2>All listings:</h2>
                <div className={styles.gridContainer}>
                  {listings.map((listing) => (
                    <SaleCard
                      key={listing._id}
                      listing={listing}
                      handleConnect={handleConnectClick}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
          {/* NO SWAPS */}
          {(swaps.length === 0) && (
              <div className={styles.groupingRequests}>
                <h2>No swaps available at this time 😔</h2>
              </div>
            )}
            {/* ALL SWAPS */}
            {swaps.length > 0 && (
              <div className={styles.groupingRequests}>
                <h2>All swaps:</h2>
                <div className={styles.gridContainer}>
                  {swaps.map((swap) => (
                    <SwapCard
                      key={swap._id}
                      swap={swap}
                      handleConnect={handleConnectClick}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      )}
      </div>
      {showReportModal && currentItem && (
        <ReportModal
          listing={currentItem}
          user={user}
          onClose={handleCloseModal}
          onBlockSuccess={handlePageRefresh}
        />
      )}
      {isNewMsgModalOpen && currentItem && (
        <NewMsgModal
          item={currentItem}
          isSaleListing={showListings}
          onClose={handleCloseNewMsgModal}
        />
      )}
    </PageLayout>
  );
};

export default ListingsPageTest;
