import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import styles from '../styles/pages/individual-ticket-page.module.css';
import { PageLayout } from "../components/page-layout";
import { SaleCard } from "../components/sale-listings/sale-listing";
import { SwapCard } from "../components/swap-listings/swap-listing";
import { SaleTicketCard } from "../components/ticket-cards/solo-sale-card";
import { SwapTicketCard } from "../components/ticket-cards/solo-swap-card";
import DropdownButton from "../components/buttons/dropdown-button";
import ReportModal from "../components/modals/reportModal";
import NewMsgModal from "../components/modals/newMsgModal";
import { LoginButton } from "../components/buttons/login-button";

const TicketPage = () => {
  const { ticketId } = useParams();
  const { user } = useAuth0();
  const [ticket, setTicket] = useState(''); 
  const dynamicRedirect = `/tickets/${ticketId}`;

  const [eventName, setEventName] = useState(''); 
  const [events, setEvents] = useState([]);
  const [similarSwaps, setSimilarSwaps] = useState([]);
  const [similarListings, setSimilarListings] = useState([]);
  const [lineups, setLineups] = useState([]);
  const [activeButton, setActiveButton] = useState('listings');
  const [activeDropdownButton, setActiveDropdownButton] = useState('similarSale');
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';
  const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL || 'http://localhost:3000';
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 540);
  const [loading, setLoading] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [eventImgUrl, setEventImgUrl] = useState(null);
  const [isNewMsgModalOpen, setIsNewMsgModalOpen] = useState(false);
  const [isTicketListing, setIsTicketListing] = useState(null);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [isFromBlockedUser, setIsFromBlockedUser] = useState(false); 
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlockedUsersAndTicketDetails = async () => {
      try {
        if (user) {
          // Fetch blocked users
          const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/getUserMetadata/${user.sub}`);
          const blockedUsersData = response.data.metadata.blocked_users || []; 
          setBlockedUsers(blockedUsersData);
          console.log('blockedUsers:', blockedUsersData);
  
          // Fetch ticket details after blocked users are set
          fetchTicketDetails(blockedUsersData);
        } else if (!user) {
          fetchTicketDetails([]);
        }
      } catch (error) {
        console.error('Error fetching blocked users:', error);
        setError('Failed to fetch blocked users.');
      }
    };
    
    fetchBlockedUsersAndTicketDetails();
  }, [user, REACT_APP_API_BASE_URL]);  

    const fetchTicketDetails = async (blockedUsers) => {
      if (ticketId) {
        try {
          const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/tickets/${encodeURIComponent(ticketId)}`);
          const data = response.data;
          let ticketData = null;

          // Check for listings and set `ticket`
          if (data.listings.length > 0) {
            ticketData = data.listings[0];
            setIsTicketListing(true);
          } else if (data.swaps.length > 0) {
            ticketData = data.swaps[0];
            setIsTicketListing(false);
          }
          
          if (ticketData) {
            // Check if the ticket's seller or swapper is in `blockedUsers`
            const isBlocked = blockedUsers.includes(ticketData.seller_id || ticketData.swapper_id);
            setIsFromBlockedUser(isBlocked);

            if (!isBlocked) {
              setTicket(ticketData);
              setEventName(ticketData.event_name);
            }
          } else {
            setTicket(null);
          }
        } catch (error) {
          console.error('Error fetching ticket details:', error);
        }
      }
    };

  useEffect(() => {
    if (isFromBlockedUser) {
      // Timeout for 35s
      const timeoutId = setTimeout(() => {
        alert('Redirecting to the listings page...');
        window.location.href = `${REACT_APP_FRONTEND_BASE_URL}/listings`;
      }, 3500);
  
      return () => clearTimeout(timeoutId);
    }
  }, [isFromBlockedUser]);

    // Fetch swaps when "similarSwaps" selected
    useEffect(() => {
        if (activeDropdownButton === "similarSwaps") {
        fetchSwaps();
        }
    }, [ticket, activeDropdownButton, eventName]); 

  const fetchLineups = async () => {
    const eventName = ticket.event_name;
    try {
        console.log(`Fetching lineups for artist: ${eventName}`);
      const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/lineups/concert`, {
        params: { concertName: eventName }
      });
      // console.log(`Fetching lineups new endpoint for artist: ${eventName}`);
      // const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/lineups/${encodeURIComponent(eventName)}`);

      const lineups = response.data || [];
      // Filter out listings where seller_id is in blockedUsers
      const filteredLineups = lineups.filter(lineup => 
        !blockedUsers.includes(lineup.user_id)
      );

      setLineups(filteredLineups || []);
    } catch (error) {
      console.error('Error fetching lineups:', error);
    }
  };

  const fetchSwaps = async () => {
    const eventName = ticket.event_name;
    if (!eventName) {
        console.error('Artist name is empty');
        return; // Prevent the request if eventName is blank
      }

      if (ticket) { 
        try {
        console.log(`Fetching similar swaps for artist: ${eventName}`);
        const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/swaps/${encodeURIComponent(eventName)}`);

        let { swaps = [] } = response.data;

        // Filter swaps where seller_id is in blockedUsers and if swap = tix
        const filteredSwaps = swaps.filter(swap => 
          !blockedUsers.includes(swap.swapper_id) && 
          !swap._id.includes(ticket._id)
      );

        setSimilarSwaps(filteredSwaps);
        } catch (error) {
        console.error('Error fetching similar swaps:', error);
        }
    }
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const eventName = ticket.event_name;
      const region = ticket.region;
      if (!eventName) {
        console.error('No artist name provided');
        return;
      }
  
      try {
        console.log(`Fetching data for region: ${region} and artist: ${eventName}`);
        const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/events/${encodeURIComponent(region)}/${encodeURIComponent(eventName)}`);
  
        let { events = [], listings = [] } = response.data;
  
        // Filter events
        events = events.filter(event => 
          event.artistName.includes(eventName) || event.eventName.includes(eventName)
        );
        setEvents(events);

        if (events.length > 0) {
          setEventImgUrl(events[0].imgUrl);
        }

        // Filter listings if = tix and from blockedUsers
        const filteredListings = listings.filter(listing => 
          !listing._id.includes(ticket._id) &&
          !blockedUsers.includes(listing.seller_id)
        );

        setSimilarListings(filteredListings);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    if (ticket) fetchEvents();
  }, [ticket, REACT_APP_API_BASE_URL]);  


  const handleVisitSellerProfile = (instagramHandle) => {
    const profileLink = `${REACT_APP_FRONTEND_BASE_URL}/profile/${instagramHandle}`;
    window.open(profileLink, '_blank');
    // @shiv to open in same tab use window.location.href
  };

  const handleToggle = (button) => {
    setActiveButton(button);
    if (button === 'usersAttending' && Object.keys(ticket).length !== 0) {
        fetchLineups(); 
    }
};

  const handleReportClick = (item) => {
    setCurrentItem(item);
    setShowReportModal(true);
  };

  const handleCloseModal = () => {
    setShowReportModal(false);
    setCurrentItem(null);
  };

  const getActiveDropdownButtonLabel = () => {
    switch (activeDropdownButton) {
      case "similarSale":
        return "Sale listings";
      case "similarSwaps":
        return "Swaps";
      default:
        return "...";
    }
  };

const handleConnectClick = (item) => {
    setCurrentItem(item);
    setIsNewMsgModalOpen(true);
  };

  const handleCloseNewMsgModal = () => {
    setIsNewMsgModalOpen(false);
    setCurrentItem(null);
  };  

if (loading) return <div>Loading...</div>;
const ticketArray = Array.isArray(ticket) ? ticket : [ticket];

const ListingTitle = "Listing";
const SwapTitle = "Ticketswap";

const isMyTicket = isTicketListing
  ? ticket.seller_id === user?.sub
  : ticket.swapper_id === user?.sub;

  const handleBlockRedirect = () => {
    // Redirect to the /listings page
    window.location.href = `${REACT_APP_FRONTEND_BASE_URL}/listings`;
};

  return (
    <PageLayout>
      <div className={styles.artistPage}>
      {(isFromBlockedUser && !ticket) && (
          <>
          <h2 className={styles.artistName}>Ticket not found</h2>
          </>
      )}
        {/* Not mobileView */}
        {(!isMobileView && !isFromBlockedUser) && (
          <>
          <h2 className={styles.artistName}>{eventName}</h2>
          <div className={styles.halfContainer}>
            <div>
              <h2 className={styles.eventSubtitle}>{isTicketListing===true ? ListingTitle : SwapTitle}</h2>
              <div className={styles.gridContainer}>
              <div className={styles.headerContainer}>
              {ticketArray.length === 0 ? (
                <h2>No listing found.</h2>
              ) : (
                ticketArray.map((tix) => (
                  tix.expiry ? (
                    <SwapTicketCard key={tix._id} eventImgUrl={eventImgUrl} swap={tix} />
                  ) : tix.price ? (
                    <SaleTicketCard key={tix._id} eventImgUrl={eventImgUrl} listing={tix} />
                  ) : null
                ))
              )}
                </div>
                </div>
                {Object.keys(ticket).length === 0? (
                    null
              ) : ( 
                <>
                {isMyTicket ? null : (
                <>
                <div className={styles.lineContainer}>
                  <div className={styles.reportButton}>
                        <button className={styles.reportButton}
                            onClick={() => handleVisitSellerProfile(ticket.instagram_handle)}
                        >
                            See more from seller
                        </button>
                    </div>
                    {(!user) && (
                        <LoginButton
                        className={styles.reportButton}
                        redirectTo={dynamicRedirect}
                        buttonText="Sign in to chat with seller!"
                      />
                    )}
                    {(user) && (
                      <>
                    <div className={styles.reportButton}>
                        <button
                            onClick={() => handleConnectClick(ticket._id)}
                        >
                            Connect with seller
                        </button>
                    </div>
                    <div className={styles.reportButton}>
                      <button
                          onClick={() => handleReportClick(ticket._id)}
                      >
                          Report listing or seller
                      </button>
                    </div>
                    </>
                    )}
                </div>
                </>
                )}
              </>
              )}
            </div>
            {/* RHS */}
            <div className={styles.halfContainer}>
            <div className={styles.lineContainer}>

                <div className={styles.toggleButtons}>
                  <button
                    className={activeButton === 'listings' ? styles.activeButton : ''}
                    onClick={() => handleToggle('listings')}
                  >
                    Similar Listings
                  </button>
                  <button
                    className={activeButton === 'usersAttending' ? styles.activeButton : ''}
                    onClick={() => handleToggle('usersAttending')}
                  >
                    Users Attending
                  </button>
                </div>
              </div>
              {activeButton === 'listings' ? (
              <div className={styles.lineContainer}>
                {activeButton === 'listings' && (
                    <DropdownButton label={getActiveDropdownButtonLabel()}>
                        <button onClick={() => {
                            setActiveDropdownButton("similarSale");
                        }}>Sale</button>
                        <button onClick={() => {
                            setActiveDropdownButton("similarSwaps");
                        }}>Swaps</button>
                    </DropdownButton>
                )}
                </div>
                ) : ( null )}
              <div className={styles.gridContainer}>
                {activeButton === 'listings' ? (
                    <>
                      {activeDropdownButton === 'similarSale' && (
                        <>
                          {similarListings.length === 0 ? (
                            <div className={styles.eventCard}>
                              <h2>No similar listings found for this event.</h2>
                              <p>
                              Discover other listings{' '}
                                <a href="/listings" target="_blank" rel="noopener noreferrer" className={styles.aLink}>
                                  here
                                </a>
                                !
                              </p>
                            </div>
                          ) : (
                            similarListings.map((listing) => (
                              <SaleCard key={listing._id} listing={listing} handleConnect={handleConnectClick}/>
                            ))
                          )}
                        </>
                      )}
                      
                      {activeDropdownButton === 'similarSwaps' && (
                        <>
                          {similarSwaps.length === 0 ? (
                            <div className={styles.eventCard}>
                              <h2>No similar swaps found for this event.</h2>
                              <p>
                              Discover other listings{' '}
                                <a href="/listings" target="_blank" rel="noopener noreferrer" className={styles.aLink}>
                                  here
                                </a>
                                !
                              </p>
                            </div>
                          ) : (
                            similarSwaps.map((swap) => (
                              <SwapCard key={swap._id} swap={swap} handleConnect={handleConnectClick}/>
                            ))
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                    {lineups.length === 0 ? (
                      <div className={styles.eventCard}>
                        <h2>No declared attendees found.</h2>
                        <p>
                          Learn more about sharing your concert lineup with others{' '}
                          <a href="/start" target="_blank" rel="noopener noreferrer" className={styles.aLink}>
                            here
                          </a>
                          !
                        </p>
                      </div>
                    ) : (
                      lineups.map((lineup) => (
                        <div key={lineup._id} className={styles.eventCard}>
                          <div>
                            <p>
                              <a href="#" onClick={() => handleVisitSellerProfile(lineup.userIg)}>
                                {'@' + lineup.userIg}
                              </a>
                            </p>
                            <h3>{lineup.concertName}</h3>
                          </div>
                        </div>
                      ))
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          </>
        )}
        {/* Mobile view adjustment */}
        {(isMobileView && !isFromBlockedUser) && (
          <>
          <div>
            <div className={styles.topContainer}>
              <div className={styles.gridContainer}>
              <div className={styles.headerContainer}>
                {ticketArray.length === 0 ? (
                  <h2>No listing found.</h2>
                ) : (
                  ticketArray.map((tix) => (
                    tix.expiry ? (
                      <SwapTicketCard key={tix._id} eventImgUrl={eventImgUrl} swap={tix} />
                    ) : tix.price ? (
                      <SaleTicketCard key={tix._id} eventImgUrl={eventImgUrl} listing={tix} />
                    ) : null
                  ))
                )}
              </div>
            </div>

                {Object.keys(ticket).length === 0 ? (
                    null
              ) : ( 
                <>
                {isMyTicket ? null : (
                <>
                <div className={styles.columnContainer}>
                <div className={styles.mobileFlex}>
                <div className={styles.lineContainerListing}>
                <div className={styles.reportButton}>
                    <button className={styles.reportButton}
                        onClick={() => handleVisitSellerProfile(ticket.instagram_handle)}
                    >
                        See more from seller
                    </button>
                    </div>
                   {(!user) && (
                        <LoginButton
                        className={styles.reportButton}
                        redirectTo={dynamicRedirect}
                        buttonText="Sign in to chat with seller!"
                      />
                    )}
                    {(user) && (
                      <>
                    <div className={styles.reportButton}>
                        <button
                            onClick={() => handleConnectClick(ticket._id)}
                        >
                            Connect with seller
                        </button>
                        </div>
                    <div className={styles.reportButton}>
                      <button
                          onClick={() => handleReportClick(ticket._id)}
                      >
                          Report listing or seller
                      </button>
                    </div>
                    </>
                    )}
                </div>
                </div>
                </div>
                </>
              )}
            </>
          )}
          </div>
        </div>         
            <div className={styles.lineContainer}>
              <div className={styles.toggleButtons}>
                <button
                  className={activeButton === 'listings' ? styles.activeButton : ''}
                  onClick={() => handleToggle('listings')}
                >
                  Similar Listings
                </button>
                <button
                  className={activeButton === 'usersAttending' ? styles.activeButton : ''}
                  onClick={() => handleToggle('usersAttending')}
                >
                  Users Attending
                </button>
              </div>
            </div>
            {activeButton === 'listings' ? (
              <div className={styles.lineContainer}>
                {activeButton === 'listings' && (
                    <DropdownButton label={getActiveDropdownButtonLabel()}>
                        <button onClick={() => {
                            setActiveDropdownButton("similarSale");
                        }}>Sale</button>
                        <button onClick={() => {
                            setActiveDropdownButton("similarSwaps");
                        }}>Swaps</button>
                    </DropdownButton>
                )}
                </div>
                ) : ( null )}
                          <div className={styles.gridContainer}>
                {activeButton === 'listings' ? (
                    <>
                      {activeDropdownButton === 'similarSale' && (
                        <>
                          {similarListings.length === 0 ? (
                            <div className={styles.eventCard}>
                              <h2>No similar listings found for this event.</h2>
                              <p>
                              Discover other listings{' '}
                                <a href="/listings" target="_blank" rel="noopener noreferrer" className={styles.aLink}>
                                  here
                                </a>
                                !
                              </p>
                            </div>
                          ) : (
                            similarListings.map((listing) => (
                              <SaleCard key={listing._id} listing={listing} handleConnect={handleConnectClick}/>
                            ))
                          )}
                        </>
                      )}
                      
                      {activeDropdownButton === 'similarSwaps' && (
                        <>
                          {similarSwaps.length === 0 ? (
                            <div className={styles.eventCard}>
                              <h2>No similar swaps found for this event.</h2>
                              <p>
                              Discover other listings{' '}
                                <a href="/listings" target="_blank" rel="noopener noreferrer" className={styles.aLink}>
                                  here
                                </a>
                                !
                              </p>
                            </div>
                          ) : (
                            similarSwaps.map((swap) => (
                              <SwapCard key={swap._id} swap={swap} handleConnect={handleConnectClick}/>
                            ))
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                    {lineups.length === 0 ? (
                      <div className={styles.eventCard}>
                        <h2>No declared attendees found.</h2>
                        <p>
                          Learn more about sharing your concert lineup with others{' '}
                          <a href="/start" target="_blank" rel="noopener noreferrer" className={styles.aLink}>
                            here
                          </a>
                          !
                        </p>
                      </div>
                    ) : (
                      lineups.map((lineup) => (
                        <div key={lineup._id} className={styles.eventCard}>
                          <div>
                            <p>
                              <a href="#" onClick={() => handleVisitSellerProfile(lineup.userIg)}>
                                {'@' + lineup.userIg}
                              </a>
                            </p>
                            <h3>{lineup.concertName}</h3>
                          </div>
                        </div>
                      ))
                    )}
                  </>
                )}
              </div>
              </>
          )}
      </div>
      {showReportModal && ticket && (
        <ReportModal
          post={ticket}
          onClose={handleCloseModal}
          onBlockSuccess={handleBlockRedirect}
        />
      )}
    {isNewMsgModalOpen && ticket && (
        <NewMsgModal
          item={ticket}
          isSaleListing={activeButton === "listings" && activeDropdownButton === "similarSale"}
          onClose={handleCloseNewMsgModal}
        />
      )}
    </PageLayout>
  );
};

export default TicketPage;