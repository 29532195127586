import React from 'react';
import axios from 'axios'; 
import styles from '../../styles/components/private-sale-listing.module.css'; // Adjust the path as necessary
import ProfilePgToggleDropdown from "../buttons/custom-dropdowns";
import { useAuth0 } from "@auth0/auth0-react";

// formatDate function defined directly within the same file for simplicity
function formatDate(date) {
  const options = { month: 'short', day: 'numeric' };
  return new Date(date).toLocaleDateString('en-US', options);
}

export const PrivateSwapCard = ({ 
  swap,
  handleEdit, 
  handleDelete,
  refetchSavedList 
}) => {
    const { user } = useAuth0();
    const isOwner = user.sub === swap.swapper_id; 
    const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL || 'http://localhost:3000';
    const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';
    const type = 'swap';


    const handleSeeTixPg = () => {
      const ticketLink = `${REACT_APP_FRONTEND_BASE_URL}/tickets/${swap._id}`;
      window.open(ticketLink, '_blank'); // Open the ticket link in a new tab
    };
  
    const handleShareProfile = () => {
      const profileLink = `${REACT_APP_FRONTEND_BASE_URL}/profile/${swap.instagram_handle}`;
      navigator.clipboard
        .writeText(profileLink)
        .then(() => alert("Profile link copied to clipboard!"))
        .catch((error) => console.error("Failed to copy profile link:", error));
    };

    const handleCopyTixPg = () => {
      const ticketLink = `${REACT_APP_FRONTEND_BASE_URL}/tickets/${swap._id}`;
      navigator.clipboard
        .writeText(ticketLink)
        .then(() => alert("Ticket link copied to clipboard!"))
        .catch((error) => console.error("Failed to copy ticket link:", error));
    };

    const handleDeleteSaved = async (id) => {
      try {
        const payload = {
          userId: user.sub,
          type: type 
        };
    
        await axios.delete(`${REACT_APP_API_BASE_URL}/api/saved/${id}`, {
          data: payload,
        });

        // update the saved list after unsaving
        if (refetchSavedList) {
          refetchSavedList();
        }

      } catch (error) {
        console.error("Failed to delete listing:", error);
      }
    }

  return (

    <div key={swap._id} className={styles.eventCard}>
        <div className={styles.artistName} onClick={handleSeeTixPg}  style={{ cursor: 'pointer' }}>{swap.event_name}</div>
        <div className={styles.dateLocation} onClick={handleSeeTixPg}  style={{ cursor: 'pointer' }}>{formatDate(swap.expiry)} | {swap.venue}</div>
        <div className={styles.ticketInfo} onClick={handleSeeTixPg}  style={{ cursor: 'pointer' }}>
            {swap.num_tickets} {swap.level} {swap.num_tickets === 1 ? "Ticket" : "Tickets"}
        </div>
      <div className={styles.buttonFrame}>
        <ProfilePgToggleDropdown label="Options">
          {isOwner ? (
            <>
          <button onClick={() => handleEdit(swap)}>Edit</button>
          <button onClick={() => handleDelete(swap._id)}>Delete</button>
          <button onClick={handleCopyTixPg}>Share</button>
            </>
          ) : (
            <>
              <button onClick={() => handleDeleteSaved(swap._id)}>Unsave</button>
              <button onClick={handleCopyTixPg}>Share</button>
              <button onClick={() => handleShareProfile(swap.instagram_handle)}>See more from user</button>
            </>
          )}
        </ProfilePgToggleDropdown>
      </div>
    </div>
  );
};
