import React from "react";
import styles from "../../styles/components/tabBar.module.css"; // Import your CSS module here
import { TabBarTicket } from "./tabBarTicket";

export const TabBarPublic = ({ activeTab, setActiveTab, setActiveButton }) => {
  return (
    <div className={styles.tabContainer}>
      <TabBarTicket />
      <div className={styles.tabLabelsContainer}>
      <div className={styles.tabLabels}>
        <button
          className={activeTab === "sale" ? styles.activeTab : styles.tab}
          onClick={() => {
            setActiveTab("sale");
            setActiveButton("activelistings"); // Set active button for sales
          }}
        >
          Sale
        </button>
        <button
          className={activeTab === "swap" ? styles.activeTab : styles.tab}
          onClick={() => {
            setActiveTab("swap");
            setActiveButton("activeswaps"); // Set active button for swaps
          }}
        >
          Swap
        </button>
        <button
          className={activeTab === "concert" ? styles.activeTab : styles.tab}
          onClick={() => {
            setActiveTab("concert");
            setActiveButton("lineup"); // Set active button for lineup
          }}
        >
          Lineup
        </button>
      </div>
      </div>
    </div>
  );
};
