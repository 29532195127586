import React, { useState, useEffect } from "react";

export const TabBarTicket = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 540);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 540);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {isLargeScreen ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 769 50"
          fill="none"
        >
          <path
            d="M5.2232 1H763.866C767.317 1 769.148 5.07785 766.855 7.65746L759.516 15.9148C758.539 17.0131 758 18.4315 758 19.901V30.0171C758 31.5455 758.583 33.0163 759.631 34.1293L766.355 41.2734C768.755 43.8243 766.95 48.0103 763.447 48.0148L5.23854 48.9869C1.90757 48.9911 0.0309811 45.1604 2.07599 42.5311L8.73611 33.9681C9.55527 32.9149 10 31.6187 10 30.2845V19.7155C10 18.3813 9.55527 17.0851 8.73611 16.0319L2.06579 7.45576C0.0222282 4.82833 1.8946 1 5.2232 1Z"
            fill="#2344B2"
            stroke="#1C1919"
            strokeWidth="2"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 369 50"
          fill="none"
        >
          <path
            d="M5.2232 1H363.866C367.317 1 369.148 5.07785 366.855 7.65746L359.516 15.9148C358.539 17.0131 358 18.4315 358 19.901V30.0171C358 31.5455 358.583 33.0163 359.631 34.1293L366.369 41.2889C368.768 43.8379 366.967 48.0211 363.467 48.0303L5.25472 48.973C1.92126 48.9818 0.0402207 45.1485 2.08677 42.5173L8.73611 33.9681C9.55527 32.9149 10 31.6187 10 30.2845V19.7155C10 18.3813 9.55527 17.0851 8.73611 16.0319L2.06579 7.45576C0.0222287 4.82833 1.8946 1 5.2232 1Z"
            fill="#2344B2"
            stroke="#1C1919"
            strokeWidth="2"
          />
        </svg>
      )}
    </div>
  );
};
