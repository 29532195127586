import React, { useState, useEffect, useRef } from 'react';
import styles from '../../styles/components/customddButton.module.css';

const ProfilePgToggleDropdown = ({ label, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleOptionClick = (callback) => {
    if (callback) callback(); // Perform the action if provided
    setIsOpen(false); // Close the dropdown
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={styles['profile-dropdown']} ref={dropdownRef}>
      <button
        className={styles['profile-dropdown-button']}
        onClick={toggleDropdown}
      >
        {label}
      </button>
      <div className={`${styles['profile-dropdown-content']} ${isOpen ? styles['show'] : ''}`}>
        {React.Children.map(children, (child) => 
          React.cloneElement(child, { onClick: () => handleOptionClick(child.props.onClick) })
        )}
      </div>
    </div>
  );
};

export default ProfilePgToggleDropdown;
