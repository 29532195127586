import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import styles from "../styles/pages/profile-page.module.css";
import DropdownButton from "../components/buttons/dropdown-button";

// make sure you can only access this in mobile view (for approval??)
export const PassportPage = () => {
  const REACT_APP_API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";
  const REACT_APP_FRONTEND_BASE_URL =
    process.env.REACT_APP_FRONTEND_BASE_URL || "http://localhost:3000";

  const { user } = useAuth0();
  const [myConcerts, setMyConcerts] = useState([]);
  const [profileImage, setProfileImage] = useState('');
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 540); // mobile view?
  const [userStats, setUserStats] = useState(null);


  const fetchUserStats = useCallback(async () => {
    if (!user) return;
    try {
      const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/passport/userLineupStats/${user.sub}`);
      setUserStats(response.data);
    } catch (error) {
      console.error("Failed to fetch user stats:", error);
    }
  }, [user, REACT_APP_API_BASE_URL]);
  
  useEffect(() => {
    fetchUserStats();
  }, [fetchUserStats]);
  


//   const loadProfileImage = async (profilepic) => {
//     console.log('profilepic:', profilepic);
//     // Check if the image is stored in cloud
//     if (profilepic.startsWith("https://campus-ticket.s3.")) {
//       const pictureKey = profilepic.split('.com/')[1];
      
//       try {
//         const response = await axios.post(`${REACT_APP_API_BASE_URL}/api/getSignedUrl`, {
//           pictureKey: pictureKey,
//         });

//         const { signedUrl } = response.data;
//         console.log('signedUrl:', signedUrl);
//         // Use the signed URL to load the image
//         setProfileImage(signedUrl);
//       } catch (error) {
//         console.error("Error fetching signed URL", error);
//       }
//     } else {
//       // Use the local or alternative image URL
//       setProfileImage(profilepic);
//     }
//   };
  

//   const fetchMyLineup = useCallback(async () => {
//     if (!user || !user.email) return;

//     try {
//       const response = await axios.get(
//         `${REACT_APP_API_BASE_URL}/api/lineups/user`,
//         {
//           params: { user_email: user.email },
//         }
//       );
//       const myLineup = response.data.filter(
//         (concert) => !concert.deleted && concert.user_email === user.email
//       );

//       setMyConcerts(myLineup);
//     } catch (error) {
//       console.error("Oh no! Failed to fetch lineup:", error);
//     }
//   }, [user, REACT_APP_API_BASE_URL]);

//   useEffect(() => {
//     loadProfileImage(user.picture);
//     fetchMyLineup();
//   }, [
//     user,
//     fetchMyLineup,
//     REACT_APP_API_BASE_URL,
//   ]);


  return (
    <PageLayout>
      <div className={styles.content__layout}>
        <div className="content__body">
        <h1>Concert Passport</h1>
            {userStats && (
            <div className={styles.stats}>
                <p>Total Concerts: {userStats.total_concerts}</p>
                <p>Estimated Concert Hours: {userStats.estimated_concert_hours} hrs</p>
                <p>Top Concertgoer: Top {userStats.top_percent_concertgoer}%</p>
            </div>
            )}
            </div>
        </div>
    </PageLayout>
  );
};
